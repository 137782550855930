import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as FileIcon } from "../../Assets/icons/fileIcon.svg"
import { localizationStrings } from '../../Localization/Localization'

const ReplymessageElement = ({
    text,
    image,
    file,
    messageDeleted,
    forwarded,
    sender,
    received,
    video,
}) => {

    return (
        <Container>
            <MessageWrapper received={received}>
                <Top imgcontainer={image || video}>
                    <Sender>
                        <h1>{sender}</h1>
                        {image && <p>Image</p>}
                        {video && <p>Video</p>}
                    </Sender>
                    {
                        (image || video || file) &&
                        <ItemWrapper>
                            {image && <img src={image.url} alt='pht' />}
                            {video && <img src={video.thumbnail.url} alt='video' />}
                            {file &&
                                <FileWrapper>
                                    <FileIcon />
                                    <p>{file.name}</p>
                                </FileWrapper>
                            }
                        </ItemWrapper>
                    }
                </Top>
                {text.length > 0 && !image && !video && !file &&
                    <p>{!messageDeleted ? text : <i>{localizationStrings.chat.message_deleted}</i>}</p>
                }
            </MessageWrapper>
        </Container >
    )
}

export default ReplymessageElement
const Container = styled.div`
display:flex;
flex-direction:column;
gap:5px;

`
const ItemWrapper = styled.div`
display:flex;
flex-direction:column;
width:100%;
min-width:100px;
margin-left:auto;
    overflow:hidden;
    position:relative;
img{
object-fit:contain;
    border-radius:4px;
    margin-left:auto;
    max-height:70px;
}
svg{
    max-height:35px;
    max-width:35px;
    color:${colors.white};
}
`
const MessageWrapper = styled.div`
position:relative;
display:flex;
flex-direction:${props => props.imgcontainer ? "row" : "column"};
width:100%;
gap:${props => props.imgcontainer ? "50px" : "5px"};
background:${props => props.received ? colors.receivedReplyMessageBg : colors.darkPurple};
padding: ${(props) => props.imgcontainer ? "8px 12px" : "16px"} ;
border-top-left-radius:${(props) => props.imgcontainer ? "4px" : "20px"} ;
border-bottom-left-radius:${(props) => props.imgcontainer ? "4px" : "20px"} ;
border-top-right-radius:${(props) => props.imgcontainer ? "4px" : "8px"} ;
border-bottom-right-radius:${(props) => props.imgcontainer ? "4px" : "20px"} ;
p{
    overflow:hidden;
    text-overflow:ellipsis;
white-spaces:pre-wrap;
font-size: 12px;
font-weight: 400;
line-height: 16px;
text-align: left;
color:${colors.white};
}

i{
   font-size: 14px; 
   padding:0 5px;
}


a{
    color:${colors.white}; 
}
`

const Sender = styled.div`
display:flex;
flex-direction:column;
gap:5px;
min-width:70px;
h1{
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color:${colors.white};
}

    p{
        opacity:0.5;
        overflow:hidden;
        text-overflow:ellipsis;
    white-spaces:pre-wrap;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color:${colors.white};

    }

   
`

const FileWrapper = styled.div`
display:flex;
gap:3px;
align-items:center;
overflow:hidden;
p{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:pre;
}
`

const Top = styled.div`
display:flex;
width:100%;
flex-direction:${props => props.imgcontainer ? "row" : "column"};
position:relative;
column-gap:25px;
row-gap:10px;
`
const Bottom = styled(Top)``