

export const refreshTokenApi = (refresh_token, onSuccess, onError) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "refresh_token");
    urlencoded.append("refresh_token", refresh_token);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    fetch("https://securetoken.googleapis.com/v1/token?key=AIzaSyBq-wzxjryxhwlGmEbPKX57AuVTzvk-K7g", requestOptions)
        .then(response => response.text())
        .then(result => onSuccess(result))
        .catch(error => onError(error));

};