import { cloneDeep } from "lodash"
import moment from "moment"

export const sortNewestConversations = (conversations) => {
    let mapped = conversations.map((item) => {
        if (item.last_message) {
            return item
        } else {
            return {
                ...item,
                last_message: {
                    created_at: item.created_at
                }
            }
        }
    })
    return mapped?.sort((a, b) => a?.last_message?.created_at < b?.last_message?.created_at ? 1 : -1)
}

export const sortReceivedMessages = (messages) => {

    let sorted = messages?.sort((a, b) => a.device_sent_date > b.device_sent_date ? 1 : -1)
    return sorted;
}

export const handleReceivedMessages = (oldMessagesList, newMessgesList) => {

    const oldMessages = oldMessagesList;
    const newMessages = checkIsArray(newMessgesList);
    const oldMessagesIds = oldMessages?.map((message) => message.id)
    const newMessagesIds = newMessages?.map((message) => message.id)

    const allMessagesIds = newMessagesIds.concat(oldMessagesIds);

    const uniqueMessagesIds = [...new Set(allMessagesIds)];

    let newMessagesList = [];

    uniqueMessagesIds.forEach((messageId) => {

        const newMessageFound = newMessages.find((obj) => {
            return obj.id === messageId;
        })

        const oldMessageFound = oldMessages.find((obj) => {
            return obj.id === messageId
        })

        if (newMessageFound) {
            newMessagesList.push(newMessageFound)
        } else

            if (oldMessageFound) {
                newMessagesList.push(oldMessageFound)
            }



    })

    return newMessagesList;

}

const checkIsArray = (element) => {
    if (Array.isArray(element)) {
        return element
    } else {
        return [element]
    }
}


const compareDates = (dateA, dateB) => {
    let a = moment(dateA)
    let b = moment(dateB)
    return (a.isBefore(b, "D"))
}

export const createDateSeparators = (messages) => {
    if (!messages) {
        return
    }
    let copy = cloneDeep(messages);
    for (let index = 1; index < copy.length; index++) {
        let prev = copy[index - 1]
        let current = copy[index]

        if (compareDates(prev.device_sent_date, current.device_sent_date)) {
            copy.splice(index, 0, {
                type: "date-separator",
                date: current.device_sent_date
            })
        }
    }

    copy.splice(0, 0, {
        type: "date-separator",
        date: copy[0]?.device_sent_date
    })

    return copy
}


export const findTranslatedTextForUser = (translations, user) => {
    let translated = translations?.find((item) => item.user_id === user.id)
    return translated
}

export const handleConvertBitesToMb = (bites) => {
    const Mb = bites / 1000000
    return Mb
}