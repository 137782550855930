
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'

const AttachmentMenu = ({ optionsList }) => {
    return (
        <Container>
            {optionsList?.map((item, index) => (
                <Item onClick={item.action} key={index}>
                    {item.icon}
                    <p>{item.text}</p>
                </Item>
            ))}
        </Container>
    )
}

export default AttachmentMenu

const Container = styled.div`
display:flex;
flex-direction:column;
border-radius:12px;
background:rgba(50, 52, 68, 1);
max-width:200px;
width:200px;
overflow:hidden;
:last-child{
    border:none;
}
`

const Item = styled.div`
display:flex;
align-items:center;
padding:10px 20px;
width:100%;
color:${colors.white};
border-bottom:0.5px solid ${colors.chatElementColor};
gap:5px;
cursor:pointer;
svg{
    color:rgba(50, 52, 68, 1);
}
&:hover{
    background:${colors.receivedReplyMessageBg};
    svg{
        color:${colors.receivedReplyMessageBg};
    }
}
`