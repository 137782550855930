export const emojiList = [
    {
        emoji: "👍",
        key: "like"
    },

    {
        emoji: "🙏",
        key: "pray"
    },

    {
        emoji: "❤️",
        key: "love"
    },

    {
        emoji: "🙂",
        key: "smile"
    },

    {
        emoji: "😂",
        key: "laught"
    },

    {
        emoji: "👎",
        key: "dislike"
    },
]

export const reactionButtonEmojiItems = [
    { label: "like", node: <div>👍</div>, key: "like" },
    { label: "pray", node: <div>🙏</div>, key: "pray" },
    { label: "love", node: <div>❤️</div>, key: "love" },
    { label: "smile", node: <div>🙂</div>, key: "smile" },
    { label: "haha", node: <div>😂</div>, key: "laught" },
    { label: "dislike", node: <div>👎</div>, key: "dislike" },
]

export const mapEmoji = (key) => {
    let found = emojiList?.find((item) => item.key === key)
    if (found) {
        return found.emoji
    }
}
