export const filterContacts = (contacts, searchKey) => {
    if (searchKey === "") {
        return contacts
    } else {
        let filtered = [];
        contacts.map((contact) => {
            if (contact.phone_number.includes(searchKey) ||
                contact.name.toLowerCase().includes(searchKey.toLowerCase())
            ) {
                filtered.push(contact)
            }
        })
        return [...new Set(filtered)];
    }
}