import { DELETE, GET } from "../API";


export const hadFreeTrialAPICall = (onSuccess, onError) => {
    GET(`/api/v1/payments/had_free_trial`, onSuccess, onError);
}

export const getStripePaymentLinkAPICall = (onSuccess, onError) => {
    GET(`/api/v1/payments/stripe_payment_link`, onSuccess, onError);
}

export const cancelStripeSubscriptionAPICall = (onSuccess, onError) => {
    DELETE(`/api/v1/payments/cancel_stripe_subscription`, onSuccess, onError);
}