import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";
import { ReactComponent as RemoveIcon } from "../../Assets/icons/deleteIcon.svg";
import { localizationStrings } from "../../Localization/Localization";



const DeleteConfirmationChild = ({ action, item, variant, disableButton, children, open, setOpen }) => {
    const REMOVE_ACCOUNT = "remove_account"
    const UNBLOCK_CONTACT = "unblock_contact"
    const BLOCK_CONTACT = "block_contact"
    const UNSUBSCRIBE = "unsubscribe"
    const REVOKE_ADMIN = "revoke_admin"
    const ME = 'me';
    const DELETE_CHAT = 'delete_chat';
    const DELETE_CALL = 'delete_call';

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemove = () => {
        action(item);
        setOpen(false);
    };


    const DisplayDialogVariant = ({ variant }) => {
        let displayDialog = (
            <DialogContentText id="alert-dialog-description">
                {localizationStrings.chat.remove_member}
            </DialogContentText>)

        if (variant == REMOVE_ACCOUNT) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    {localizationStrings.profile.delete_account_info}
                </DialogContentText>)
        }
        if (variant == UNBLOCK_CONTACT) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    {localizationStrings.block_contacts.unblockConfirmationMessage}
                </DialogContentText>)
        }
        if (variant == BLOCK_CONTACT) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    {localizationStrings.block_contacts.blockConfirmationMessage}
                </DialogContentText>)
        }
        if (variant == UNSUBSCRIBE) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    {localizationStrings.profile.unsubscribeConfirmationMessage}
                </DialogContentText>)
        }
        if (variant == REVOKE_ADMIN) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    {localizationStrings.profile.revokeAdminConfirmationMessage}
                </DialogContentText>)
        }
        if (variant == DELETE_CHAT) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    {localizationStrings.profile.deleteChat}
                </DialogContentText>)
        }
        if (variant == DELETE_CALL) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    {localizationStrings.context_menu.delete_call_message}
                </DialogContentText>)
        }
        if (variant == ME) {
            displayDialog = (<DialogContentText id="alert-dialog-description">
                {localizationStrings.chat_details.exit_group}
            </DialogContentText>)
        }
        return displayDialog
    }

    const DisplayConfirmationButton = ({ variant }) => {
        let displayDialog = `${localizationStrings.chat_details.yes}`

        if (variant == BLOCK_CONTACT) {
            displayDialog = `${localizationStrings.block_contacts.block}`
        }
        if (variant == UNBLOCK_CONTACT) {
            displayDialog = `${localizationStrings.block_contacts.unblock}`
        }
        if (variant == UNSUBSCRIBE) {
            displayDialog = `${localizationStrings.profile.unsubscribe}`
        }
        if (variant == REVOKE_ADMIN) {
            displayDialog = `${localizationStrings.profile.revokeAdmin}`
        }
        return displayDialog
    }
    return (
        (<>
            {children}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
                <DialogContent>
                    <DisplayDialogVariant variant={variant} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>  {localizationStrings.chat_details.no}</Button>
                    <Button style={{ color: "red" }} onClick={handleRemove} autoFocus>
                        <DisplayConfirmationButton variant={variant} />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
        )


    );
};

export default DeleteConfirmationChild;

const DeleteButton = styled.button`
background:none;
border:none;
display:flex;
align-items:center;
cursor:pointer;
svg{
    height:16px;
}
  :disabled{
    cursor:inherit;
    opacity:0.5;
  }
`;

