import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { localizationStrings } from '../../Localization/Localization'

const OfflineView = () => {
    return (
        <OfflineContainer id="offline">
            <OfflineMessage><h1>{localizationStrings.profile.not_connected}</h1></OfflineMessage>
        </OfflineContainer>
    )
}

export default OfflineView

const OfflineContainer = styled.div`
position:fixed;
top:0;
left:0;
width:100%;
height:100%;
background:rgba(0,0,0,0.5);
backdrop-filter: blur(15px);
z-index:5;
display:flex;
align-items:center;
justify-content:center;
`
const OfflineMessage = styled.div`
border-radius:10px;
background:${colors.background};
padding:30px;
h1{
    font-size:28px;
    color:${colors.purple};
}
`