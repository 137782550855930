// src/requestPermission.js

import { getToken } from "firebase/messaging";
import { messaging } from "../firebase";
import { register } from "../serviceWorker";
import { useAuthService } from "../Context/AuthContext";

export var pushToken = null;

const requestPermission = async (onSuccess) => {
  const VAPID_KEY = process.env.REACT_APP_VAPID_KEY;

  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      // Get the token
      await getToken(messaging, { vapidKey: VAPID_KEY }).then((response) => {
        // console.log("FCM Token:", response);
        register();
        onSuccess(response);
      });

      // Send token to your server or use it directly to send messages
    } else {
      console.log("Notification permission denied.");
    }
  } catch (error) {
    console.error("Error getting permission or token:", error);
  }
};

const getCurrentNotificationTokenIfPossible = async (onSuccess) => {
  const VAPID_KEY = process.env.REACT_APP_VAPID_KEY;

  const permission = Notification.permission;
  if (permission === "granted") {
    console.log("Notification permission granted.");
    // Get the token
    pushToken = await getToken(messaging, { vapidKey: VAPID_KEY }).then(
      (response) => {

        register();
        onSuccess(response);
      }
    );
    // Send token to your server or use it directly to send messages
  } else {
    console.log("Notification permission denied.");
  }
};

export default {
  getCurrentNotificationTokenIfPossible,
  requestPermission,
};
