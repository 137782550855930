import { GET, PATCH, POST } from "../API"
let source;

export const getQuickChatAPICall = (hashId, onSuccess, onError) => {
    source = GET(`/api/v1/chat/quick_chat/?hash_id=${hashId}`, onSuccess, onError, true, source)
}

export const joinQuickChatAPICall = (hash_id, name, language, onSuccess, onError) => {
    const data = {
        hash_id: hash_id,
        name: name,
        language: language
    }

    POST(`/api/v1/chat/quick_chat/join`, data, onSuccess, onError)
}

export const reactToMessageQuickChatAPICall = (reaction, chat_id, message_id, onSuccess, onError) => {
    const data = {
        reaction: reaction,
    }
    POST(`/api/v1/chat/quick_chat/${chat_id}/messages/${message_id}`, data, onSuccess, onError)
}

export const markAsReadQuickChatAPICall = (chat_id, message_id, onSuccess, onError) => {
    const data = {}
    POST(`/api/v1/chat/quick_chat/${chat_id}/messages/${message_id}/read`, data, onSuccess, onError)
}

export const getMessagesQuickChatAPICall = (chat_id, page, onSuccess, onError) => {
    source = GET(`/api/v1/chat/quick_chat/${chat_id}/messages?page=${page}`, onSuccess, onError, true, source)
}

export const addMessageQuickChatAPICall = (data, chat_id, onSuccess, onError) => {
    POST(`/api/v1/chat/quick_chat/${chat_id}/add_message`, data, onSuccess, onError)
}

export const addReplyMessageQuickChatAPICall = (data, chat_id, message_id, onSuccess, onError) => {
    POST(`/api/v1/chat/quick_chat/${chat_id}/messages/${message_id}/add_reply`, data, onSuccess, onError)
}

export const editMessageQuickChatAPICall = (chat_id, message_id, message, onSuccess, onError) => {
    const data = {
        original_message: message
    }
    PATCH(`/api/v1/chat/quick_chat/${chat_id}/messages/${message_id}`, data, onSuccess, onError)
}