


import { POST } from "../API";
let source = null;

export const setGroupMembersAPI = (id, members, onSuccess, onError) => {
    const data = {
        id: id,
        members: members
    }
    source = POST(`/api/v1/chat/groups/set_members`, data, onSuccess, onError, true, source);
};
