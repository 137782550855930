import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../../Assets/styles/customPhoneSelect.css";
import "react-phone-number-input/style.css";
import { colors } from "../../Assets/styles/colors";
import AddMembers from "./AddMembers";
import SelectedMembers from "./SelectedMembers";

export const SELECTED_MEMBERS = "selected_members"
export const ADD_MEMBERS = "add_members";
export const NEW_CONTACT = "new_contact"


const AddNewGroup = ({
  setIsAddNewChatGroupOpen,
  conversations,
  setConversations,
  currentConversationRef,
  handleSelectConversation,
  contacts,
  setIsAddNewContactOpen,
  handleInviteContact
}) => {


  const [selectedContacts, setSelectedContacs] = useState([]);
  const [initialContacts, setInitialContacts] = useState([]);

  const [option, setOption] = useState(SELECTED_MEMBERS);

  const initialValues = {
    groupName: "",
    groupDescription: "",
    searchInput: ""
  };

  const [inputValues, setInputValues] = useState(initialValues);
  const [previewAvatar, setPreviewAvatar] = useState()
  const [avatar, setAvatar] = useState();


  const handleSelectContact = (contact) => {
    let found = selectedContacts.find((item) => item.id === contact.id);
    if (found) {
      let filtered = selectedContacts.filter((item) => item.id != contact.id);
      setSelectedContacs(filtered);
    }
    else {
      setSelectedContacs((prev) => {
        let newList = [...prev, contact]
        let sorted = newList.sort((a, b) => a.name > b.name ? 1 : -1)
        return sorted
      })
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.currentTarget;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleAvatarChange = (e) => {
    setAvatar(e.target.files[0])
    setPreviewAvatar(URL.createObjectURL(e.target.files[0]));
  };

  const handleSaveBtn = () => {
    setInitialContacts(selectedContacts)
    setOption(SELECTED_MEMBERS);
  }

  const handleBackBtn = () => {
    setSelectedContacs(initialContacts)
    setOption(SELECTED_MEMBERS);
  }

  // useEffect(() => {
  //   setInitialContacts(selectedContacts)
  // }, [contacts])

  return (

    <AddNewGroupContainer>
      {option === ADD_MEMBERS &&
        <AddMembers
          contacts={contacts}
          selectedContacts={selectedContacts}
          setSelectedContacs={setSelectedContacs}
          initialContacts={initialContacts}
          setOption={setOption}
          handleSelectContact={handleSelectContact}
          setIsAddNewContactOpen={setIsAddNewContactOpen}
          handleInviteContact={handleInviteContact}
          handleSaveBtn={handleSaveBtn}
          handleBackBtn={handleBackBtn}
        />

      }

      {option === SELECTED_MEMBERS &&
        <SelectedMembers
          contacts={contacts}
          selectedContacts={selectedContacts}
          setSelectedContacs={setSelectedContacs}
          currentConversationRef={currentConversationRef}
          handleSelectConversation={handleSelectConversation}
          setConversations={setConversations}
          conversations={conversations}
          setOption={setOption}
          setIsAddNewChatGroupOpen={setIsAddNewChatGroupOpen}
          handleInputChange={handleInputChange}
          inputValues={inputValues}
          avatar={avatar}
          handleAvatarChange={handleAvatarChange}
          previewAvatar={previewAvatar}
          setInitialContacts={setInitialContacts}
          initialContacts={initialContacts}

        />}
    </AddNewGroupContainer>

  );
};

export default AddNewGroup;

const AddNewGroupContainer = styled.div`
  background: ${colors.newChatModalBg};
  display: flex;
  flex-direction: column;

  gap: 25px;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: auto;
`;


