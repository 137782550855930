import toast from "react-hot-toast";
import { colors } from "../Assets/styles/colors";

export const notifySuccess = (message) => {
    toast.success(message, {
        style: {
            border: `1px solid ${colors.purple}`,
            color: colors.white,
            background: colors.newChatModalInputBg
        },

    })
}

export const notifyError = (message) => {
    toast.error(message, {
        style: {
            border: `1px solid ${colors.errorColor}`,
            color: colors.white,
            background: colors.newChatModalInputBg
        },

    })
}



