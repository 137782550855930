import { useCallback, useEffect, useState } from "react"
const VisitorAPI = require("visitorapi");

export const useDefaultCountry = () => {

    const [defaultCountry, setDefaultCountry] = useState()

    useEffect(() => {
        VisitorAPI(
            "50VReJfnxckEmr5NzFaw",
            data => {
                setDefaultCountry(data.countryCode);
            }
        );
    }, [])

    return [defaultCountry]
}