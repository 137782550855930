import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import "../Assets/styles/customPhoneSelect.css"
import 'react-phone-number-input/style.css'
import Header from '../Components/GenericComponents/Header';
import SpinnerComponent from '../Components/GenericComponents/SpinnerComponent';
import CredentialsForDesktop from '../Components/Auth Components/CredentialsForDesktop';


const DesktopLoginSuccess = ({ appVerifier }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <SpinnerComponent isLoading={isLoading}>
            <FirstWrapper>
                <LoginPageContainer>
                    <Header />
                    <CredentialsForDesktop />
                </LoginPageContainer>
            </FirstWrapper>
        </SpinnerComponent>
    )
}

export default DesktopLoginSuccess
const LoginPageContainer = styled.div`
display:flex;
flex-direction:column;
width:100%;
max-height:calc(100vh - 40px);
@media(max-width:1440px){
    max-height:100vh;
}
`
const FirstWrapper = styled.div`
width:100%;
max-width: 1600px;
margin-left: auto;
margin-right: auto;
margin-top: 20px;
margin-bottom: 20px;
overflow: hidden;
}
@media(max-width:1440px){
    margin:0;
}
`