


import { POST } from "../API";
let source = null;

export const addGroupMembersAPI = (id, members, onSuccess, onError) => {
    const data = {
        id: id,
        members: members
    }
    POST(`/api/v1/chat/groups/add_members`, data, onSuccess, onError,);
};
