import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "../../Assets/styles/customPhoneSelect.css";
import "react-phone-number-input/style.css";
import { colors } from "../../Assets/styles/colors";
import { localizationStrings } from "../../Localization/Localization";
import { useConversationService } from "../../Context/ConversationsContext";
import { notifyError, notifySuccess } from "../../Helpers/Notifications";
import { useAuthService } from "../../Context/AuthContext";
import GroupSelectedMembers from "./GroupSelectedMembers";
import { ADD_MEMBERS, NEW_CONTACT, SELECTED_MEMBERS } from "../NewGroup/AddNewGroup";
import AddMembers from "../NewGroup/AddMembers";
import AddNewChatContact from "../NewChat/AddNewContact";



const GroupDetailsView = ({
  groupData,
  setConversations,
  setSelectedConversation,
  contacts,
  setLoading,
  loadContacts,
  handleInviteContact
}) => {

  const { removeGroupMember, addGroupMember, updateGroup, makeGroupAdmin, removeGroupAdmin } = useConversationService();

  const initialValues = {
    groupName: groupData.group.name,
  };

  const { user } = useAuthService();


  const [phone, setPhone] = useState("");
  const [inputValues, setInputValues] = useState(initialValues);
  const profileAvatarRef = useRef();
  const [previewAvatar, setPreviewAvatar] = useState()
  const [avatar, setAvatar] = useState();
  const [selectedContacts, setSelectedContacs] = useState([]);
  const [initialContacts, seInitialContacts] = useState([]);
  const [infoChanged, setInfoChanged] = useState(false);
  const [option, setOption] = useState(SELECTED_MEMBERS)

  const handleInputChange = (e) => {
    const { name, value } = e.currentTarget;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleOpenAvatar = () => {
    profileAvatarRef.current.click();
  };

  const handleAvatarChange = (e) => {
    setAvatar(e.target.files[0])
    setPreviewAvatar(URL.createObjectURL(e.target.files[0]));
  };


  const handleAddMember = (member, onSucces) => {
    addGroupMember(
      groupData.group.id,
      [member.id],
      function (response) {
        let res = response.data.conversation;
        setPhone(null)
        setConversations((prev) => {
          let mapped = prev.map((item) => {
            if (item.id === res.id) {
              return res
            } else {
              return item
            }
          })
          return mapped;
        })
        // notifySuccess(`${localizationStrings.chat_details.member_added}`)
        setSelectedConversation(res);
        if (onSucces) {
          onSucces();
        }

      },
      function (error) {
        notifyError(`${localizationStrings.profile.something_wrong}`)
        console.log(error)
      }
    )
  }

  const handleRemoveMember = (member) => {
    removeGroupMember(
      groupData.group.id,
      [member.id],
      function (response) {
        let res = response.data.conversation;

        if (member.id === user.id) {

          setConversations((prev) => {
            let mapped = prev.filter((item) =>
              item.id !== res.id
            )
            return mapped;
          })
          setSelectedConversation(null)
        } else {
          setConversations((prev) => {
            let mapped = prev.map((item) => {
              if (item.id === res.id) {
                return res
              } else {
                return item
              }
            })
            return mapped;
          })

          setSelectedConversation(res);
        }

      },
      function (error) {
        notifyError(`${localizationStrings.profile.something_wrong}`)
        console.log(error);
      }
    )
  }

  const handleUpdateGroup = () => {
    var objData = new FormData();

    objData.append("id", groupData.group.id);
    objData.append("name", inputValues.groupName);

    if (avatar) {
      objData.append("image", avatar)
    }

    updateGroup(
      objData,
      function (response) {
        let resp = response.data.conversation;
        setConversations((prev) => {
          let mapped = prev.map((item) => {
            if (item.id === resp.id) {
              return resp
            } else {
              return item
            }
          })
          return mapped
        })
        setSelectedConversation(resp)
      },
      function (error) {
        notifyError(`${localizationStrings.profile.something_wrong}`)
        console.log(error)
      }
    )

  }

  const handleMakeAdmin = (member) => {
    setLoading(true)
    makeGroupAdmin(
      groupData.group.id,
      [member.id],
      (response) => {
        let res = response.data.conversation;

        setConversations((prev) => {
          let mapped = prev.map((item) => {
            if (item.id === res.id) {
              return res
            } else {
              return item
            }
          })
          return mapped;
        })
        setLoading(false)
        setSelectedConversation(res);
      },
      (error) => {
        setLoading(false)
        notifyError(`${localizationStrings.profile.something_wrong}`)
        console.log(error)
      },
    )

  }

  const handleRevokeAdmin = (member) => {
    setLoading(true)
    removeGroupAdmin(
      groupData.group.id,
      [member.id],
      (response) => {
        let res = response.data.conversation;

        setConversations((prev) => {
          let mapped = prev.map((item) => {
            if (item.id === res.id) {
              return res
            } else {
              return item
            }
          })
          return mapped;
        })
        setLoading(false)
        setSelectedConversation(res);
      },
      (error) => {
        setLoading(false)
        notifyError(`${localizationStrings.profile.something_wrong}`)
        console.log(error)
      },
    )

  }


  const checkChanges = () => {
    if (inputValues.groupName != groupData.group.name ||
      previewAvatar != groupData.group.image
    ) {
      setInfoChanged(true)
    } else {
      setInfoChanged(false)
    }
  }

  const displayCurrentContactElement = (user_list) => {
    let newUserList = user_list;
    let currentUserFound = user_list.find((item) => item.id === user.id);


    if (!currentUserFound) {
      newUserList.push(user)
    }

    let sortedParticipants = newUserList.sort((a, b) => a.name > b.name ? 1 : -1);

    return sortedParticipants;

  }

  const handleBackBtn = () => {
    setOption(SELECTED_MEMBERS);
    setSelectedContacs(initialContacts)
  }

  const handleSaveBtn = () => {

    const newToAdd = selectedContacts.filter((selected) => !initialContacts.some((remove) => remove.id == selected.id));

    for (let index = 1; index <= newToAdd.length; index++) {


      if (newToAdd.length > 1) {
        if (index == newToAdd.length) {
          notifySuccess(localizationStrings.new_chat.members_added);
          setOption(SELECTED_MEMBERS)
          return
        }
        handleAddMember(newToAdd[index - 1],
          function () {
            handleAddMember(newToAdd[index])
          }
        )

      }
      else {
        handleAddMember(newToAdd[index - 1],
          function () {
            notifySuccess(localizationStrings.new_chat.members_added);
            setOption(SELECTED_MEMBERS)
          })

        return
      }
    }

  }


  const handleSelectNewContact = (contact) => {
    let foundInSelected = selectedContacts.find((item) => item.id === contact.id);
    let foundInInitial = initialContacts.find((item) => item.id === contact.id);

    if (foundInSelected) {
      if (foundInInitial) {
        return
      }
      let filtered = selectedContacts.filter((item) => item.id !== contact.id)
      setSelectedContacs(filtered)
    } else {
      setSelectedContacs([...selectedContacts, contact])
    }
  }

  const handleCloseAddNewContact = (item) => {
    setOption(ADD_MEMBERS)
  }


  useEffect(() => {
    checkChanges()
  }, [inputValues, previewAvatar])


  useEffect(() => {
    setInputValues(initialValues)
    let sortedParticipants = displayCurrentContactElement(groupData.other_participants)
    // let sortedParticipants = groupData.other_participants.sort((a, b) => a.name > b.name ? 1 : -1)
    seInitialContacts(sortedParticipants)
    setSelectedContacs(sortedParticipants)

    setPreviewAvatar(groupData.group.image)
  }, [groupData, groupData.other_participants?.length])

  return (
    <AddNewGroupContainer>
      {option === ADD_MEMBERS && <Wrapper>
        <AddMembers
          contacts={contacts}
          selectedContacts={selectedContacts}
          setSelectedContacs={setSelectedContacs}
          initialContacts={initialContacts}
          setOption={setOption}
          groupDetailsSide
          handleSelectContact={handleSelectNewContact}
          handleInviteContact={handleInviteContact}
          handleBackBtn={handleBackBtn}
          handleSaveBtn={handleSaveBtn}
        />;
      </Wrapper>
      }

      {
        option === SELECTED_MEMBERS &&
        <GroupSelectedMembers
          inputValues={inputValues}
          handleAvatarChange={handleAvatarChange}
          setOption={setOption}
          previewAvatar={previewAvatar}
          handleInputChange={handleInputChange}
          handleOpenAvatar={handleOpenAvatar}
          profileAvatarRef={profileAvatarRef}
          groupData={groupData}
          handleRemoveMember={handleRemoveMember}
          selectedContacts={selectedContacts}
          initialContacts={initialContacts}
          handleMakeAdmin={handleMakeAdmin}
          handleRevokeAdmin={handleRevokeAdmin}

        />
      }
      {option === NEW_CONTACT &&
        <Wrapper>
          <AddNewChatContact
            setIsAddNewContactOpen={handleCloseAddNewContact}
            setLoading={setLoading}
            loadContacts={loadContacts}
            handleInviteContact={handleInviteContact}
          />
        </Wrapper>

      }
      {
        infoChanged
        &&
        <Group>
          <CreateButton
            onClick={() => handleUpdateGroup()}
          >{localizationStrings.chat_details.save_changes}</CreateButton>
        </Group>
      }

    </AddNewGroupContainer>
  )

};

export default GroupDetailsView;

const AddNewGroupContainer = styled.div`
  background: ${colors.newChatModalBg};
  display: flex;
  flex-direction: column;
  padding: 24px 21px;
  gap: 25px;
  z-index: 2;
  width: 100%;
  height: 100%;
  max-height:calc(100vh - 74px);
  overflow: auto;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    color: ${colors.optionsGroupTitle};
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  cursor: pointer;
  max-height: 42px;
  max-width: 42px;
  min-width: 42px;
  min-height: 42px;
  position: relative;
  overflow: hidden;
  object-fit: contain;
  svg {
    position: relative;
    width: 100%;
    height: 100%;
  }
  img {
    object-fit:cover;
    position: relative;
    height: 43px;
    width: 43px;
  }
`;

const UserWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid #363e5180;
  padding: 8px 0;
  h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    color: ${colors.white};
  }
`;

const ChatOption = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  border-bottom: 1px solid #363e5180;
  &:last-child {
    border: none;
  }
  h1 {
    min-width: 75px;
    color: #ffffff99;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
  }
  h2 {
    text-transform: capitalize;
    color: ${colors.white};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
  }
`;

const PhoneNumberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomPhoneWrapper = styled.div`
  background: ${colors.newChatModalInputBg};
  padding: 0 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  input {
    padding: 14px 16px;
    color: ${colors.white};
    background: ${colors.newChatModalInputBg};
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    &:focus {
      outline: none;
    }
  }
`;

const CustomInput = styled.input`
  background: none;
  border: none;

  color: ${colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  &:focus-visible {
    outline: none;
  }
`;

const CustomNameInput = styled(CustomInput)`
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
`;

const Separator = styled.div`
  background: rgba(54, 62, 81, 0.5);
  height: 1px;
  width: 100%;
`;

const MebersList = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
  padding: 10px 0 10px 16px;
  gap: 8px;
`;

const CreateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  background: ${colors.purple};
  color: ${colors.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  border: none;
  border-radius: 10px;
  padding: 14px 15px;
  cursor: pointer;
  &:disabled{
opacity:0.5;
  }
`;

const UserAvatarWrapper = styled.div`
  position: relative;
`;

const PencilWrapper = styled.div`
  position: absolute;
  bottom: -5px;
  right: 0;
`;

const ContactNotFound = styled.div`
display:flex;
justify-content:center;
p{
font-size: 16px;
font-weight: 400;
line-height: 22px;
text-align: center;
color:${colors.white};
opacity:0.7;
padding-bottom:30px;
}
`
// const Wrapper = styled.div`
// display:flex;
// flex-direction:column;
// height:calc(100vh - 74px);
// overflow:auto;
// `


const AddMembersButton = styled.button`
display:flex;
align-items:center;
gap:10px;
font-size: 16px;
font-weight: 400;
line-height: 22px;
letter-spacing: -0.40799999237060547px;
color:${colors.purple};
border:none;
border-radius:10px;
 background: ${colors.newChatModalInputBg};
 padding:14px 16px;
 cursor:pointer;
`

const Wrapper = styled.div`
position:absolute;
height:100%;
top:0;
overflow:auto;
width:100%;
left:0;
background: ${colors.newChatModalBg};

gap: 25px;
display: flex;
  flex-direction: column;
`