import React, { useState } from 'react'
import styled from 'styled-components';
import { colors } from '../../Assets/styles/colors';
import { ReactComponent as BackIcon } from "../../Assets/icons/arrowBack.svg";
import { ReactComponent as SearchIcon } from "../../Assets/icons/searchIcon.svg";
import { localizationStrings } from '../../Localization/Localization';
import FoundContactElement from '../Chat/FoundContactElement';


import { ReactComponent as PlusIcon } from "../../Assets/icons/PlusIcon.svg";
import { useConversationService } from '../../Context/ConversationsContext';
import { notifyError } from '../../Helpers/Notifications';

const AddQuickChat = ({
  setIsAddQuickChatOpen,
  handleCreateQuickChat
}) => {

  const { createQuickChat } = useConversationService();
  const [chatName, setChatName] = useState("");




  return (
    <AddNewGroupContainer>
      <Container>
        <BackButton onClick={() => setIsAddQuickChatOpen(false)}>
          <BackIcon />
          {localizationStrings.profile.back}
        </BackButton>
        <ProfileTitle>
          <h1>{localizationStrings.new_chat.new_quick_chat}</h1>
        </ProfileTitle>
        <div style={{ minWidth: "70px", display: "flex", justifyContent: "end" }}>
        </div>

      </Container>
      <Group>
        <TitleWrapper>
          <h1>{localizationStrings.new_chat.name}</h1>
        </TitleWrapper>
        <OptionWrapper>
          <CustomNameInput
            value={chatName}
            placeholder={localizationStrings.new_chat.quick_chat_placeholder}
            onChange={(e) => setChatName(e.currentTarget.value)}
            name="qickChatName"
            maxLength={100}
          ></CustomNameInput>
        </OptionWrapper>
      </Group>
      <Group>
        <CreateButton
          disabled={chatName.length <= 2}
          onClick={() => handleCreateQuickChat(chatName)}
        >{localizationStrings.new_chat.create_quick_chat}</CreateButton>
      </Group>
    </AddNewGroupContainer >


  )
}

export default AddQuickChat
const AddNewGroupContainer = styled.div`
  background: ${colors.newChatModalBg};
  display: flex;
  flex-direction: column;
  padding: 24px 21px;
  gap: 25px;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: auto;
`;


const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;


const ProfileTitle = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  h1 {
    color: ${colors.white};
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.newChatModalBg};
  min-height: 38px;
`;


const BackButton = styled.button`
min-width:70px;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  gap: 10px;
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    color: ${colors.optionsGroupTitle};
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 16px;
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
`;


const CustomInput = styled.input`
  background: none;
  border: none;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  width:100%;
  &:focus-visible {
    outline: none;
  }
`;

const CustomNameInput = styled(CustomInput)`
    font-size: 18px;
  font-weight: 500;
  line-height: 20px;
`;

const CreateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  background: ${colors.purple};
  color: ${colors.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  border: none;
  border-radius: 10px;
  padding: 14px 15px;
  cursor: pointer;
  &:disabled{
opacity:0.5;
  }
`;