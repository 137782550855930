import { GET, POST } from "../API"
let source;

export const getWebsockerUrlApi = (onSuccess, onError) => {
    source = GET("/api/v1/identity/websocket/connection_url", onSuccess, onError, true, source)
}

export const getQcWebsockerUrlApi = (conversation_id, onSuccess, onError) => {
    const data = {
        conversation_id: conversation_id
    }

    source = POST("/api/v1/identity/websocket/connection_url_for_quick_chat", data, onSuccess, onError, true, source)
}