import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as AvatarUnset } from '../../Assets/icons/unsetAvatar.svg'
import NewChatButton from './NewChatButton'
import { useAuthService } from '../../Context/AuthContext'

const UserMenu = ({
    setIsProfileOpen,
    setIsAddNewChatGroupOpen,
    setIsAddNewChatOpen,
    setIsAddQuickChatOpen

}) => {

    const { user } = useAuthService();


    return (

        <Container>
            <UserAvatar >
                {user.profile_picture ? <img src={user.profile_picture} alt='avatar' onClick={() => setIsProfileOpen(true)} /> : <AvatarUnset onClick={() => setIsProfileOpen(true)} />}
            </UserAvatar>

            <NewChatButton
                setIsAddNewChatGroupOpen={setIsAddNewChatGroupOpen}
                setIsAddNewChatOpen={setIsAddNewChatOpen}
                setIsAddQuickChatOpen={setIsAddQuickChatOpen}
            />

        </Container>

    )
}

export default UserMenu
const Container = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
padding:16px 20px;
background:${colors.userMenuHeaderBg};
`
const UserAvatar = styled.div`
display:flex;
align-items:center;
justify-content:center;
border-radius:100px;
border:1px solid ${colors.purple};
cursor:pointer;
max-height:42px;
max-width:42px;
min-height:42px;
object-fit:contain;
position:relative;
overflow:hidden;
svg{
   
    position:relative;
    width:100%;
    height:100%;
}
img{
     object-fit:cover;
    height:42px;
    width:42px;
     position:relative;

}
`
