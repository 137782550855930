
import React, { useEffect, useReducer, useRef, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { localizationStrings } from '../../Localization/Localization'
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { ReactComponent as CopyIcon } from "../../Assets/icons/context-menu-icons/CopyIcon.svg";

const QcModal = ({ handleShareBtn, setIsQcModalOpen, qcLink }) => {

    const handleCloseModal = () => {
        qcLink = null;
        setIsQcModalOpen(false)

    }

    return (
        <FullScreenItem>
            <RatingCard>
                <Line>
                    <ModalTitle>
                        <h1>{localizationStrings.new_chat.new_quick_chat} </h1>
                    </ModalTitle>
                    <CloseBtn onClick={() => handleCloseModal()}>
                        <CloseIcon />
                    </CloseBtn>
                </Line>

                <Group>
                    <SubtitleWrapper>
                        <p>{localizationStrings.quick_chat.cautionModal}</p>
                    </SubtitleWrapper>
                </Group>

                <TitleWrapper>
                    <h1>{localizationStrings.chat_details.generated_link} </h1>
                </TitleWrapper>
                <Group>
                    <InputCopyWrapper>
                        <CustomInput
                            readOnly
                            value={qcLink}
                        />
                    </InputCopyWrapper>
                    <ShareBtn onClick={() => handleShareBtn(qcLink)}>
                        Copy link
                    </ShareBtn>
                </Group>
            </RatingCard>
        </FullScreenItem >

    )
}

export default QcModal

const FullScreenItem = styled.div`
display:flex;
align-items:center;
justify-content:center;
position:fixed;
width:100%;
height:100%;
background:rgba(0,0,0,0.3);
left:0;
top:0;
z-index:1;
`
const RatingCard = styled.div`
display:flex;
flex-direction:column;
min-height:200px;
max-width:400px;
width:100%;
background:${colors.newChatModalBg};
border-radius:8px;
padding:30px;
align-items:center;
position:relative;


`
const TitleWrapper = styled.div`
display:flex;
width:100%;
margin:0 0 20px 0;
  h1 {
    color: ${colors.optionsGroupTitle};
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
  }
`
const CustomInput = styled.input`
display:flex;
padding:13px 16px;
background:none;
outline:none;
color:white;
font-size: 16px;
font-weight: 400;
line-height: 22px;
text-align: left;
border:none;
border-right:1px solid ${colors.chatElementColor};
width:100%;
`
const Line = styled.div`
display:flex;
align-items:center;
width:100%;
`
const CloseBtn = styled.button`
position:absolute;
display:flex;
align-items:center;
background:none;
border:none;
cursor:pointer;
right:30px;

`
const Group = styled.div`
display:flex;
flex-direction:column;
gap:25px;
width:100%;
`
const InputCopyWrapper = styled(Line)`
border:1px solid ${colors.chatElementColor};
border-radius:10px;
overflow:hidden;
color:white;
width:100%;
`
const CopyBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
border:none;
background:none;
color:white;
padding:13px 16px;
font-size: 14px;
font-weight: 400;
cursor:pointer;
color:${colors.purple};
`
const ShareBtn = styled.button`
display:flex;
justify-content:center;
align-items:center;
width:100%;
color:${colors.white};
border-radius:10px;
border:none;
background:${colors.purple};;
padding:14px;
cursor:pointer;
`
const SubtitleWrapper = styled.div`
display: flex;
justify-content: space-between;
margin:20px 0;
p {
  color: ${colors.chatElementColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;

}
`

const ModalTitle = styled.div`
display: flex;
margin-left:auto;
margin-right:auto;
    h1{
        font-size:20px;
        line-height:24px;
        font-weight:600;
        color:${colors.white};
    }

`