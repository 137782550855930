import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as NewChatIcon } from "../../Assets/icons/newChatIcon.svg";
import { ReactComponent as QuickChatIcon } from "../../Assets/icons/QuickChatIcon.svg";
import { colors } from "../../Assets/styles/colors";
import 'react-phone-number-input/style.css'
import "../../Assets/styles/customPhoneSelect.css"
import { localizationStrings } from "../../Localization/Localization";
import { ReactComponent as NewGroupIcon } from '../../Assets/icons/groupIcon.svg'


const NewChatButton = ({

  setIsAddNewChatGroupOpen,
  setIsAddNewChatOpen,
  setIsAddQuickChatOpen
}) => {

  const btnRef = useRef();
  const ref = useRef();

  const [isNewChatOpen, setIsNewChatOpen] = useState(false);



  var leftDistance = 0;
  var topDistance = 0;

  const handleOpenNewChatModal = (e) => {
    setIsNewChatOpen(!isNewChatOpen);
  };


  if (btnRef.current) {
    let distanceFromLeftOfView = btnRef.current.getBoundingClientRect().left
    let distanceFromTopOfView = btnRef.current.getBoundingClientRect().top

    if (btnRef.current.offsetLeft > 130) {
      leftDistance = distanceFromLeftOfView - 150;
    }

    topDistance = distanceFromTopOfView + 50;
  }






  const handleOpenAddNewChatGroup = () => {
    setIsNewChatOpen(false)
    setIsAddNewChatGroupOpen(true);
  }

  const handleOpenAddNewChat = () => {
    setIsNewChatOpen(false)
    setIsAddNewChatOpen(true)
  }


  const handleOpenAddQuickChat = () => {
    setIsNewChatOpen(false)
    setIsAddQuickChatOpen(true)
  }



  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isNewChatOpen && ref.current && !ref.current.contains(e.target)) {
        setIsNewChatOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isNewChatOpen]);



  return (
    <Container>
      <NewChatButtonWrapper ref={btnRef} onClick={(e) => handleOpenNewChatModal(e)}>
        <NewChatIcon />
        {localizationStrings.chat.new}
      </NewChatButtonWrapper>

      {isNewChatOpen &&
        (
          <FullscreenWrapper>
            <NewChatCard topdistance={topDistance} leftdistance={leftDistance} ref={ref}>
              <ButtonOptionsWrapper>
                <Group>
                  <ChatOptionBtn onClick={() => handleOpenAddNewChat()}><NewChatIcon />{localizationStrings.new_chat.chat}</ChatOptionBtn>
                  <ChatOptionBtn onClick={() => handleOpenAddNewChatGroup()}><NewGroupIcon />{localizationStrings.new_chat.group}</ChatOptionBtn>
                  <ChatOptionBtn onClick={() => handleOpenAddQuickChat()}><QuickChatIcon />{localizationStrings.new_chat.quick_chat}</ChatOptionBtn>
                </Group>
              </ButtonOptionsWrapper>

            </NewChatCard>
          </FullscreenWrapper>
        )
      }

    </Container >
  );
};

export default NewChatButton;
const Container = styled.div``;
const NewChatButtonWrapper = styled.div`

  display: flex;
  align-items: center;
  gap: 3px;
  background: ${colors.purple};
  color: ${colors.white};
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 10px;
  padding: 11px 15px;
  cursor: pointer;
  svg {
    height: 20px;
    width: 20px;
  }
`;
const FullscreenWrapper = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #120a0a87;
  z-index: 2;
`;
const NewChatCard = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
 background:${colors.newButtonOptionsBg};
  border-radius: 20px;
  top: ${(props) => props.topdistance}px;
  left: ${(props) => props.leftdistance}px;
  overflow:hidden;
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  label {
    text-transform:uppercase;
    color: #757575;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
  } 
`;
const ButtonOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;  
`;
const ChatOptionBtn = styled.button`
cursor:pointer;
  display: flex;
  align-items: center;
  color: ${colors.white};
  background:none;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  width: 100%;
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
gap:10px;
`