export const displayName = (conversation) => {

    let otherUser = conversation?.other_participants[0];

    let name = otherUser?.name;

    if (conversation?.group) {
        name = conversation.group.name
    }
    if (conversation?.quick_chat) {
        name = conversation.quick_chat_name
    }

    return name
}