import React, { Suspense } from 'react'
import styled from 'styled-components';
import { colors } from '../../Assets/styles/colors';
import { ReactComponent as AvatarUnset } from '../../Assets/icons/unsetAvatar.svg'

const ForwardModalContactElement = ({ item, selected, handleSelectContact, invite, handleInvite, noCheck }) => {

  const handleClick = (item) => {
    handleSelectContact(item)
  }

  return (

    <Warpper onClick={() => handleClick(item)} >
      <FoundContact  >
        <FoundContactAvatar>
          {item.profile_picture ? <img src={item.profile_picture} alt='avatar' /> : <AvatarUnset />}
        </FoundContactAvatar>
        <p>{item.name}</p>

        {invite ?
          <InviteButton >
            Invite
          </InviteButton>
          :
          !noCheck &&
          <CustomCheckBox >
            <label className="container">
              <input
                type="checkbox"
                checked={selected}
                readOnly
              />
              <span
                className="checkmark">
              </span>
            </label>
          </CustomCheckBox>

        }

      </FoundContact>
    </Warpper>

  )
}

export default ForwardModalContactElement
const Warpper = styled.div`
display:block;
z-index:1;
`
const FoundContact = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor:pointer;
  
  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: ${colors.white};
    width: 100%;
  }
`;
const FoundContactAvatar = styled.div`
  display: flex;
  height:35px;
  width:35px;
  max-width: 35px;
  min-height: 35px;
  min-width: 35px;
  max-height: 35px;
  overflow: hidden;
  border-radius: 100%;
  // height: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  svg{
    object-fit: cover;
   width: 100%;
   height: 100%;
 }
`;
const CustomCheckBox = styled.div`
display:block;
z-index:-1;
  .container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-left: 35px;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

  }

  /* Hide the browser's default checkbox */
  .container input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    z-index:0;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid rgba(91, 91, 93, 1);
    border-radius: 50%;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: rgba(112, 105, 253, 1);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 7px;
    top: 3.5px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const InviteButton = styled.button`
border:none;
background:none;
font-size: 16px;
font-weight: 400;
line-height: 22px;
color:${colors.purple};
cursor:pointer;
`