import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "../Assets/styles/customPhoneSelect.css";
import "react-phone-number-input/style.css";
import { useAuthService } from "../Context/AuthContext";
import SpinnerComponent from "../Components/GenericComponents/SpinnerComponent";
import { localizationStrings } from "../Localization/Localization";
import { colors } from "../Assets/styles/colors";

import "../Assets/styles/customPhoneSelect.css";
import "react-phone-number-input/style.css";
import { ReactComponent as PhoneInputIcon } from "../Assets/icons/enterPhoneNumberIcon.svg";
import { useSearchParams } from "react-router-dom";

const LoginPage = ({ appVerifier }) => {
  const isElectron = !!window.electron;

  const navigateInNewTab = (to) => {
    window.open("https://www.emotii.ai/terms");
  };

  const WEB_REDIRECT_URL = process.env.REACT_APP_ELECTRON_TO_WEB_URL

  const {
    getCurrentUser,
  } = useAuthService();
  const { signInWithCustomAcessToken } = useAuthService();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams()

  const onBtnClick = () => {
    if (
      window.electron &&
      typeof window.electron.openExternalUrl === "function"
    ) {
      window.electron.openExternalUrl(WEB_REDIRECT_URL);
    } else {
      console.error("Electron API is not available or function not defined");
    }
  };

  useEffect(() => {
    // const access_token = "eyJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay13c3MwbUBlbW90aWktOWY5NzYuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJzdWIiOiJmaXJlYmFzZS1hZG1pbnNkay13c3MwbUBlbW90aWktOWY5NzYuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTcyMzc5NjM3NCwiZXhwIjoxNzIzNzk5OTc0LCJ1aWQiOiJrTHlOQTBKNXI3Y2pqdzV2OW9wWjA4SFQwSDcyIiwiY2xhaW1zIjp7fX0.fIRyjk_ddRMYDnZi8P_WW98d7zMghnt-eMdNzRctx6qMdNvhbSKLNDVvbZt5bfOo9STdK7fwjeKS0806KgkDy2OM_XDeQVnUpUUwYzk4XUp6XDOEo6qbM2JgoznrINdOovf1f0Z9yzPtKY2dlZTQW5QTLLh6Lw2nubEGLcPuhn3OauKAg0CwxlAdK9vRF1uTcjDxPP2tY0WxTcMBh_jVXZBgA5jHSBABJLxGsqVKoFhl-OKu0rs1Xm7aKiBKERFqVkX75j7fVNGuuDEb5PWYDVKMesWB9zdF_UGJMtnggIJveyJ9sfA4UBdqWa2-sNCdeWHBiWfGbmI1I7z2Q0kkBQ"
    // signInWithCustomAcessToken(access_token,
    //   (response) => {
    //     getCurrentUser(
    //       () => { },
    //       () => { },
    //     )
    //     setIsLoading(false)

    //   },
    //   (error) => {
    //     console.log(error)
    //     setIsLoading(false)
    //   },
    // )

    // Ensure `window.electron` is available
    if (isElectron) {
      const handleDeepLink = (url) => {
        const receivedUrl = new URL(url)
        const params = new URLSearchParams(receivedUrl.searchParams)
        const access_token = params.get("access_token")
        if (access_token && access_token != "") {
          setIsLoading(true)
          signInWithCustomAcessToken(access_token,
            (response) => {
              getCurrentUser(
                () => { },
                () => { },
              )
              setIsLoading(false)
            },
            (error) => {
              console.log(error)
              setIsLoading(false)
            },
          )
        }
      };

      if (
        window.electron && typeof window.electron.onDeepLink === "function"
      ) {
        window.electron.onDeepLink(handleDeepLink);
        return () => {
          window.electron.onDeepLink(() => { });
        }
      }
    }
    else {
      console.error("Electron API not available");
    }
  }, []);


  return (
    <SpinnerComponent isLoading={isLoading}>
      <FirstWrapper>
        <LoginPageContainer>
          <Container>
            <LeftSide>
              <Card>
                <Group>
                  <LoginButton
                    id="sign-in-button"
                    onClick={(e) => onBtnClick(e)}
                  >
                    {localizationStrings.login.authenticate}
                  </LoginButton>
                  <TermsInfo
                    onClick={() => navigateInNewTab("terms-and-conditions")}
                  >
                    <p>
                      {localizationStrings.login.login_agree}
                      <br />
                      <span>
                        {localizationStrings.login.terms_and_conditions}
                      </span>
                    </p>
                  </TermsInfo>
                </Group>
              </Card>
            </LeftSide>
            <RightSide>
              <PhoneInputIcon />
            </RightSide>
          </Container>
        </LoginPageContainer>
      </FirstWrapper>
    </SpinnerComponent>
  );
};

export default LoginPage;
const LoginPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 40px);
  @media (max-width: 1440px) {
    max-height: 100vh;
  }
`;
const FirstWrapper = styled.div`
width:100%;
max-width: 1600px;
margin-left: auto;
margin-right: auto;
margin-top: 20px;
margin-bottom: 20px;
overflow: hidden;
}
@media(max-width:1440px){
    margin:0;
}
`;
const Container = styled.div`
  display: flex;
  min-height: 600px;
  max-height: 100vh;
  height: 100%;
  width: 100%;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  gap: 20px;
`;
const CustomPhoneWrapper = styled.div`
  background: ${colors.newChatModalInputBg};
  padding: 0 0 0 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  h1 {
    color: ${colors.white};
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0px;
    text-align: left;
  }
  input {
    padding: 14px 16px;
    color: ${colors.white};
    background: ${colors.newChatModalInputBg};
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    &:focus {
      outline: none;
    }
  }
`;
const LoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  background: ${colors.purple};
  color: ${colors.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  border: none;
  border-radius: 10px;
  padding: 14px 15px;
  cursor: pointer;
  svg {
    height: 20px;
    width: 20px;
  }
  &:disabled {
    opacity: 0.5;
  }
`;
const LeftSide = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
`;
const RightSide = styled(LeftSide)``;

const TermsInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #b5b8cd;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
  }

  span {
    cursor: pointer;
    color: ${colors.purple};
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    text-decoration: underline;
  }
`;
