
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as FileIcon } from "../../Assets/icons/fileIcon.svg"
const FileMessageComponent = ({ file }) => {

    const returnMb = (size) => {
        if (!size) {
            return
        }

        let mb = (size / 1000000)
        let kb = (size / 1000)

        if (mb.toFixed(2) <= 1.00) {
            return `${kb.toFixed(2)} Kb`
        } else {
            return `${mb.toFixed(2)} Mb`
        }

    }
    return (
        <Container onClick={() => window.open(file.url)}>
            <FileIcon />
            <InfoWrapper>
                <h1>{file.name}</h1>
                <p>{returnMb(file.size)}</p>
            </InfoWrapper>
        </Container>
    )
}

export default FileMessageComponent

const Container = styled.div`
display:flex;
border-radius:16px;
min-width:100px;
width:100%;
align-items:center;
gap:10px;
cursor:pointer;
max-width:400px;
svg{
    color: ${colors.white};
    min-width:40px;
    max-width:40px;
    max-height:40px;
}

@media(max-width:450px){
    max-width:200px;
}
`
const InfoWrapper = styled.div`
display:flex;
flex-direction:column;
width:100%;
overflow:hidden;
h1 {
    white-space:pre;
    text-overflow:ellipsis;
    overflow:hidden;
    color: ${colors.white};
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }
  p {
    color: ${colors.chatElementColor};
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
  }
`