import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { localizationStrings } from '../../Localization/Localization'
import { colors } from '../../Assets/styles/colors'

const CloseQcConfirmation = ({ open, handleClose, handleRemove }) => {

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

        >
            <DialogTitle id="alert-dialog-title" style={{ background: colors.background,color: colors.chatElementColor }}>{"Warning"}</DialogTitle>
            <DialogContent style={{ background: colors.background }}>
                <DialogContentText id="alert-dialog-description" style={{ color: colors.chatElementColor }}>
                    {localizationStrings.quick_chat.caution_close_qc}
                </DialogContentText>
            </DialogContent>
            <DialogActions  style={{ background: colors.background }}>
                <Button onClick={handleClose}>  {localizationStrings.quick_chat.cancel}</Button>
                <Button style={{ color: "red" }} onClick={handleRemove} autoFocus>
                    {localizationStrings.chat.close}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default CloseQcConfirmation