import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import QcInputLine from "./QcInputLine";
import { notifyError, notifySuccess } from "../../Helpers/Notifications";
import {
  createDateSeparators,
  findTranslatedTextForUser,
} from "../../Helpers/chatHelper/ChatHelper";
import MessageReceived from "../Chat/MessageReceived";
import moment from "moment";
import Message from "../Chat/Message";
import SendFile from "../Chat/SendFile";
import SendMedia from "../Chat/SendMedia";
import RightClickMenu from "../Chat/RightClickMenu";
import { contextMenu, useContextMenu } from "react-contexify";
import copy from "copy-to-clipboard";
import ReplyMessageContainer from "../Chat/ReplyMessageContainer";
import EditMessageContainer from "../Chat/EditMessageContainer";
import DateSeparator from "../Chat/DateSeparator";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInViewport } from "react-in-viewport";
import MessageMoodSelector from "../Chat/MessageMoodSelector";
import { useExpanded } from "../../hooks/useExpanded";

const QcChatComponent = ({
  messages,
  user,
  selectedConversation,
  handleSendMessage,
  handleSendMedia,
  handleSendFile,
  shouldScroolToCurrentMessage,
  shouldScrollBottom,
  handleGetMoreMessages,
  handleShowFullScreenImg,
  handleEditMessage,
  handleReplyMessage,
  handleReactToMessage,
  handleReplyWithMedia,
  handleReplyWithFile,
  shouldLoadMoreMessages,
  messageMood,
  setMessageMood
}) => {
  const MENU_ID = "menu-id";
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const [inputValue, setInputValue] = useState("");
  const [fileInput, setFileInput] = useState(null);
  const [mediaInput, setMediaInput] = useState(null);

  const [showEditMessageModal, setShowEditMessageModal] = useState(false);
  const [isAttachmentMenuOpen, setIsAttachmentMenuOpen] = useState(false);
  const [showReplyMessageModal, setShowReplyMessageModal] = useState(false);
  const [selectedMessageForContextMenu, setSelectedMessageForContextMenu] =
    useState();
  const [showMoodSelector, togleModselector] = useExpanded()

  const mediaInputFileRef = useRef();
  const fileInputRef = useRef();
  const messageInputRef = useRef();

  function displayRightClickMenu(e, messageId, conversationId, messageObject) {
    e.preventDefault();
    setShowEditMessageModal(false);
    setShowReplyMessageModal(false);
    setSelectedMessageForContextMenu({
      messageId: messageId,
      conversationId: conversationId,
      messageObject: messageObject,
    });

    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show({
      event: e,
    });
  }

  const handleCloseReply = () => {
    setShowReplyMessageModal(false);
  };

  const handleCloseEdit = () => {
    setShowEditMessageModal(false);
    setInputValue("")
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSendButton();
    }
  };

  const handleMediaInputFileChange = (e) => {
    if (!e.target.files) {
      return;
    }

    const inputFile = e.target.files[0];
    const fileType = inputFile.type.split("/");
    if (fileType[0] == "image" || fileType[0] == "video") {
      setMediaInput(inputFile);
    } else {
      notifyError("Please select valid file");
      return;
    }
  };

  const handleInputFileChange = (e) => {
    if (!e.target.files) {
      return;
    }
    e.preventDefault();

    const inputFile = e.target.files[0];
    setFileInput(inputFile);
  };

  const handleMessageInputChange = (e) => {
    const { value } = e.currentTarget;
    setInputValue(value);
  };

  const handleOpenUploadMedia = () => {
    mediaInputFileRef.current.click();
  };

  const handleOpenUploadFile = () => {
    fileInputRef.current.click();
  };

  const handleReportMessage = () => {
    window.location = "mailto:support@emotii.ai";
  };

  const handleCopyOption = (message) => {
    copy(message.original_message);
    notifySuccess("Copy");
  };

  const handleReplyMessageOption = () => {
    setShowReplyMessageModal(true);
    messageInputRef?.current?.focus();
  };

  const handleEditMessageOption = () => {
    setShowEditMessageModal(true);
    setInputValue(selectedMessageForContextMenu.messageObject.original_message);
    messageInputRef?.current?.focus();
  };

  const getMessageToEdit = () => {
    if (
      !selectedMessageForContextMenu ||
      !selectedMessageForContextMenu.messageObject
    ) {
      return;
    }

    return selectedMessageForContextMenu.messageObject;
  };

  const getMessageToReply = () => {
    if (
      !selectedMessageForContextMenu ||
      !selectedMessageForContextMenu.messageObject
    ) {
      return;
    }
    return selectedMessageForContextMenu.messageObject;
  };

  const handleSendButton = () => {
    if (showEditMessageModal) {
      handleEditMessage(
        selectedMessageForContextMenu.conversationId,
        selectedMessageForContextMenu.messageId,
        inputValue,
        () => {
          handleCloseEdit();
        }
      );
      return;
    }

    if (showReplyMessageModal) {
      handleReplyMessage(
        inputValue,
        selectedMessageForContextMenu.conversationId,
        selectedMessageForContextMenu.messageId,
        () => {
          setShowReplyMessageModal(false);
          setSelectedMessageForContextMenu(false);
          setInputValue("");
        }
      );
      return;
    }

    handleSendMessage(inputValue, () => {
      setInputValue("");
    });
  };

  const resetInputs = () => {
    mediaInputFileRef.current.value = "";
    fileInputRef.current.value = "";
    setInputValue("");
  };

  const handleSendMediaButton = (type, file, text, thumbnail) => {
    if (showReplyMessageModal) {
      handleReplyWithMedia(
        selectedMessageForContextMenu.conversationId,
        selectedMessageForContextMenu.messageId,
        type,
        file,
        text,
        thumbnail,
        () => {
          resetInputs();
          handleCloseReply();
        }
      );
    } else {
      resetInputs();
      handleSendMedia(
        type,
        file,
        text,
        thumbnail,
        () => {
          resetInputs();
        },
        () => {
          resetInputs();
        }
      );
    }
  };

  const handleSendFileButton = (file, text) => {
    if (showReplyMessageModal) {
      handleReplyWithFile(
        selectedMessageForContextMenu.conversationId,
        selectedMessageForContextMenu.messageId,
        file,
        text,
        () => {
          handleCloseReply();
          resetInputs();
        }
      );
    } else {
      handleSendFile(
        file,
        text,
        () => {
          resetInputs();
        },
        () => {
          resetInputs();
        }
      );
    }
  };

  const onConversationWrapperScroll = (e) => {
    contextMenu.hideAll();
    setIsAttachmentMenuOpen(false);
  };

  useEffect(() => {
    let element = document.getElementById("qcMessageList");

    if (shouldScrollBottom.current === true) {
      element.scrollTo("top", element.scrollHeight);
    }
  }, [messages, shouldScrollBottom.current]);

  let allMessages = createDateSeparators(messages);
  return (
    <Container>
      <MessagesWrapper id={"qcMessageList"}>
        <InfiniteScroll
          dataLength={allMessages.length}
          next={handleGetMoreMessages}
          hasMore={shouldLoadMoreMessages.current}
          onScroll={(e) => onConversationWrapperScroll(e)}
          scrollableTarget="qcMessageList"
          style={{ display: "flex", flexDirection: "column-reverse", overflow: "auto" }}
          inverse

        >

          {allMessages.reverse().map((message, index) => {
            if (message.id) {
              const isMyMessage = user.id === message.user_id;
              if (isMyMessage) {
                return (
                  <Message
                    key={message.id}
                    text={message.original_message}
                    image={message.image}
                    status={message.status}
                    sentTime={moment(message.device_sent_date).format("HH:mm")}
                    reactions={message.reactions}
                    messageId={message.id}
                    conversationId={message.conversation_id}
                    handleShowFullScreenImg={handleShowFullScreenImg}
                    messageDeleted={message.deleted}
                    displayRightClickMenu={displayRightClickMenu}
                    isMessageRead={message.read}
                    messageObject={message}
                    forwarded={message.forwarded}
                    messageParent={message.message_parent}
                    file={message.document}
                    video={message.video}
                  />
                );
              } else {
                return (
                  <MessageReceived
                    key={message.id}
                    image={message.image}
                    messageId={message.id}
                    conversationId={message.conversation_id}
                    originalText={message.original_message}
                    language={message.language_name}
                    translatedText={findTranslatedTextForUser(
                      message.message_translations,
                      user
                    )}
                    sentTime={moment(message.device_sent_date).format("HH:mm")}
                    handleReactToMessage={handleReactToMessage}
                    reactions={message.reactions}
                    handleShowFullScreenImg={handleShowFullScreenImg}
                    messageDeleted={message.deleted}
                    displayRightClickMenu={displayRightClickMenu}
                    user={message.user}
                    selectedConversation={selectedConversation}
                    translatedFromLanguage={message.translated_from_language}
                    messageObject={message}
                    forwarded={message.forwarded}
                    messageParent={message.message_parent}
                    file={message.document}
                    video={message.video}
                  />
                );
              }

            } else {
              return <DateSeparator key={index} date={message.date} />;
            }

          })}

          {showReplyMessageModal && (
            <ReplyMessageContainer
              message={getMessageToReply()}
              handleClose={handleCloseReply}
              user={user}
            />
          )}
          {showEditMessageModal && (
            <EditMessageContainer
              message={getMessageToEdit()}
              handleCloseEdit={handleCloseEdit}
            />
          )}
          {
            showMoodSelector &&
            <MessageMoodSelector
              isMenuOpen={showMoodSelector}
              messageMood={messageMood}
              closeModal={togleModselector}
              setMood={setMessageMood}
            />
          }

        </InfiniteScroll>
      </MessagesWrapper>
      <QcInputLine
        inputValue={inputValue}
        setInputValue={setInputValue}
        mediaInputFileRef={mediaInputFileRef}
        fileInputRef={fileInputRef}
        messageInputRef={messageInputRef}
        showEditMessageModal={showEditMessageModal}
        isAttachmentMenuOpen={isAttachmentMenuOpen}
        setIsAttachmentMenuOpen={setIsAttachmentMenuOpen}
        showReplyMessageModal={showReplyMessageModal}
        handleCloseEdit={handleCloseEdit}
        handleCloseReply={handleCloseReply}
        handleMediaInputFileChange={handleMediaInputFileChange}
        handleOpenUploadMedia={handleOpenUploadMedia}
        handleInputFileChange={handleInputFileChange}
        handleOpenUploadFile={handleOpenUploadFile}
        handleSendButton={handleSendButton}
        onKeyDown={handleKeyDown}
        togleModselector={togleModselector}
        messageMood={messageMood}
      />
      {fileInput && (
        <SendFile
          fileInput={fileInput}
          setFileInput={setFileInput}
          handleSendFileButton={handleSendFileButton}
          selectedConversation={selectedConversation}
          fileInputRef={fileInputRef}
          inputValue={inputValue}
          messageInputRef={messageInputRef}
          handleInputChange={handleMessageInputChange}
        />
      )}

      {mediaInput && (
        <SendMedia
          mediaInput={mediaInput}
          setMediaInput={setMediaInput}
          handleSendMedia={handleSendMediaButton}
          selectedConversation={selectedConversation}
          mediaInputFileRef={mediaInputFileRef}
          inputValue={inputValue}
          messageInputRef={messageInputRef}
          handleInputChange={handleMessageInputChange}
        />
      )}
      <RightClickMenu
        handleReportMessage={handleReportMessage}
        handleEditMessageOption={handleEditMessageOption}
        handleReplyMessageOption={handleReplyMessageOption}
        handleCopyOption={handleCopyOption}
        contextMenuItem={selectedMessageForContextMenu}
        selectedConversation={selectedConversation}
        user={user}
        currentConversationMessages={messages}
      />
    </Container>
  );
};

export default QcChatComponent;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.chatBg};
  height: 100%;
  position: relative;
 min-height: calc(100svh - 74px);
  max-height: calc(100svh - 74px);
  margin-top:74px;
`;

const MessagesWrapper = styled.div`
  height: 100%;
  min-height: calc(100svh - 148px);
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;

`;
