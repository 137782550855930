import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { localizationStrings } from '../../Localization/Localization'
import { Item, Menu } from "react-contexify";


const CustomItem = ({ text, icon, redButton }) => {
    return (
        <CustomItemContainer redButton={redButton}>
            <p>{text}</p>
            {icon}
        </CustomItemContainer>
    );
};

const buttonList = [
    {
        id: "hide_conversation",
        text: localizationStrings.context_menu.hide_chat,

    },
    {
        id: "delete_conversation",
        text: localizationStrings.context_menu.delete_chat,
        redButton: true,
        withConfirmation: true
    },

]


const ConversationListContextMenu = ({
    selectedContextMenuItem,
    handleDeleteConversation,
    handleHideConversation,
    setDeleteConfirmationOpen
}) => {

    const MENU_ID = "conversationsList-id";

    function handleItemClick({ id }) {
        switch (id) {
            case "delete_conversation":
                setDeleteConfirmationOpen(true)
                break;
            case "hide_conversation":
                handleHideConversation(selectedContextMenuItem.id)
                break;
            default:
                break;
        }
    }
    const filterButtons = (buttons) => {
        let availableOptions = buttons;
        if (!selectedContextMenuItem) {
            return availableOptions
        }

        // if (selectedContextMenuItem.with_emotii) {
        //     availableOptions = []
        // }

        return availableOptions
    }


    return (
        <StyledMenu id={MENU_ID}>
            {filterButtons(buttonList).map((item, index) => {
                if (item.withConfirmation) {
                    return (
                        <Item onClick={handleItemClick} id={item.id} key={index}>
                            <CustomItem onClick={handleItemClick} text={item.text} redButton={item.redButton} />
                        </Item>
                    )
                } else {
                    return (
                        <Item onClick={handleItemClick} id={item.id} key={index}>
                            <CustomItem onClick={handleItemClick} text={item.text} redButton={item.redButton} />
                        </Item>
                    )
                }
            }
            )}
        </StyledMenu>
    )
}

export default ConversationListContextMenu


const StyledMenu = styled(Menu).attrs({
    // custom props
})`
    background: rgba(50, 52, 68, 1);
    color: white;
    padding: 0;
    border-radius: 12px;
  
    .contexify_item {
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
      padding: 6px 10px;
      &:last-child {
        border-bottom: none;
      }
    }
    .contexify_itemContent {
      color: ${colors.errorColor};
    }
    .contexify_item:not(.contexify_item-disabled):hover > .contexify_itemContent,
    .contexify_item:not(.contexify_item-disabled):focus > .contexify_itemContent {
      color: inherit;
      background-color: rgba(255, 255, 255, 0.1);
    }
  `;

const CustomItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${(props) => (props.redButton ? colors.errorColor : "white")};
  svg {
    height: 20px;
    width: 20px;
  }
`;
