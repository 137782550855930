import React, { useState } from 'react'
import styled from 'styled-components'
import SearchMenu from '../GenericComponents/SearchMenu'
import ChatListElement from './ChatListElement'
import { filterConversations } from '../../Helpers/filters/filterConversations'
import { Menu, useContextMenu } from 'react-contexify'
import { colors } from '../../Assets/styles/colors'
import ConversationListContextMenu from './ConversationListContextMenu'
import { useConversationService } from '../../Context/ConversationsContext'
import { notifyError } from '../../Helpers/Notifications'
import { localizationStrings } from '../../Localization/Localization'
import DeleteConfirmationChild from '../GenericComponents/DeleteConfirmationChild'


const ConversationsList = ({
    searchInput,
    setSearchInput,
    conversations,
    selectedConversation,
    handleSelectConversation,
    setLoading,
    loadConversations
}) => {

    const [selectedContextMenuItem, setSelectedContextMenuItem] = useState(null);
    const { hideConversation, deleteConversation } = useConversationService();
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)

    const MENU_ID = "conversationsList-id";

    const { show } = useContextMenu({
        id: MENU_ID,
    });

    function displayMenu(e, conversation) {
        // put whatever custom logic you need
        // you can even decide to not display the Menu
        setSelectedContextMenuItem(conversation);
        show({
            event: e,
        });
    }

    const handleDeleteConversation = (conversationId) => {
        setLoading(true);
        deleteConversation(
            conversationId,
            (response) => {
                if (selectedConversation?.id === conversationId) {
                    handleSelectConversation(null)
                }
                loadConversations();
                setLoading(false);
            },
            (error) => {
                console.log(error)
                notifyError(localizationStrings.profile.something_wrong);
                setLoading(false);
            },
        )
    }

    const handleHideConversation = (conversationId) => {
        setLoading(true);
        hideConversation(
            conversationId,
            (response) => {
                if (selectedConversation?.id === conversationId) {
                    handleSelectConversation(null)
                }
                loadConversations();
                setLoading(false);
            },
            (error) => {
                console.log(error)
                notifyError(localizationStrings.profile.something_wrong);
                setLoading(false);
            },
        )
    }

    return (
        <ChatsList>
            <DeleteConfirmationChild
                setOpen={setDeleteConfirmationOpen}
                open={deleteConfirmationOpen}
                action={handleDeleteConversation}
                item={selectedContextMenuItem?.id}
                variant={"delete_chat"}
            />

            <ConversationListContextMenu
                selectedContextMenuItem={selectedContextMenuItem}
                handleDeleteConversation={handleDeleteConversation}
                setDeleteConfirmationOpen={setDeleteConfirmationOpen}
                handleHideConversation={handleHideConversation}
            />

            <SearchMenu
                value={searchInput}
                setvalue={setSearchInput}
            />

            {filterConversations(conversations, searchInput)?.map((conversation, index) => (
                <ChatListElement
                    key={index}
                    data={conversation}
                    selected={selectedConversation?.id === conversation.id}
                    handleSelectConversation={handleSelectConversation}
                    displayMenu={displayMenu}
                />
            ))}

        </ChatsList>
    )
}

export default ConversationsList

const ChatsList = styled.div`
display:flex;
flex-direction:column;
gap:20px;
padding:0 20px 10px 20px;
overflow-Y:auto;
overflow-X:hidden;
`
