export const MESSAGES_LIST = "messages_list"
export const CALLS_LIST = "calls_list"


//MOOD CONSTANTS
export const ROMANTIC_MOOD = "romantic"
export const HUMOROUS_MOOD = "humorous"
export const CASUAL_MOOD = "casual"
export const NEUTRAL_MOOD = "neutral"
export const SUPPORTIVE_MOOD = "supportive"
export const BUSINESS_MOOD = "business"
export const FORMAL_MOOD = "formal"