import React from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as DropDownArrowIcon } from "../../Assets/icons/dropDownArrow.svg";
import { localizationStrings } from "../../Localization/Localization";
const SingleOptionMenu = ({
  value,
  setValue,
  placeholder,
  valuesList,
  slim,

}) => {

  return (
    <Container>
      <CustomSelect
        slim={slim}
        onChange={(e) => setValue(e.currentTarget.value)}
        defaultValue={value}
        placeholder={localizationStrings.profile.please_select_gender}
      >

        <option disabled selected={value === null}>
          {placeholder}
        </option>

        {valuesList?.map((item, index) => (
          <option key={index} value={item.value} >
            {item.text}
          </option>
        ))}
      </CustomSelect>

      <DropDownArrow>
        <DropDownArrowIcon />
      </DropDownArrow>
    </Container>
  );
};

export default SingleOptionMenu;
const Container = styled.div`
  position: relative;
  width: 100%;
`;
const CustomSelect = styled.select`
  background: ${colors.newChatModalInputBg};
  border: none;
  width: 100%;
  padding: ${(props) => (props.slim ? "" : "16px")};
  color: ${colors.white};
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
`;

const DropDownArrow = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  align-items: center;
  top: 0;
  right: 10px;

  svg {
    height: 7px;
    width: 14px;
  }
`;
