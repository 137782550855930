


import { PATCH, POST } from "../API";
let source = null;

export const updateGroupAPI = (data, onSuccess, onError) => {
    source = POST(`/api/v1/chat/groups/update`, data, onSuccess, onError, true, source);
};

export const removeAdminsAPICalls = (groupId, members, onSuccess, onError) => {
    const data = {
        id: groupId,
        members: members
    }
    source = POST(`/api/v1/chat/groups/remove_admins`, data, onSuccess, onError, true, source);
};

export const makeAdminsAPICalls = (groupId, members, onSuccess, onError) => {
    const data = {
        id: groupId,
        members: members
    }
    source = POST(`/api/v1/chat/groups/make_admins`, data, onSuccess, onError, true, source);
};
