import { DELETE, GET, POST } from "../API"

let source;
let getConversationIdSource;
export const getConversationsForUserApi = (onSuccess, onError) => {
    source = GET(`/api/v1/chat/conversations`, onSuccess, onError, true, source)
}

export const getConversationsForIdApi = (conversationId, onSuccess, onError) => {
    getConversationIdSource = GET(`/api/v1/chat/conversations/${conversationId}`, onSuccess, onError, true, getConversationIdSource)
}

export const hideConversationAPICall = (conversationId, onSuccess, onError) => {
    const data = undefined
    POST(`/api/v1/chat/conversations/${conversationId}/hide_conversation`, data, onSuccess, onError, true, source)
}

export const deleteConversationAPICall = (conversationId, onSuccess, onError) => {
    DELETE(`/api/v1/chat/conversations/${conversationId}/delete_conversation`, onSuccess, onError, true, source)
}

