
export const RO = {
    "language_name": "Romana",
    "language_code": "RO",
    "login": {
        "login_with_phone": "Conectați-vă cu numărul dvs. de telefon",
        "login_agree": "Prin autentificare, sunteți de acord cu",
        "terms_and_conditions": "Termeni și condiții",
        "enter_code": "Introduceți codul pe care tocmai l-am trimis",
        "wrong_validation_code": "Ne pare rău, codul de validare este incorect. Vă rugăm să încercați cu un alt cod.",
        "code_not_received": "Nu ați primit niciun cod?",
        "resend_code": "Retrimiteți codul",
        "app_language": "Selectați limba aplicației",
        "verify_code": "Verificați codul",
        "continue": "Continua"
    },
    "register": {
        "next_button": "Următorul",
        "your_name": "Numele tău",
        "name_details": "Spune-ne cum ar trebui să te cheme alte persoane de pe platformă",
        "name_placeholder": "Tastați numele dvs...",
        "chat_language": "Limba conversației",
        "language_details": "Veți primi toate mesajele în limba aleasă.",
        "birthdate": "Data nașterii",
        "birthdate_details": "Vom folosi data nașterii dvs. pentru a face traducerile mesajelor mai bine adaptate pentru dvs.",
        "dont_want_to_share": "Nu doriți să partajați",
        "gender": "Gen",
        "gender_details": "Vom lua în considerare genul dvs. pentru a personaliza mai bine comunicarea.",
        "cultural_background": "Context cultural",
        "cultural_background_details": "Folosim informațiile despre contextul cultural pentru a ajusta comunicarea.",
    },
    "profile": {
        "my_profile": "Profilul meu",
        "back": "Înapoi",
        "personal_details": "DETALII PERSONALE",
        "chat_settings": "SETĂRI CHAT",
        "account_details": "DETALII CONT",
        "others": "ALTELE",
        "language": "Limba",
        "birthday": "Data nașterii",
        "gender": "Genul",
        "cultural_background": "Contextul cultural",
        "phone_number": "Număr de telefon",
        "logout": "Deconectare",
        "delete_account": "Ștergeți contul",
        "term_and_conditions": "Termeni și condiții",
        "privacy": "Politica de confidențialitate",
        "support": "Suport",
        "please_select_language": "Vă rugăm să selectați limba",
        "please_select_gender": "Vă rugăm să selectați genul",
        "type_cultural_background": "Introduceți contextul cultural",
        "select_birthdate": "Selectați data nașterii",
        "type_your_status": "Tastați starea dvs.",
        "not_connected": "Nu sunteți conectat la internet, vă rugăm să verificați conexiunea!"
    },
    "chat": {
        "was_invited": "a fost invitat",
        "invited_to_conversation": "Acest număr de telefon nu are un cont emotii, i-am trimis un mesaj SMS pentru a se alătura platformei. Odată ce își vor crea un cont, puteți începe să conversați.",
        "speak_in_your_language": "Vorbiți în limba dvs., noi vom avea grijă de restul!",
        "new": "Nou",
        "original_message": "Mesajul original",
        "preffered_language": "Limba preferată",
        "image": "Imagine",
        "search": "Căutare",
        "message_deleted": "Mesajul a fost șters",
        "type_your_message": "Tastați mesajul dvs.",
        "send_image": "Trimiteți imaginea",
        "send_to": "Trimiteți către",
        "delete": "Șterge",
        "close": "Închide",
        "rating_modal_message": "Cât de bune sunt traducerile în această conversație?",
        "remove_member": "Sunteți sigur că doriți să eliminați acest membru?"
    },
    "new_chat": {
        "new_chat": "Conversație nouă",
        "new_group": "Grup nou",
        "group": "Grup",
        "chat": "Conversație",
        "start_chat": "Începeți conversația",
        "name": "NUME",
        "first_name": "Prenume",
        "last_name": "Nume de familie",
        "search_member": "Căutați membru",
        "add_member": "Adăugați membru",
        "found_contact": "Contact găsit",
        "members": "Membri",
        "enter_group_name": "Introduceți numele grupului",
        "contact_not_emotii_user": "Contactul nu este utilizator Emotii",
        "create": "Creați"
    },
    "chat_details": {
        "group_details": "Detalii grup",
        "user_details": "Detalii utilizator",
        "account_details": "Detalii cont",
        "save_changes": "Salvați modificările",
        "details": "Detalii",
        "exit_group": "Sunteți sigur că doriți să părăsiți acest grup?",
        "yes": "Da",
        "no": "Nu"
    }
}