import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";
import { ReactComponent as RemoveIcon } from "../../Assets/icons/deleteIcon.svg";
import { localizationStrings } from "../../Localization/Localization";



const DeleteConfirmation = ({ handleRemoveItem, item, variant, disableButton }) => {
    const [open, setOpen] = React.useState(false);
    const ME = 'me';


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemove = () => {
        handleRemoveItem(item);
        setOpen(false);
    };

    const DisplayVariant = ({ variant }) => {
        let displayItem = (
            <DeleteButton onClick={() => handleClickOpen()} disabled={disableButton}>
                <RemoveIcon />
            </DeleteButton>
        )



        return (
            displayItem
        )
    }

    const DisplayDialogVariant = ({ variant }) => {

        let displayDialog = (
            <DialogContentText id="alert-dialog-description">
                {localizationStrings.chat.remove_member}
            </DialogContentText>)

        if (variant == ME) {
            displayDialog = (<DialogContentText id="alert-dialog-description">
                {localizationStrings.chat_details.exit_group}
            </DialogContentText>)
        }
        return displayDialog
    }

    return (
        (<div>
            <DisplayVariant variant={variant} />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
                <DialogContent>
                    <DisplayDialogVariant variant={variant} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>  {localizationStrings.chat_details.no}</Button>
                    <Button style={{ color: "red" }} onClick={handleRemove} autoFocus>
                        {localizationStrings.chat_details.yes}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>)


    );
};

export default DeleteConfirmation;

const DeleteButton = styled.button`
background:none;
border:none;
display:flex;
align-items:center;
cursor:pointer;
svg{
    height:16px;
}
  :disabled{
    cursor:inherit;
    opacity:0.5;
  }
`;

