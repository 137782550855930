import {
    createContext,
    useContext,
    useCallback,
} from "react";
import { deleteConversationAPICall, getConversationsForIdApi, getConversationsForUserApi, hideConversationAPICall } from "../API/conversations/getConversationsForUser";
import { addMessageForConversationApi } from "../API/conversations/addMessageForConversation";
import { deleteMessageForConversationAPi } from "../API/conversations/deleteMessageForConversation";
import { getMessageForConversationApi } from "../API/conversations/getMessageForConversation";
import { conversationWithPhoneNumberApi } from "../API/conversations/conversationWithPhoneNumber";
import { canStartConversationApi } from "../API/contacts/canStartConversation";
import { inviteContactApi } from "../API/contacts/inviteContact";
import { markMessageAsReadApi } from "../API/conversations/markMessageAsRead";
import { reactToMessageForConversationApi } from "../API/conversations/reacttoMessageForConversation";
import { createGroupAPI } from "../API/groups/createGroup";
import { addGroupMembersAPI } from "../API/groups/addMembers";
import { removeGroupMemberAPI } from "../API/groups/removeMember";
import { setGroupMembersAPI } from "../API/groups/setGroupMembers";
import { makeAdminsAPICalls, removeAdminsAPICalls, updateGroupAPI } from "../API/groups/updateGroup";
import { contactsOnPlatformAPI } from "../API/contacts/contactOnPlatform";
import { addRatingAPI } from "../API/conversations/addRating";
import { getContactsAPI } from "../API/contacts/getContacts";
import { addContactAPI } from "../API/contacts/addContact";
import { editMessageForConversationApi } from "../API/conversations/editMessageForConversation";
import { forwardMessageApiCall } from "../API/conversations/forwardMessage";
import { replyMessageAPICall } from "../API/conversations/replyMessage";
import { CloseQuickChatAPICall, createQuickChatApi } from "../API/quickChatAuth/quickChatAuthService";
import { blockContactAPICall, blockUnknowContactsAPICall, getBlockedContactsAPICall, unblockContactAPICall } from "../API/contacts/blockUnblock";


export const ConversationContext = createContext({
    messagesHandler: () => { },
    setConversations: () => { },
    getConversationsForUser: () => { },
    getMessageForConversation: () => { },
    addMessageForConversation: () => { },
    editMessageForConversation: () => { },
    deleteMessageForConversation: () => { },
    conversationsWithPhone: () => { },
    canStartConversation: () => { },
    inviteContact: () => { },
    markMessageAsRead: () => { },
    reactToMessageForConversation: () => { },
    createGroup: () => { },
    addGroupMember: () => { },
    removeGroupMember: () => { },
    setGroupMembers: () => { },
    updateGroup: () => { },
    searchContactsOnPlatform: () => { },
    add_rating: () => { },
    getContacts: () => { },
    addContact: () => { },
    forwardMessage: () => { },
    replyMessage: () => { },
    createQuickChat: () => { },
    closeQuickChat: () => { },
    blockContact: () => { },
    unblockContact: () => { },
    blockUnknownContacts: () => { },
    getBlockedContacts: () => { },
    removeGroupAdmin: () => { },
    makeGroupAdmin: () => { },
    hideConversation: () => { },
    deleteConversation: () => { },
    getConversationsForId: () => { },

});

const ConversationContextProvider = ({ children }) => {

    const getConversationsForUser = useCallback(
        async (onSuccess, errorCallback) => {
            try {
                await getConversationsForUserApi(
                    function (response) {
                        const conversations = response.data.conversations;
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const getConversationsForId = useCallback(
        async (conversationId, onSuccess, errorCallback) => {
            try {
                await getConversationsForIdApi(
                    conversationId,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const getMessageForConversation = useCallback(
        async (conversationId, page, onSuccess, errorCallback) => {
            try {
                await getMessageForConversationApi(
                    conversationId,
                    page,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const addMessageForConversation = useCallback(
        async (data, conversationId, onSuccess, errorCallback) => {
            try {
                await addMessageForConversationApi(
                    data,
                    conversationId,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const editMessageForConversation = useCallback(
        async (conversationId, messageId, original_message, onSuccess, errorCallback) => {
            try {
                await editMessageForConversationApi(
                    conversationId, messageId, original_message,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const forwardMessage = useCallback(
        async (conversation_ids, phone_numbers, device_sent_date, messageId, onSuccess, errorCallback) => {
            try {
                await forwardMessageApiCall(
                    conversation_ids, phone_numbers, device_sent_date, messageId,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const replyMessage = useCallback(
        async (data, conversationId, messageId, onSuccess, errorCallback) => {
            try {
                await replyMessageAPICall(
                    data,
                    conversationId,
                    messageId,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const conversationsWithPhone = useCallback(
        async (phoneNumber, onSuccess, errorCallback) => {
            try {
                await conversationWithPhoneNumberApi(
                    phoneNumber,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const canStartConversation = useCallback(
        async (phoneNumber, onSuccess, errorCallback) => {
            try {
                await canStartConversationApi(
                    phoneNumber,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const inviteContact = useCallback(
        async (phoneNumber, onSuccess, errorCallback) => {
            try {
                await inviteContactApi(
                    phoneNumber,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const deleteMessageForConversation = useCallback(
        async (conversationId, messageId, onSuccess, errorCallback) => {
            try {
                await deleteMessageForConversationAPi(
                    conversationId,
                    messageId,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const markMessageAsRead = useCallback(
        async (conversationId, messageId, onSuccess, errorCallback) => {
            try {
                await markMessageAsReadApi(
                    conversationId,
                    messageId,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const reactToMessageForConversation = useCallback(
        async (reaction, conversationId, messageId, onSuccess, errorCallback) => {
            try {
                await reactToMessageForConversationApi(
                    reaction,
                    conversationId,
                    messageId,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const createGroup = useCallback(
        async (data, onSuccess, errorCallback) => {
            try {
                await createGroupAPI(
                    data,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const updateGroup = useCallback(
        async (data, onSuccess, errorCallback) => {
            try {
                await updateGroupAPI(
                    data,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const addGroupMember = useCallback(
        async (id, members, onSuccess, errorCallback) => {
            try {
                await addGroupMembersAPI(
                    id,
                    members,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const removeGroupMember = useCallback(
        async (id, members, onSuccess, errorCallback) => {
            try {
                await removeGroupMemberAPI(
                    id,
                    members,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const setGroupMembers = useCallback(
        async (id, members, onSuccess, errorCallback) => {
            try {
                await setGroupMembersAPI(
                    id,
                    members,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const searchContactsOnPlatform = useCallback(
        async (phone_numbers, onSuccess, errorCallback) => {
            try {
                await contactsOnPlatformAPI(
                    phone_numbers,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const add_rating = useCallback(
        async (conversation_id, rating, onSuccess, errorCallback) => {
            try {
                await addRatingAPI(
                    conversation_id,
                    rating,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );


    const getContacts = useCallback(
        async (onSuccess, errorCallback) => {
            try {
                await getContactsAPI(
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const addContact = useCallback(
        async (name, phone, onSuccess, errorCallback) => {
            try {
                await addContactAPI(
                    name,
                    phone,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const createQuickChat = useCallback(
        async (name, onSuccess, errorCallback) => {
            try {
                await createQuickChatApi(
                    name,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const closeQuickChat = useCallback(
        async (conversationId, onSuccess, errorCallback) => {
            try {
                await CloseQuickChatAPICall(
                    conversationId,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const blockContact = useCallback(
        async (phoneNumber, name, onSuccess, errorCallback) => {
            try {
                await blockContactAPICall(
                    phoneNumber, name,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const unblockContact = useCallback(
        async (phoneNumber, onSuccess, errorCallback) => {
            try {
                await unblockContactAPICall(
                    phoneNumber,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );
    const blockUnknownContacts = useCallback(
        async (block, onSuccess, errorCallback) => {
            try {
                await blockUnknowContactsAPICall(
                    block,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const getBlockedContacts = useCallback(
        async (onSuccess, errorCallback) => {
            try {
                await getBlockedContactsAPICall(

                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );
    const removeGroupAdmin = useCallback(
        async (groupId, members, onSuccess, errorCallback) => {
            try {
                await removeAdminsAPICalls(
                    groupId,
                    members,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const makeGroupAdmin = useCallback(
        async (groupId, members, onSuccess, errorCallback) => {
            try {
                await makeAdminsAPICalls(
                    groupId,
                    members,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const hideConversation = useCallback(
        async (conversationID, onSuccess, errorCallback) => {
            try {
                await hideConversationAPICall(
                    conversationID,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );
    const deleteConversation = useCallback(
        async (conversationID, onSuccess, errorCallback) => {
            try {
                await deleteConversationAPICall(
                    conversationID,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    return (
        <ConversationContext.Provider
            value={{
                getConversationsForUser,
                getMessageForConversation,
                addMessageForConversation,
                editMessageForConversation,
                deleteMessageForConversation,
                conversationsWithPhone,
                canStartConversation,
                inviteContact,
                markMessageAsRead,
                reactToMessageForConversation,
                createGroup,
                addGroupMember,
                removeGroupMember,
                setGroupMembers,
                updateGroup,
                searchContactsOnPlatform,
                add_rating,
                getContacts,
                addContact,
                forwardMessage,
                replyMessage,
                createQuickChat,
                closeQuickChat,
                getBlockedContacts,
                blockUnknownContacts,
                unblockContact,
                blockContact,
                removeGroupAdmin,
                makeGroupAdmin,
                hideConversation,
                deleteConversation,
                getConversationsForId


            }}
        >
            {children}
        </ConversationContext.Provider>
    );
};

export const useConversationService = () => useContext(ConversationContext);

export default ConversationContextProvider;
