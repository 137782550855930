import React from 'react'
import styled from 'styled-components'
import { ReactComponent as RemoveIcon } from "../../Assets/icons/deleteIcon.svg";
import { colors } from '../../Assets/styles/colors';
import { ReactComponent as AvatarUnset } from '../../Assets/icons/unsetAvatar.svg'
import DeleteConfirmation from '../GenericComponents/DeleteConfirmations';

const ContactElement = ({ item, handleRemove, withConfirmationDelete, variant, deliveredInfo, style, noAction }) => {
  return (
    <Contact style={style}>
      <ContactAvatar>
        {item.profile_picture ? <img src={item.profile_picture} alt='avatar' /> : <AvatarUnset />}
      </ContactAvatar>
      <ContactNameWrapper>
        <ContactName>{item.name}</ContactName>
        {deliveredInfo &&
          <DeliveredInfo>
            {deliveredInfo}
          </DeliveredInfo>
        }

        {!noAction && (
          withConfirmationDelete ?
            <ActionWrapper>
              <DeleteConfirmation
                item={item}
                handleRemoveItem={handleRemove}
                variant={variant}
              />
            </ActionWrapper>
            :
            <ActionWrapper>
              <RemoveButton onClick={() => handleRemove(item)}>
                <RemoveIcon />
              </RemoveButton>
            </ActionWrapper>
        )
        }


      </ContactNameWrapper>
    </Contact>
  )
}

export default ContactElement


const Contact = styled.div`
display:flex;
align-items:center;
gap:10px;
overflow:hidden;

`
const ContactNameWrapper = styled.div`
display:flex;
align-items:center;
border-bottom:1px solid rgba(54, 62, 81, 0.5);
width:100%;
overflow:hidden;
gap:10px;
`
const ContactName = styled.p`
text-overflow:ellipsis;
overflow:hidden;
white-space:pre;
font-size: 16px;
font-weight: 400;
line-height: 35px;
text-align: left;
color:${colors.white};
`
const RemoveButton = styled.button`
background:none;
border:none;
display:flex;
align-items:center;
cursor:pointer;
svg{
    height:24px;
}
`
const ContactAvatar = styled.div`
  display: flex;
  height:35px;
  width:35px;
  max-width: 35px;
  min-height: 35px;
  min-width: 35px;
  max-height: 35px;
  overflow: hidden;
  border-radius: 100%;
//   height: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  svg{
     object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
const DeliveredInfo = styled.p`
   margin-left:auto;
    color: ${colors.chatElementColor};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

white-space:pre;
`
const ActionWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
margin-right:16px;
margin-left:auto;
`