import { Route, Routes, useNavigate } from "react-router";
import NotFound from "./Pages/NotFound";
import ChatPage from "./Pages/ChatPage";
import PrivateRoute from "./Router/PrivateRoute";

import { Toaster } from "react-hot-toast";
import LoginPage from "./Pages/LoginPage";
import SpinnerComponent from "./Components/GenericComponents/SpinnerComponent";
import { useEffect, useState } from "react";
import ConversationContextProvider from "./Context/ConversationsContext";
import LocalStorage from "./Helpers/LocalStorage";
import { localizationStrings } from "./Localization/Localization";
import WebSocketServiceProvider from "./Context/WebsocketContext";
import OfflineView from "./Components/GenericComponents/OfflineView";
import { useOnlineStatus } from "./Helpers/useOnlineStatus";
import InitializeAnalytics from "./Components/Analytics/InitializeAnalytics";
import ReactGA from "react-ga4";
import QuickChatPage from "./Pages/QuickChatPage";
import QuickChatContextProvider from "./Context/QuickChatContext";
import CallsContextProvider from "./Context/CallsContext";
import QcWebSocketServiceProvider from "./Context/QuickChatWebsocketContext";
import ElectronLoginPage from "./electron/ElectronLogin";
import { useAuthService } from "./Context/AuthContext";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import { createSearchParams, useSearchParams } from "react-router-dom";
import DesktopLoginSuccess from "./Pages/DesktopLoginSuccess";

function App() {
  const { getCurrentUser, singOut, user, getCustomToken } = useAuthService();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParasm] = useSearchParams();
  const isOnline = useOnlineStatus();
  const branch = require("branch-sdk");
  const isElectron = !!window.electron;
  const navigate = useNavigate();

  const verifyAppLanguage = () => {
    const language = LocalStorage.getAppLanguage();
    if (language) {
      localizationStrings.setLanguage(language);
    }
  };

  const authObserver = () => {
    onAuthStateChanged(auth, (authUser) => {
      if (authUser) {

        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = authUser.uid;
        LocalStorage.setAuthenthicationToken(authUser.accessToken);
        LocalStorage.setRefreshToken(authUser.stsTokenManager.refreshToken);
        if (searchParams.get("desktop_login") == "true") {
          getCustomToken(
            (response) => {
              setSearchParasm((prev) => {
                prev.set("access_token", response.data.authentication_token)
              })
              navigate({
                pathname: "/desktop_login_success",
                search: createSearchParams(searchParams).toString()
              })
            },
            (error) => {
            }
          )
        }
        else {
          if (!user) {
            getCurrentUser(
              function () {
                navigate("/")
              },
              function () { }
            );
          }
        }
      }
      else {

        singOut();
      }
    });
  };

  useEffect(() => {
    // TODO: ENABLE WHEN ENABLE APP LANGUAGE SELECTION
    // verifyAppLanguage();
    ReactGA.set({ user_id: null });
    branch.init(process.env.REACT_APP_BRANCH_KEY);
    //Firebase Messaging request
    authObserver();
  }, []);

  return (
    <>
      {!isOnline && <OfflineView />}
      <div id="recaptcha-container"></div>
      <SpinnerComponent isLoading={loading}>
        <Toaster />
        <InitializeAnalytics />
        <Routes>
          <Route path="*" element={<NotFound />}></Route>
          {/* Conditionally render the login route */}
          <Route
            path="/login"
            element={isElectron ? <ElectronLoginPage /> : <LoginPage />}
          />
          <Route path="/desktop_login_success" element={<DesktopLoginSuccess />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <ConversationContextProvider>
                  <CallsContextProvider>
                    <WebSocketServiceProvider>
                      <ChatPage setLoading={setLoading} />
                    </WebSocketServiceProvider>
                  </CallsContextProvider>
                </ConversationContextProvider>
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/quick-chat/:hashId"
            element={
              <QuickChatContextProvider>
                <ConversationContextProvider>
                  <QcWebSocketServiceProvider>
                    <QuickChatPage setLoading={setLoading} />
                  </QcWebSocketServiceProvider>
                </ConversationContextProvider>
              </QuickChatContextProvider>
            }
          ></Route>
        </Routes>
      </SpinnerComponent>
    </>
  );
}

export default App;
