
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { localizationStrings } from '../../Localization/Localization'
import { useAuthService } from '../../Context/AuthContext'
import moment from 'moment'
import MessageInfoContactElement from './MessageInfoContactElement'
import { useConversationService } from '../../Context/ConversationsContext'
import { notifyError } from '../../Helpers/Notifications'

const MessageDetailsModal = ({ selectedMessage, selectedConversation }) => {

  const [currentConversation, setCurrentConversation] = useState(selectedConversation)
  const { user } = useAuthService();
  const { getConversationsForId } = useConversationService();

  const currentMessage = selectedMessage.messageObject

  var messageInfo = currentMessage.message_translations.filter((item) => item.user_id != user.id);

  var otherUsers = currentConversation.other_participants.filter((item) => item.id != user.id)
  var readMessageList = messageInfo.filter((item) => item.read_at != null);
  var readUsersId = readMessageList.map((item) => item.user_id)
  var readUsers = otherUsers.filter((item) => readUsersId.includes(item.id))
  var deliveredUsers = otherUsers.filter((item) => !readUsersId.includes(item.id))

  const getDeliveredInfo = (userId) => {
    let messageForCurrentUser = messageInfo.find((item) => item.user_id === userId);
    if (messageForCurrentUser) {
      return (
        `${moment(messageForCurrentUser.created_at).format(`DD.MM.yyyy`)} at ${moment(currentMessage.created_at).format(`hh:mm`)}`
      )
    }
  }

  const getReadInfo = (userId) => {
    let readMessage = readMessageList.find((item) => item.user_id === userId)
    if (readMessage.read_at) {
      return `${moment(readMessage.read_at).format("DD.MM.yyyy")} at ${moment(readMessage.read_at).format("hh:mm")} `
    } else {
      return "-"
    }
  }

  const handleGetConversationForId = (id) => {
    getConversationsForId(
      id,
      (response) => {
        const conversation = response.data.conversation
        setCurrentConversation(conversation)
      },
      (error) => {
        notifyError(localizationStrings.profile.something_wrong)
        console.log(error)
      }
    )
  }

  useEffect(() => {
    handleGetConversationForId(selectedConversation.id)
  }, [selectedMessage])

  return (
    <Container>
      {(deliveredUsers.length > 0) &&
        <Group>
          <TitleWrapper>
            <h1>{localizationStrings.chat_details.delivered_to}</h1>
          </TitleWrapper>
          <OptionWrapper>
            <MebersList>
              {deliveredUsers.map((user, index) => (
                <MessageInfoContactElement
                  item={user}
                  deliveredInfo={getDeliveredInfo(user.id)}
                  currentMessage={currentMessage}
                />
              ))}
            </MebersList>
          </OptionWrapper>
        </Group>
      }
      {readUsers.length > 0 &&
        <Group>
          <TitleWrapper>
            <h1>{localizationStrings.chat_details.read_by}</h1>
          </TitleWrapper>
          <OptionWrapper>
            <MebersList>
              {readUsers.map((user, index) => (
                <MessageInfoContactElement
                  item={user}
                  read
                  readInfo={getReadInfo(user.id)}
                  deliveredInfo={getDeliveredInfo(user.id)}
                  currentMessage={currentMessage}
                />
              ))}
            </MebersList>
          </OptionWrapper>
        </Group>
      }
    </Container>
  )
}

export default MessageDetailsModal

const Container = styled.div`
  background: ${colors.newChatModalBg};
  display: flex;
  flex-direction: column;
  padding: 24px 21px;
  gap: 25px;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: auto;
  display:flex;
  flex-direction:column;
`
const ChatOption = styled.div`
  display: flex;
  align-items:center;
  width: 100%;
  gap: 10px;
  padding: 13px 0;
  border-bottom: 1px solid #363e5180;
  &:last-child {
    border: none;
  }
  h1 {
    min-width: 75px;
    color:${colors.white};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
  }
  p {
  margin-left:auto;
    color: ${colors.chatElementColor};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
`;

const OptionLabel = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    color: ${colors.chatElementColor};
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
`
const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    color: ${colors.optionsGroupTitle};
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
  }
`;
const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
`;

const MebersList = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
  gap: 10px;
`;