import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors';
import { ReactComponent as AvatarUnset } from '../../Assets/icons/unsetAvatar.svg'
import { ReactComponent as MenuDots } from '../../Assets/icons/context-menu-icons/optionMenuDots.svg'
import { ReactComponent as AdminBadge } from '../../Assets/icons/adminBadge.svg'


const ContactElementChatDetails = ({ item, deliveredInfo, style, displayMenu, isCurrentUserAdmin, isUserAdmin, variant }) => {


    return (
        <Contact style={style}>
            <AvatarWrapper>
                <ContactAvatar>
                    {item.profile_picture ? <img src={item.profile_picture} alt='avatar' /> : <AvatarUnset />}
                </ContactAvatar>
                {isUserAdmin &&
                    <BadgeWrapper>
                        <AdminBadge />
                    </BadgeWrapper>

                }
            </AvatarWrapper>

            <ContactNameWrapper>
                <ContactName>{item.name}</ContactName>
                {deliveredInfo &&
                    <DeliveredInfo>
                        {deliveredInfo}
                    </DeliveredInfo>
                }
                {isCurrentUserAdmin &&
                    <OptionMenuWrapper >
                        <MenuDots onClick={(e) => displayMenu(e, item)} />
                    </OptionMenuWrapper>
                }
            </ContactNameWrapper>

        </Contact >
    )
}

export default ContactElementChatDetails


const Contact = styled.div`
display:flex;
align-items:center;
gap:10px;
overflow:hidden;

`
const ContactNameWrapper = styled.div`
display:flex;
align-items:center;
border-bottom:1px solid rgba(54, 62, 81, 0.5);
width:100%;
overflow:hidden;
gap:10px;
`
const ContactName = styled.p`
text-overflow:ellipsis;
overflow:hidden;
white-space:pre;
font-size: 16px;
font-weight: 400;
line-height: 35px;
text-align: left;
color:${colors.white};
`
const RemoveButton = styled.button`
background:none;
border:none;
display:flex;
align-items:center;
cursor:pointer;
svg{
    height:24px;
}
`
const AvatarWrapper = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
position:relative;

`
const ContactAvatar = styled.div`
  display: flex;
  height:35px;
  width:35px;
  max-width: 35px;
  min-height: 35px;
  min-width: 35px;
  max-height: 35px;
  overflow: hidden;
  border-radius: 100%;
//   height: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  svg{
     object-fit: cover;
    width: 100%;
    height: 100%;
  }

`;
const DeliveredInfo = styled.p`
   margin-left:auto;
    color: ${colors.c};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

white-space:pre;
`

const OptionMenuWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
color:${colors.optionsGroupTitle};
margin-left:auto;
cursor:pointer;
svg{
max-height:30px;
max-width:20px;
}
`
const BadgeWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
position:absolute;
bottom:-1px;
right:-3px;
z-index:1;
svg{
height:12px;
}
`