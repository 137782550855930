import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { colors } from '../../Assets/styles/colors';
import PhoneInput from 'react-phone-number-input';
import { localizationStrings } from '../../Localization/Localization';
import { useConversationService } from '../../Context/ConversationsContext';
import { notifyError, notifySuccess } from '../../Helpers/Notifications';
import { ReactComponent as BackIcon } from "../../Assets/icons/arrowBack.svg";


const AddNewChatContact = ({ user, setIsAddNewContactOpen, setLoading, loadContacts }) => {
  const { addContact } = useConversationService()
  const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    groupName: ""
  }

  const [phone, setPhone] = useState(initialValues.phone);
  const [inputValues, setInputValues] = useState(initialValues);
  const [addBtnDisabled, setAddBtnDisabled] = useState(true);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleAddNewContact = () => {
    setLoading(true)
    let fullName = inputValues.firstName + " " + inputValues.lastName
    addContact(
      fullName,
      phone,
      function (response) {
        notifySuccess("Contact added");
        loadContacts();
        setIsAddNewContactOpen(false);
        setLoading(false)
      },
      function (error) {
        notifyError("Something wrong...")
        console.log(error)
        setLoading(false)
      }
    )
  }

  const checkDisabled = () => {
    if (phone?.length < 7) {
      setAddBtnDisabled(true)
    } else {
      setAddBtnDisabled(false)
    }
  }

  useEffect(() => {
    checkDisabled()
  }, [phone])

  return (
    <AddNewGroupContainer>
      <Container>
        <BackButton onClick={() => setIsAddNewContactOpen(false)}>
          <BackIcon />
          {localizationStrings.profile.back}
        </BackButton>
        <ProfileTitle>
          <h1>{localizationStrings.new_chat.new_contact}</h1>
        </ProfileTitle>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "end" }}
        ></div>

      </Container>

      <CardInputsWrapper>
        <Group>
          <TitleWrapper>
            <h1>{localizationStrings.new_chat.name}</h1>
          </TitleWrapper>

          <CustomInput
            name="firstName"
            id="firstName-input"
            placeholder={localizationStrings.new_chat.first_name}
            value={inputValues.firstName}
            onChange={(e) => inputChangeHandler(e)}
            maxLength={100}
          />

          <CustomInput
            name="lastName"
            id="lastName-input"
            placeholder={localizationStrings.new_chat.last_name}
            value={inputValues.lastName}
            onChange={(e) => inputChangeHandler(e)}
            maxLength={100}
          />
        </Group>

        <Group>
          <TitleWrapper>
            <h1>{localizationStrings.profile.phone_number}</h1>
          </TitleWrapper>
          <CustomPhoneWrapper>
            <PhoneInput
              showDropdown={false}
              international
              defaultCountry={"RO"}
              name="phoneNumber"
              placeholder={localizationStrings.profile.phone_number}
              value={phone}
              onChange={(e) => setPhone(e)}
              buttonClass="inputClass"
              dropdownClass="inputClass"
              containerClass="inputClass"
              limitMaxLength
            />
          </CustomPhoneWrapper>
        </Group>
        <Group>
          <AddNewContactButton disabled={addBtnDisabled} onClick={() => handleAddNewContact()}>
            {localizationStrings.new_chat.add_contact}
          </AddNewContactButton>

        </Group>

      </CardInputsWrapper>
    </AddNewGroupContainer>
  )
}

export default AddNewChatContact

const AddNewGroupContainer = styled.div`
  background: ${colors.newChatModalBg};
  display: flex;
  flex-direction: column;
  padding: 24px 21px;
  gap: 25px;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const BackButton = styled.button`
  width: 100%;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  gap: 10px;
  display: flex;
  align-items: center;
`;


const ProfileTitle = styled.div`
width:100%;
  justify-content: center;
  display: flex;
  min-width:140px;
  h1 {
    color: ${colors.white};
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
`;


const CardInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;


`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.newChatModalBg};
  min-height: 38px;
`;


const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CustomInput = styled.input`
  background: ${colors.newChatModalInputBg};
padding: 10px 16px;
border-radius: 10px;
width: 100%;
  font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color:${colors.white};
    width:100%;
    border:none;
    &:focus{
        outline:none;
    }
&::placeholder{
font-weight:500;

}
`;

const CustomPhoneWrapper = styled.div`
background: ${colors.newChatModalInputBg};
padding:0 0 0 16px;
border-radius:10px;
display:flex;
align-items:center;
input{
  padding: 14px 16px;
  color: ${colors.white};
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
  &:focus {
    outline: none;
  }
}
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    color: ${colors.optionsGroupTitle};
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
  }
`;
const ConfirmButton = styled(BackButton)`
 color: ${colors.purple};
 margin-left:auto;
`
const AddNewContactButton = styled.button`

display: flex;
align-items: center;
justify-content: center;
gap: 3px;
background: ${colors.purple};
color: ${colors.white};
font-size: 16px;
font-weight: 600;
line-height: 22px;
text-align: center;
border: none;
border-radius: 10px;
padding: 14px 15px;
cursor: pointer;
&:disabled{
opacity:0.5;
}
`