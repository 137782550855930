import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "../../Assets/styles/customPhoneSelect.css";
import "react-phone-number-input/style.css";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as BackIcon } from "../../Assets/icons/arrowBack.svg";
import { ReactComponent as AvatarUnset } from '../../Assets/icons/unsetAvatar.svg'
import { ReactComponent as AvatarPencil } from "../../Assets/icons/avatarPencil.svg";
import { localizationStrings } from "../../Localization/Localization";
import ContactElement from "../Chat/ContactElement";
import { useConversationService } from "../../Context/ConversationsContext";
import { notifyError } from "../../Helpers/Notifications";
import cloneDeep from "clone-deep";
import { ReactComponent as PlusIcon } from "../../Assets/icons/PlusIcon.svg";
import { ADD_MEMBERS } from "./AddNewGroup";

const SelectedMembers = ({
  setIsAddNewChatGroupOpen,
  conversations,
  setConversations,
  currentConversationRef,
  handleSelectConversation,
  setOption,
  selectedContacts,
  setSelectedContacs,
  handleInputChange,
  inputValues,
  avatar,
  handleAvatarChange,
  previewAvatar,
  setInitialContacts,
  initialContacts,
}) => {

  const { createGroup } = useConversationService();

  const profileAvatarRef = useRef();

  const handleOpenAvatar = () => {
    profileAvatarRef.current.click();
  };


  const handleRemoveContact = (contact) => {
    let filtered = selectedContacts.filter((item) => item.id !== contact.id);
    setSelectedContacs(filtered)
    setInitialContacts(filtered)
  }

  const handleCreateNewGroup = () => {
    let membersId = selectedContacts.map((item) => { return item.id })
    let clone = cloneDeep(conversations)
    let dataObj = new FormData();

    dataObj.append("name", inputValues.groupName)
    dataObj.append("participants_ids", `[${membersId}]`)

    if (avatar) {
      dataObj.append("image", avatar)
    }

    createGroup(
      dataObj,
      function (response) {
        setIsAddNewChatGroupOpen(false);
        let newConvList = [response.data.conversation].concat(clone)
        currentConversationRef.current = clone;
        setConversations(newConvList);
        handleSelectConversation(response.data.conversation)
      },
      function (error) {
        notifyError(`${localizationStrings.profile.something_wrong}`)
        console.log(error)
      }
    )
  }


  return (
    <Wrapper>

      <Container>
        <BackButton onClick={() => setIsAddNewChatGroupOpen(false)}>
          <BackIcon />
          {localizationStrings.profile.back}
        </BackButton>
        <ProfileTitle>
          <h1>{localizationStrings.new_chat.new_group}</h1>
        </ProfileTitle>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "end" }}
        ></div>
      </Container>

      <Group>
        <TitleWrapper>
          <h1>{localizationStrings.new_chat.name}</h1>
        </TitleWrapper>
        <OptionWrapper>
          <ProfileDetails>
            <UserWrapper>
              <input
                type="file"
                hidden
                accept=".png, .jpg, .jpeg "
                ref={profileAvatarRef}
                onChange={(e) => handleAvatarChange(e)}
              />
              <UserAvatarWrapper>
                <UserAvatar onClick={() => handleOpenAvatar()}>
                  {avatar ? <img src={previewAvatar} alt='avatar' /> : <AvatarUnset />}
                </UserAvatar>
                <PencilWrapper>
                  <AvatarPencil />
                </PencilWrapper>
              </UserAvatarWrapper>
              <CustomNameInput
                value={inputValues.groupName}
                onChange={(e) => handleInputChange(e)}
                placeholder={localizationStrings.new_chat.enter_group_name}
                name="groupName"
                maxLength={100}
              ></CustomNameInput>
            </UserWrapper>
          </ProfileDetails>
        </OptionWrapper>
      </Group>

      <Group>
        <TitleWrapper>
          <h1>{localizationStrings.new_chat.add_members}</h1>
        </TitleWrapper>
        <ChatOption style={{ flexDirection: "column" }}>
          <AddMembersButton onClick={() => setOption(ADD_MEMBERS)}>
            <PlusIcon /> {localizationStrings.new_chat.add_members}
          </AddMembersButton>
        </ChatOption>
      </Group>


      {initialContacts.length > 0 &&
        <Group>
          <TitleWrapper>
            <h1>{initialContacts.length} {localizationStrings.new_chat.members}</h1>
          </TitleWrapper>
          <MebersList>
            {initialContacts.map((contact, index) => (
              <ContactElement
                key={index}
                item={contact}
                handleRemove={handleRemoveContact}
              />
            ))}
          </MebersList>
        </Group>
      }

      <Group>
        <CreateButton
          disabled={inputValues.groupName.length <= 2 || selectedContacts.length == 0}
          onClick={() => handleCreateNewGroup()}
        >{localizationStrings.new_chat.create_group}</CreateButton>
      </Group>


    </Wrapper>
  );
};

export default SelectedMembers;
const Wrapper = styled.div`
display: flex;
flex-direction: column;
padding: 24px 21px;
gap: 25px;
`
const AddNewGroupContainer = styled.div`
  background: ${colors.newChatModalBg};
  display: flex;
  flex-direction: column;
  padding: 24px 21px;
  gap: 25px;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    color: ${colors.optionsGroupTitle};
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  cursor: pointer;
  max-height: 42px;
  max-width: 42px;
  min-width: 42px;
  min-height: 42px;
  object-fit: contain;
  position: relative;
  overflow: hidden;

  svg {

    position: relative;
    width: 100%;
    height: 100%;
  }
  img {
    object-fit:cover;
    position: relative;
    height: 43px;
    width: 43px;
  }
`;

const UserWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid #363e5180;
  padding: 8px 0;
  width:100%;
  overflow:hidden;
  h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    color: ${colors.white};
  }
`;

const Status = styled.div`
  padding: 8px 0;
  p {
    color: ${colors.white};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
`;

const ChatOption = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;

  border-bottom: 1px solid #363e5180;

  &:last-child {
    border: none;
  }
  h1 {
    min-width: 75px;
    color: #ffffff99;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
  }
  h2 {
    text-transform: capitalize;
    color: ${colors.white};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
  }
`;

const PhoneNumberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomPhoneWrapper = styled.div`
  background: ${colors.newChatModalInputBg};
  padding: 0 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;

  input {
    padding: 14px 16px;
    color: ${colors.white};
    background: ${colors.newChatModalInputBg};
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    &:focus {
      outline: none;
    }
  }
`;

const CustomInput = styled.input`
  background: none;
  border: none;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  width:100%;
  &:focus-visible {
    outline: none;
  }
`;

const CustomNameInput = styled(CustomInput)`
    font-size: 18px;
  font-weight: 500;
  line-height: 20px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.newChatModalBg};
  min-height: 38px;
`;

const BackButton = styled.button`
  width: 100%;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  gap: 10px;
  display: flex;
  align-items: center;
`;

const ProfileTitle = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  h1 {
    color: ${colors.white};
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
`;

const Separator = styled.div`
  background: rgba(54, 62, 81, 0.5);
  height: 1px;
  width: 100%;
`;

const MebersList = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
  padding: 10px 0 10px 16px;
  gap: 8px;
`;

const CreateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  background: ${colors.purple};
  color: ${colors.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  border: none;
  border-radius: 10px;
  padding: 14px 15px;
  cursor: pointer;
  &:disabled{
opacity:0.5;
  }
`;

const UserAvatarWrapper = styled.div`
  position: relative;
`;

const PencilWrapper = styled.div`
  position: absolute;
  bottom: -5px;
  right: 0;
`;

const ContactNotFound = styled.div`
display:flex;
justify-content:center;
p{
font-size: 16px;
font-weight: 400;
line-height: 22px;
text-align: center;
color:${colors.white};
opacity:0.7;
padding-bottom:30px;
}
`
const AddMembersButton = styled.button`
display:flex;
align-items:center;
gap:10px;
font-size: 16px;
font-weight: 400;
line-height: 22px;
letter-spacing: -0.40799999237060547px;
color:${colors.purple};
border:none;
border-radius:10px;
 background: ${colors.newChatModalInputBg};
 padding:14px 16px;
 cursor:pointer;
`