import { createContext, useCallback, useContext, useEffect } from "react";
import { useAuthService } from "./AuthContext";
import WebSocketHandler from "../Helpers/websocket/WebSocketHandler";
import { getQcWebsockerUrlApi, getWebsockerUrlApi } from "../API/websocket/getWebsocketurl";
import LocalStorage from "../Helpers/LocalStorage";
import { useLocation } from "react-router";
import { useOnlineStatus } from "../Helpers/useOnlineStatus";

export const WebSocketServiceContext = createContext({
    add_observer: () => { },
    remove_observer: () => { },
    getQcWebsocketUrl: () => { },
    sendCloseCallMessage: () => { },
    sendDenyTranslationMessage: () => { },
    connectHandler: () => { }
});


const WebSocketServiceProvider = ({ children }) => {
    const { user } = useAuthService();
    const isOnline = useOnlineStatus();
    const handler = new WebSocketHandler();
    const location = useLocation()

    const add_observer = (observer) => {
        handler.addObserver(observer);
    };

    const remove_observer = (observer) => {
        handler.removeObserver(observer);
    };

    const sendCloseCallMessage = (uuid) => {
        handler.sendCloseCallMessage(uuid);
    }

    const sendDenyTranslationMessage = (uuid) => {
        handler.sendDenyTranslationMessage(uuid)
    }

    const connectHandler = () => {
        handler.disconnect();

        handler.connect()
    }

    const getWebsocketUrl = useCallback(async (onSucces, errorCallback) => {
        try {
            await getWebsockerUrlApi
                (
                    function (response) {
                        onSucces(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
        } catch (err) {
            errorCallback();
        }
    });

    const getQcWebsocketUrl = useCallback(async (conversation_id, onSucces, errorCallback) => {
        try {
            await getQcWebsockerUrlApi
                (
                    conversation_id,
                    function (response) {
                        // LocalStorage.setWsLink(response.data.url);
                        sessionStorage.setItem("wsLink", response.data.url);
                        handler.connect();
                        onSucces(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
        } catch (err) {
            errorCallback();
        }
    });

    useEffect(() => {
        if (user && !location.pathname.includes("quick-chat")) {
            getWebsocketUrl(
                function (response) {
                    // LocalStorage.setWsLink(response.data.url);
                    sessionStorage.setItem("wsLink", response.data.url);
                    handler.connect();
                },
                function (error) {
                    console.log(error, "error")
                }
            );
        } else {
            // LocalStorage.setWsLink(null);
            sessionStorage.setItem("wsLink", null);
            handler.disconnect();
        }
    }, [user]);

    useEffect(() => {
        if (isOnline) {
            connectHandler();
        }

    }, [isOnline])


    return (
        <WebSocketServiceContext.Provider
            value={{
                add_observer,
                remove_observer,
                getQcWebsocketUrl,
                sendCloseCallMessage,
                sendDenyTranslationMessage,
                handler,
                connectHandler
            }}
        >
            {children}
        </WebSocketServiceContext.Provider>
    );
};
export const useWebSocketDataService = () =>
    useContext(WebSocketServiceContext);
export default WebSocketServiceProvider;
