import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import "../Assets/styles/customPhoneSelect.css"
import 'react-phone-number-input/style.css'

import { RecaptchaVerifier } from 'firebase/auth';
import { auth } from '../firebase';
import { useAuthService } from '../Context/AuthContext';
import EnterPhoneNumber from '../Components/Auth Components/EnterPhoneNumber';
import EnterOtpCode from '../Components/Auth Components/EnterOtpCode';
import { notifyError, notifySuccess } from '../Helpers/Notifications';
import Header from '../Components/GenericComponents/Header';
import CreateNewUser from '../Components/Auth Components/CreateNewUser';
import AplicationLanguage from '../Helpers/AplicationLanguage';
import { useNavigate } from 'react-router';
import SpinnerComponent from '../Components/GenericComponents/SpinnerComponent';
import { isMobile } from 'react-device-detect';
import { localizationStrings } from '../Localization/Localization';
import { useDefaultCountry } from '../hooks/useGeolocationDb';
import { createSearchParams, useSearchParams } from 'react-router-dom';


const LoginPage = ({ appVerifier }) => {

    const [phone, setPhone] = useState("");
    const [countryCode, setCountryCode] = useState("RO")
    const [otp, setOtp] = useState("");
    const [wrongCode, setWrongCode] = useState(false)
    const { signInWithPhone, verifyOtpCodeAndSignIn, getCurrentUser, getTranslationsLanguages, getCustomToken } = useAuthService();
    const [phase, setPhase] = useState(2);
    const { user } = useAuthService();
    const [defaultCountry] = useDefaultCountry();
    const navigate = useNavigate();
    const confirmationRes = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const initializeReCaptcha = () => {
        if (window.recaptchaVerifier == null) {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    //onSignInSubmit();
                },
            });

        }
    }

    const resetRechaptcha = () => {
        window.recaptchaVerifier.recaptcha.reset();
    }

    const reinitializeRecaptchaContainer = () => {
        window.recaptchaVerifier = null;
        let element = document.getElementById("recaptcha-container");
        let parent = element.parentElement;
        parent.removeChild(element)
        let newElement = document.createElement("div")
        newElement.setAttribute("id", "recaptcha-container")
        parent.appendChild(newElement);

    }

    const onSignInSubmit = (e) => {
        if (window.recaptchaVerifier == null) {
            initializeReCaptcha();
        } else {
            resetRechaptcha();
        }

        setIsLoading(true)
        signInWithPhone(
            phone,
            window.recaptchaVerifier,
            function (confirmationResult) {
                setIsLoading(false);
                confirmationRes.current = confirmationResult;
                setPhase(3);
                notifySuccess(`${localizationStrings.login.code_sent}`)
            },
            function (error) {
                notifyError(`${localizationStrings.profile.something_wrong}`)
                console.log(error, "err")
                setIsLoading(false)
            }
        )
    }

    const handleVerifyCode = (otp) => {
        setIsLoading(true)
        verifyOtpCodeAndSignIn(
            confirmationRes.current,
            otp,
            function (response) {
                reinitializeRecaptchaContainer();
                setWrongCode(false);
                handleCodeResponse();
            },
            function (error) {
                setOtp("");
                setWrongCode(true);
                console.log(error);
                notifyError(`${localizationStrings.profile.something_wrong}`);
                setIsLoading(false);
            }
        );
    };


    const handleCodeResponse = () => {
        if (searchParams.get("desktop_login") === "true") {
            getCustomToken(
                (response) => {
                    setSearchParams((prev) => {
                        prev.set("access_token", response.data.authentication_token)
                    })

                    navigate({
                        pathname: "/desktop_login_success",
                        search: createSearchParams(searchParams).toString()
                    })
                    setIsLoading(false)
                },
                (error) => {
                    console.log(error)
                },
            )
        } else {
            getCurrentUser(
                function (response) {
                    setIsLoading(false);
                    navigate("/")
                },
                function (error) {
                    if (error?.response?.status === 404) {
                        setIsLoading(false)
                        setPhase(4);
                    }
                }
            )
        }

    }

    const handleResendOTP = () => {
        onSignInSubmit()
        // window.location.reload(false)
    }
    const handleContinue = () => {
        setPhase(2)
    }

    const checkReady = () => {
        if (otp.length !== 6) {
        } else {
            handleVerifyCode(otp);
        }
    };

    useEffect(() => {
        checkReady();
    }, [otp]);

    useEffect(() => {
        if (phase == 1 && phase == 2) {
            return
        }

        getTranslationsLanguages(function () { },
            function () { }
        )

    }, [phase])

    // DISALBED SELECT APP LANGUAGE, SET PHASE=1 TO ENABLE
    const phase1 =
        <AplicationLanguage
            handleContinue={handleContinue}
        />

    const phase2 =
        <>
            <Header />
            <EnterPhoneNumber
                phone={phone}
                setPhone={setPhone}
                setCountryCode={setCountryCode}
                onSigninSubmit={onSignInSubmit}
                defaultCountry={defaultCountry}
            />
        </>


    const phase3 =
        <>
            <Header />
            <EnterOtpCode
                otp={otp}
                setOtp={setOtp}
                wrongCode={wrongCode}
                handleVerifyCode={handleVerifyCode}
                handleResendOTP={handleResendOTP}
            />
        </>


    const phase4 = <div>
        <Header />
        <CreateNewUser
            countryCode={countryCode}
        />
    </div>


    var content = phase1;

    switch (phase) {
        case 1:
            content = phase1
            break;
        case 2:
            content = phase2
            break;
        case 3:
            content = phase3
            break;
        case 4:
            content = phase4
            break;
        default:
            break;
    }

    useEffect(() => {
        if (isMobile) {
            window.location.replace('http://emotii.ai');
        }
        reinitializeRecaptchaContainer()
    }, [])

    useEffect(() => {
        // if (user && searchParams.get("desktop_login") !== "true") {
        //     navigate({
        //         pathname: "/",
        //         search: createSearchParams(searchParams).toString()
        //     },
        //         { replace: true }
        //     )
        // }
    }, [])

    return (
        <SpinnerComponent isLoading={isLoading}>
            <FirstWrapper>
                <LoginPageContainer>
                    {content}
                </LoginPageContainer>
            </FirstWrapper>
        </SpinnerComponent>
    )
}

export default LoginPage
const LoginPageContainer = styled.div`
display:flex;
flex-direction:column;
width:100%;
max-height:calc(100vh - 40px);
@media(max-width:1440px){
    max-height:100vh;
}
`
const FirstWrapper = styled.div`
width:100%;
max-width: 1600px;
margin-left: auto;
margin-right: auto;
margin-top: 20px;
margin-bottom: 20px;
overflow: hidden;
}
@media(max-width:1440px){
    margin:0;
}
`