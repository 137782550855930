import React, { useEffect, useState } from 'react'
import Header from '../Components/GenericComponents/Header'
import styled from 'styled-components'
import { colors } from '../Assets/styles/colors'
import SingleOptionMenu from '../Components/Auth Components/SingleOtionMenu'
import { localizationStrings } from '../Localization/Localization'
import { appLanguageList, languageList } from '../Constants/DropdownValues'
import LocalStorage from './LocalStorage'

const AplicationLanguage = ({ handleContinue }) => {

    // const [aplicationLanguage, setAplicationLanguage] = useState(LocalStorage.getAppLanguage() || "EN");
    const [aplicationLanguage, setAplicationLanguage] = useState("EN");
    const [forceRender, setForceRender] = useState();


    useEffect(() => {
        if (!aplicationLanguage) {
            LocalStorage.setAppLanguage("EN");
            localizationStrings?.setLanguage("EN");
            return
        }
        localizationStrings?.setLanguage(aplicationLanguage);
        LocalStorage.setAppLanguage(aplicationLanguage);
        setForceRender({});
    }, [aplicationLanguage])

    return (
        <>
            <Header />
            <Container>

                <LeftSide>
                    <Card>
                        <GroupTitle>
                            <h1> {localizationStrings.login.app_language}</h1>
                        </GroupTitle>
                        <Group>
                            <SingleOptionMenu
                                value={aplicationLanguage}
                                setValue={setAplicationLanguage}
                                valuesList={appLanguageList}
                                placeholder={"Select app language"}
                            />
                        </Group>
                        <Group>
                            <LoginButton
                                disabled={aplicationLanguage === null}
                                id='language-continue-button'
                                onClick={(e) => handleContinue(e)}>
                                {localizationStrings.register.next_button}
                            </LoginButton>
                        </Group>
                    </Card>

                </LeftSide>
                <RightSide>

                </RightSide>
            </Container>
        </>
    )
}

export default AplicationLanguage
const Container = styled.div`


`
const LeftSide = styled.div`
width:100%;
height:100%;
display:flex;
align-items:center;
justify-content:center;
padding:0 32px;

`
const RightSide = styled(LeftSide)`
`

const Card = styled.div`
display:flex;
flex-direction:column;
max-width:400px;
width:100%;
gap:20px;
`
const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
h1{
color:${colors.white};
font-size: 32px;
font-weight: 600;
line-height: 39px;
letter-spacing: 0px;
text-align: left;
}
p{
color:#FFFFFF80;
font-size: 14px;
font-weight: 400;
line-height: 17px;
letter-spacing: 0px;
text-align: left;
}
`;

const GroupTitle = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
h1{
    color:${colors.white};
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0px;
    text-align: left;
}
    p{
    color:#FFFFFF80;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    }
`

const LoginButton = styled.button`
display: flex;
align-items: center;
justify-content:center;
gap: 3px;
background: ${colors.purple};
color: ${colors.white};
font-size: 16px;
font-weight: 600;
line-height: 22px;
text-align: center;
border: none;
border-radius: 10px;
padding: 14px 15px;
cursor: pointer;
svg {
  height: 20px;
  width: 20px;
}
&:disabled{
    cursor:inherit;
    opacity:0.3;
}
`;