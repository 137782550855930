
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'

const ClosedQuickChatInfo = () => {
    return (
        <QcCloseInfoWrapper>
            <h1>
                This chat is closed
            </h1>
        </QcCloseInfoWrapper>
    )
}

export default ClosedQuickChatInfo

const QcCloseInfoWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
margin-left:auto;
margin-right:auto;
padding:30px;
h1{
    width:fit-content;
color:${colors.white};
font-size: 18px;
font-weight: 400;
line-height: 22px;
text-align: center;

}
`