import React, { useState } from 'react'
import styled from 'styled-components';
import { colors } from '../Assets/styles/colors';
import LanguageMenu from '../Components/Auth Components/LanguageMenu';
import { useAuthService } from '../Context/AuthContext';
import { localizationStrings } from '../Localization/Localization';
import { ReactComponent as Decoration } from "../Assets/icons/enterOtpCodeIcon.svg"
import { ReactComponent as NavLogo } from "../Assets/icons/landingPage/nav-logo.svg"

const QuickChatDetailsPage = ({ handleContinue, name, setName, language, setLanguage, quickChat }) => {

    const { translationsLanguages } = useAuthService();

    return (
        <FirstWrapper>
            <LogoWrapper>
                <NavLogo />
                <DecorationLine />
            </LogoWrapper>
            <Content>
                <RowShowSmall style={{ marginBlock: "10px" }}>
                    <DecorationWraper>
                        <Decoration />
                    </DecorationWraper>
                </RowShowSmall>
                <TitleWrapper>
                    <Title>{localizationStrings.quick_chat.invited_by} <span>'{quickChat.quick_chat_name}'</span> {localizationStrings.quick_chat.emotii_chat}!</Title>
                    <Subtitle>{localizationStrings.quick_chat.enter_name_and_language}</Subtitle>
                </TitleWrapper>

                <Row style={{ gap: "50px " }}>
                    <Group>
                        <Group style={{ marginTop: '40px' }}>
                            <GroupTitle>{localizationStrings.quick_chat.your_name}</GroupTitle>
                            <CustomInputWrapper>
                                <input
                                    placeholder={localizationStrings.register.name_placeholder}
                                    onChange={(e) => setName(e.currentTarget.value)}
                                    value={name}
                                    name='name'
                                    maxLength={100}
                                />
                            </CustomInputWrapper>
                        </Group>


                        <Group style={{ marginTop: '20px' }}>
                            <GroupTitle>{localizationStrings.quick_chat.chat_language}</GroupTitle>
                            <LanguageMenu
                                value={language}
                                setValue={setLanguage}
                                valuesList={translationsLanguages}
                            />
                        </Group>
                        <Group style={{ marginTop: '20px' }}>
                            <PrimaryBtn
                                disabled={!language || name === ""}
                                onClick={(e) => handleContinue(e)}>
                                {localizationStrings.register.next_button}
                            </PrimaryBtn>
                        </Group>
                    </Group>
                    <DecorationGroup >
                        <DecorationWraper>
                            <Decoration />
                        </DecorationWraper>

                    </DecorationGroup>
                </Row>


            </Content>
        </FirstWrapper>
    )
}

export default QuickChatDetailsPage

const FirstWrapper = styled.div`
display:flex;
flex-direction:column;
width:100%;
height:100svh;
overflow:hidden;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (max-width: 1440px) {
    margin: 0;
    padding: 0;
  }

`;
const Content = styled.div`
display:flex;
flex-direction:column;
width:100%;
padding:75px 150px;
margin-top:110px;
overflow:auto;
height:100%;
@media(max-width:990px){
    margin-top:60px;
    padding:40px 20px;
}

`
const TitleWrapper = styled.div`
display:flex;
flex-direction:column;
gap:20px;
@media(max-width:990px){
    gap:10px;
}
`
const Title = styled.h1`
color:${colors.white};
font-size: 30px;
font-weight: 400;
line-height: 30px;
span{
    font-weight: 700;
}
@media(max-width:990px){
    font-size: 20px;
    line-height: 25px;
}
`
const Subtitle = styled.p`
color:${colors.white};
opacity:0.5;
font-size: 16px;
font-weight: 400;
line-height: 30px;

`
const Row = styled.div`
display:flex;
`

const Group = styled.div`
display:flex;
flex-direction:column;
gap:20px;
width:100%;
@media(max-width:990px){
    gap:10px;
}
`
const GroupTitle = styled.h1`
color:${colors.white};
font-size: 32px;
font-weight: 600;
line-height: 38.73px;
text-align: left;
@media(max-width:990px){
    font-size: 20px;
    line-height: 28px;
}
`

const CustomInputWrapper = styled.div`
 display: flex;
 width:100%;
input {
     width:100%;
    padding: 14px 16px;
    color: ${colors.white};
    background: ${colors.newChatModalInputBg};
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    &:focus {
      outline: none;
    }
  }
`
const PrimaryBtn = styled.button`
display: flex;
align-items: center;
justify-content:center;
gap: 3px;
background: ${colors.purple};
color: ${colors.white};
font-size: 16px;
font-weight: 600;
line-height: 22px;
text-align: center;
border: none;
border-radius: 10px;
padding: 14px 15px;
cursor: pointer;
svg {
  height: 20px;
  width: 20px;
}

&:disabled{
    cursor:inherit;
    opacity:0.3;
}
`;
const DecorationWraper = styled.div`
display:flex;
width:100%;
height:100%;
margin-left:auto;
align-items:center;
svg{
    margin-left:auto;
  height:100%;
  width:100%;
  max-width:400px;
}
@media(max-width:990px){
    margin-right:auto;
    svg{
        margin-right:auto;
          max-width:300px;
    }
}
@media(max-width:425px){
    svg{
          max-width:200px;
    }
}
`
const DecorationGroup = styled(Group)`
@media(max-width:990px){
    display:none;
}
`
const RowShowSmall = styled(Row)`
@media(max-width:990px){
    display:flex;
}
@media(max-width:425px){
    display:none;
}
display:none;
`
const LogoWrapper = styled.div`
display:flex;
flex-direction:column;
position:fixed;
background:${colors.quickChatBg};
z-index:2;
width:100%;
svg{
margin:50px 0 0 100px;
    color:white;
}

@media(max-width:990px){
    svg{
        margin:25px 0 0 50px;
            color:white;
        }
}
`

const DecorationLine = styled.div`
min-height:7px;
max-width:150px;
min-width:150px;
margin-top:30px;
background:${colors.purple};

`