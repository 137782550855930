import React from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as ReplyIcon } from "../../Assets/icons/context-menu-icons/ReplyIcon.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import FileAttachmentItem from "./FileAttachmentItem";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import { DefaultVideoLayout, defaultLayoutIcons } from "@vidstack/react/player/layouts/default";
import { ReactComponent as FileIcon } from "../../Assets/icons/fileIcon.svg"
import { findTranslatedTextForUser } from "../../Helpers/chatHelper/ChatHelper";

const ReplyMessageContainer = ({ message, handleClose, user }) => {

  return (
    <Container>
      <EditWrapper>
        <ReplyIcon />
      </EditWrapper>


      <MessageWrapper style={{ height: "100%" }}>
        <h1>{message.user.name}</h1>
        <MessageWrapper>
          <p>{findTranslatedTextForUser((message.message_translations), user).translation}</p>
          {message.document &&
            <h2>{message.document.name}</h2>
          }
        </MessageWrapper>
      </MessageWrapper>


      {message.document &&
        <MessageWrapper>
          <IconWrapper>
            <FileIcon />
          </IconWrapper>

          <IconWrapper>
            <FileIcon />
          </IconWrapper>

        </MessageWrapper>
      }

      {message.video &&
        <MessageWrapper>
          {message.video &&
            <img src={message.video.thumbnail.url} alt="reply_video" />
          }
        </MessageWrapper>
      }

      {message.image &&
        <MessageWrapper>
          {message.image &&
            <img src={message.image.url} alt="reply_image" />
          }
        </MessageWrapper>
      }

      <CloseBtn onClick={() => handleClose()}>
        <CloseIcon />
      </CloseBtn>

    </Container>
  );
};

export default ReplyMessageContainer;

const Container = styled.div`
display:flex;
align-items:center;
background:${colors.userMenuHeaderBg};
z-index:10;
max-height:300px;
position:absolute;
width:100%;
bottom:74px;
  svg {
    color: white;
  }
  @media(max-width:990px){
    max-height:100px;
}
`;
const EditWrapper = styled.div`
display:flex;
height:100%;
align-items:center;
border-right:2px solid ${colors.purple};
padding: 10px 20px;
  svg {
    color: ${colors.purple};
  }
`;
const MessageWrapper = styled.div`
display:flex;
flex-direction:column;
overflow:hidden;
gap:5px;
padding:10px;
max-height:100px;
height:100%;
width: 100%;
position:relative;
  h1 {
    color: ${colors.purple};
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }
  p {
    color: ${colors.white};
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  h2{
    opacity:0.8;
    color: ${colors.white};
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  h2{
    opacity:0.8;
    color: ${colors.white};
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  img {
    max-width:400px;
    margin-left: auto;
    max-height: 100px;
  }
  @media(max-width:990px){
    max-height:100px;
    img {
          max-height: 60px;
    }
}
`;
const CloseBtn = styled.button`
  margin-left: auto;
  margin-right: 20px;
  border: none;
  background: none;
  cursor: pointer;
`;


const IconWrapper = styled.div`
display:flex;
margin-left:auto;
svg{
max-width:40px;
  max-height:40px;
}
`