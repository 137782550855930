import React, { useEffect, useRef } from 'react'
import { ReactComponent as Neutral } from "../../Assets/icons/messageMood/neutral.svg"
import { ReactComponent as Romantic } from "../../Assets/icons/messageMood/romantic.svg"
import { ReactComponent as Humorous } from "../../Assets/icons/messageMood/humorous.svg"
import { ReactComponent as Casual } from "../../Assets/icons/messageMood/casual.svg"
import { ReactComponent as Supportive } from "../../Assets/icons/messageMood/supportive.svg"
import { ReactComponent as Business } from "../../Assets/icons/messageMood/business.svg"
import { ReactComponent as Formal } from "../../Assets/icons/messageMood/formal.svg"
import { ReactComponent as SmallPoint } from "../../Assets/icons/messageMood/smallPoint.svg"
import { ReactComponent as BigPoint } from "../../Assets/icons/messageMood/bigPoint.svg"
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";


import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { BUSINESS_MOOD, CASUAL_MOOD, FORMAL_MOOD, HUMOROUS_MOOD, NEUTRAL_MOOD, ROMANTIC_MOOD, SUPPORTIVE_MOOD } from '../../Constants/constantValues'


const MoodItem = ({ mood, selected, onClick }) => {

    const displayMoodIcon = (mood) => {
        let returnMood = <Neutral />
        switch (mood) {
            case ROMANTIC_MOOD:
                returnMood = <Romantic />
                break;
            case HUMOROUS_MOOD:
                returnMood = <Humorous />
                break;
            case CASUAL_MOOD:
                returnMood = <Casual />
                break;
            case SUPPORTIVE_MOOD:
                returnMood = <Supportive />
                break;
            case BUSINESS_MOOD:
                returnMood = <Business />
                break;
            case FORMAL_MOOD:
                returnMood = <Formal />
                break;
            default:
                break;
        }

        return returnMood
    }

    const displayMoodText = (mood) => {
        let returnMood = "Neutral"

        switch (mood) {
            case ROMANTIC_MOOD:
                returnMood = "Romantic"
                break;
            case HUMOROUS_MOOD:
                returnMood = "Humorous"
                break;
            case CASUAL_MOOD:
                returnMood = "Casual"
                break;
            case SUPPORTIVE_MOOD:
                returnMood = "Supportive"
                break;
            case BUSINESS_MOOD:
                returnMood = "Business"
                break;
            case FORMAL_MOOD:
                returnMood = "Formal"
                break;
            default:
                break;
        }

        return returnMood
    }


    return (
        <ItemContainer selected={selected} onClick={onClick}>
            <IconWrapper selected={selected}>
                {displayMoodIcon(mood)}
            </IconWrapper>

            {selected ? <BigPoint /> : <SmallPoint />}
            <p>{displayMoodText(mood)}</p>


        </ItemContainer>
    )
}

const MessageMoodSelector = ({ isMenuOpen, messageMood, closeModal, setMood }) => {
    const moods = [ROMANTIC_MOOD, HUMOROUS_MOOD, CASUAL_MOOD, NEUTRAL_MOOD, SUPPORTIVE_MOOD, BUSINESS_MOOD, FORMAL_MOOD];
    const ref = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                closeModal(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);


    const handleSelectMood = (mood) => {
        setMood(mood);
        closeModal()
    }


    return (
        <Container ref={ref}>
            <Line>
                <SmallInfoText>Define the style for you messages to improve translation.</SmallInfoText>
                <CloseBtn onClick={closeModal}>
                    <CloseIcon />
                </CloseBtn>
            </Line>

            <SelectorWrapper>
                <MiddleLIne />
                {moods.map((mood, index) => (
                    <MoodItem
                        key={index}
                        mood={mood}
                        selected={mood === messageMood}
                        onClick={() => handleSelectMood(mood)}
                    />
                ))}
            </SelectorWrapper>


        </Container>
    )
}

export default MessageMoodSelector

const Container = styled.div`
display:flex;
flex-direction:column;
align-items:center;
background:${colors.userMenuHeaderBg};
z-index:10;
max-height:300px;
height:auto;
position:absolute;
width:100%;
bottom:74px;
padding:10px 20px;
justify-content:space-between;
gap:20px;

svg{
    color:white;
}
@media(max-width:990px){
    max-height:120px;
}
`
const ItemContainer = styled.div`
display:flex;
align-items:center;
jusify-content:center;
flex-direction:column;
gap:5px;
color:${colors.fadedPurple};
height:100%;
z-index:1;
cursor:pointer;
p{
color:${colors.fadedPurple};
font-size: 12px;
font-weight: 400;
line-height: 16px;
text-align: center;
}

${props => props.selected ?
        `
  p{
  color:${colors.purple};
  }
  svg{
  color:${colors.purple};
  }
  
  `
        : ``}
`
const MiddleLIne = styled.div`
height:2px;
display:flex;
width:100%;
position:absolute;
top:36px;
background:${colors.fadedPurple};
z-index:0;
`
const CloseBtn = styled.button`
position:absolute;
display:flex;
align-items:center;
background:none;
border:none;
cursor:pointer;
right:0;
svg{
width:38px;
height:20px;
}
`
const Line = styled.div`
display:flex;
gap:10px;
align-items:center;
justify-content:space-between;
width:100%;
position:relative;
`
const SelectorWrapper = styled.div`
display:flex;
align-items:center;
position:relative;
width:100%;
justify-content:space-between;
gap:10px;
overflow:hidden;
@media(max-width:990px){
gap:5px;
}
`
const SmallInfoText = styled.p`
color:${colors.white};
font-size: 12px;
font-weight: 400;
line-height: 14px;
text-align: left;
`
const IconWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
svg{
color:${colors.fadedPurple};
height:22px;
width:22px;
}
${props => props.selected ?
        `
svg{
color:${colors.purple};
}

`
        : ``}
`