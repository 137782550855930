import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { colors } from '../../Assets/styles/colors';
import { localizationStrings } from '../../Localization/Localization';
import { ReactComponent as SearchIcon } from '../../Assets/icons/searchIcon.svg';
import ForwardModalChatElement from './ForwardModalChatElement';
import { cloneDeep } from 'lodash';
import ForwardModalContactElement from './ForwardModalContactEmelement';
import { filterContacts } from '../../Helpers/filters/filterContacts';
import { filterConversations } from '../../Helpers/filters/filterConversations';
import { useConversationService } from '../../Context/ConversationsContext';
import { notifyError } from '../../Helpers/Notifications';
import moment from 'moment';
import FileAttachmentItem from './FileAttachmentItem';
import { MediaPlayer, MediaProvider } from '@vidstack/react';
import { DefaultVideoLayout, defaultLayoutIcons } from '@vidstack/react/player/layouts/default';
import { useAuthService } from '../../Context/AuthContext';
import { findTranslatedTextForUser } from '../../Helpers/chatHelper/ChatHelper';


const ForwardModal = ({ handleClose, chats, contacts, contextMenuItem }) => {

    const { forwardMessage } = useConversationService()
    const { user } = useAuthService();
    const [searchValue, setSearchValue] = useState("");
    const [selectedConversations, setSelectedConversations] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);

    const { image, original_message, video, message_translations } = contextMenuItem.messageObject;

    const file = contextMenuItem.messageObject.document
    const handleSearchChange = (e) => {
        let value = e.currentTarget.value
        setSearchValue(value)
    }

    const handleConversationSelect = (element) => {
        let clone = cloneDeep(selectedConversations);
        let found = selectedConversations?.find((item) => item.id == element.id)
        if (found) {
            let filtered = clone.filter((item) => item.id !== element.id);
            setSelectedConversations(filtered)
        } else {
            clone.push(element);
            setSelectedConversations(clone)
        }
    }

    const handleContactSelect = (element) => {
        let clone = cloneDeep(selectedContacts);
        let found = selectedContacts?.find((item) => item.id == element.id)
        if (found) {
            let filtered = clone.filter((item) => item.id !== element.id);
            setSelectedContacts(filtered)
        } else {
            clone.push(element);
            setSelectedContacts(clone)
        }
    }

    const displaySelectionNumber = () => {
        let selection = selectedConversations.length + selectedContacts.length;

        if (selection == 0) {
            return ""
        }

        if (selection === 1) {
            return `${selection + " chat"}`
        }

        if (selection > 1) {
            return `${selection + " chats"}`
        }
    }

    const handleForwardmessage = () => {

        let conversationIds = selectedConversations.map((item) => { return (item.id) })
        let phoneNumbers = selectedContacts.map((item) => { return (item.phone_number) })
        let nowDate = moment().toISOString();
        forwardMessage(
            conversationIds,
            phoneNumbers,
            nowDate,
            contextMenuItem.messageId,
            (response) => {
                handleClose();
            },
            (error) => {
                notifyError("Something wrong...")
            },


        )
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Escape') {
                event.preventDefault();
                // 👇️ your logic here
                handleClose();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    function auto_grow(element) {
        element.style.height = "5px";
        element.style.height = element.scrollHeight + "px";
    }

    useEffect(() => {
        if (original_message) {
            let element = document.getElementById("resizeTa-3");
            if (element) {
                auto_grow(element)
            }
        }

    }, [original_message])


    return (
        <FullScreenContainer>
            <Card>
                <Header>
                    <CloseBtn onClick={handleClose}>
                        <CloseIcon />
                    </CloseBtn>
                    <Text>
                        <h1>{localizationStrings.chat.forward_to}</h1>
                    </Text>
                    <div></div>
                </Header>

                <Content>
                    <Group>
                        <h2>{localizationStrings.chat.forward_message}</h2>
                        {image &&
                            <ImgWrapper>
                                <img src={image.url} alt='pht' />
                            </ImgWrapper>
                        }
                        {video &&
                            <VideoPreview>
                                <MediaPlayer
                                    load='visible'
                                    posterLoad='visible'
                                    controls
                                    playsInline
                                    src={video.video_url}
                                >
                                    <MediaProvider />
                                    <DefaultVideoLayout thumbnails={video.thumbnail.url} icons={defaultLayoutIcons} />
                                </MediaPlayer>
                            </VideoPreview>
                        }

                        {file &&
                            <FileAttachmentItem
                                file={file}
                            />
                        }

                        {original_message.length > 0 &&
                            <>
                                <CustomTextArea
                                    id='resizeTa-3'
                                    value={findTranslatedTextForUser((message_translations), user).translation}
                                    readOnly />
                            </>
                        }


                    </Group>
                    <Group>
                        <CustomSearch>
                            <SearchIcon />
                            <input
                                placeholder={(localizationStrings.chat.search)}
                                onChange={(e) => handleSearchChange(e)}
                                value={searchValue}
                            />
                        </CustomSearch>
                    </Group>
                    {filterConversations(chats, searchValue)?.length > 0 && <Group>
                        <h2>{localizationStrings.chat.chats}</h2>
                        <ContactsWrapper>
                            {filterConversations(chats, searchValue)?.map((item, index) => (
                                <ForwardModalChatElement
                                    key={item.id}
                                    data={item}
                                    handleContactSelect={handleConversationSelect}
                                    selected={selectedConversations.find((element) => element.id === item.id)}
                                />

                            ))}
                        </ContactsWrapper>
                    </Group>}
                    {filterContacts(contacts.on_app_contacts, searchValue)?.length > 0 &&
                        <Group>
                            <h2>{localizationStrings.new_chat.contacts}</h2>
                            <ContactsWrapper>
                                {filterContacts(contacts.on_app_contacts, searchValue)?.map((item, index) => (
                                    <ForwardModalContactElement
                                        key={item.id}
                                        item={item}
                                        handleSelectContact={handleContactSelect}
                                        selected={selectedContacts.find((element) => element.id === item.id)}
                                    />

                                ))}
                            </ContactsWrapper>
                        </Group>
                    }


                </Content>

                <Bottom>
                    <ChatNumberWrapper>
                        <p>{displaySelectionNumber()} </p>
                    </ChatNumberWrapper>
                    {selectedContacts.concat(selectedConversations).length > 0 &&
                        <ForwardBtn
                            onClick={() => handleForwardmessage()}
                        >
                            {localizationStrings.chat.forward}
                        </ForwardBtn>}


                </Bottom>

            </Card>

        </FullScreenContainer>
    )
}

export default ForwardModal

const FullScreenContainer = styled.div`
display:flex;
align-items:center;
justify-content:center;
position:fixed;
width:100%;
height:100%;
background:rgba(0,0,0,0.3);
left:0;
top:0;
padding:5vh 40vw;
`
const Card = styled.div`
display:flex;
flex-direction:column;
gap:24px;
padding:25px 20px;
background:${colors.newChatModalBg};
width:100%;
height:100%;
min-width:600px;
`
const Group = styled.div`
display:flex;
flex-direction:column;
gap:10px;
h2{
font-size: 12px;
font-weight: 500;
line-height: 22px;
letter-spacing: -0.40799999237060547px;
text-align: left;
color:rgba(117, 117, 117, 1);
text-transform:uppercase;
}
`
const Header = styled.div`
display:flex;
justify-content:space-between;

`
const CloseBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
border:none;
background:none;
cursor:pointer;
`
const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: px;
overflow:hidden;
h1{
  text-transform:capitalize;
    color:${colors.white};
font-size: 20px;
font-weight: 500;
line-height: 30px;
text-align: left;

}
`;
const Content = styled.div`
display:flex;
flex-direction:column;
height:100%;
overflow:auto;
gap:20px;
`
const Bottom = styled.div`
padding-top:25px;
display:flex;
justify-content:space-between;
border-top:1px solid rgba(54, 62, 81, 0.5);
margin-top:auto;
`
const ChatNumberWrapper = styled.div`
display:flex;
align-items:center;
p{
font-size: 16px;
font-weight: 400;
line-height: 22px;
letter-spacing: -0.40799999237060547px;
text-align: left;
color:white;
}
`
const ForwardBtn = styled.button`
font-size: 16px;
font-weight: 600;
line-height: 22px;
text-align: left;
color:${colors.purple};
border:none;
background:none;
cursor:pointer;

`
const CustomTextArea = styled.textarea`
background:${colors.newButtonOptionsBg};
border-radius:10px;
resize:none;
max-height:160px;
outline:none;
border:none;
padding:10px;
color:white;
font-size: 14px;
font-weight: 400;
line-height: 17px;
letter-spacing: 0px;
text-align: left;
`
const CustomSearch = styled.div`
background:${colors.newButtonOptionsBg};
border-radius:10px;
display:flex;
padding:10px;
gap:5px;
align-items:center;
input{
    background:none;
    border:none;
    outline:none;
    color:white;
font-size: 14px;
font-weight: 400;
line-height: 17px;
letter-spacing: 0px;
text-align: left;
width:100%;
}
`
const ContactsWrapper = styled.div`
background:${colors.newButtonOptionsBg};
display: flex;
flex-direction: column;
border-radius:10px;
padding: 10px 0 10px 16px;
gap: 8px;
`

const ImgWrapper = styled.div`
img{
    overflow:hidden;
        cursor:pointer;
width:100%;
    }
`

const VideoPreview = styled.div`
display:flex;
height:100%;
width:100%;

justify-content:center;
align-items:center;
position:relative;
overflow:hidden;
`