class WebSocketObserver {
  constructor(channelName, callback) {
    this.channelName = channelName;
    this.callback = callback;
  }

  update(message) {
    this.callback(message);
  }

}

export default WebSocketObserver;
