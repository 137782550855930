import { GET, POST } from "../API"
let source;
export const blockContactAPICall = (phoneNumber, name, onSuccess, onError) => {
    const data = {
        phone_number: phoneNumber,
        name: name
    }
    source = POST(`/api/v1/chat/contacts/block`, data, onSuccess, onError, true, source)
}

export const unblockContactAPICall = (phoneNumber, onSuccess, onError) => {
    const data = {
        phone_number: phoneNumber
    }
    source = POST(`/api/v1/chat/contacts/unblock`, data, onSuccess, onError, true, source)
}

export const blockUnknowContactsAPICall = (blockUnknownUsers, onSuccess, onError) => {
    const data = {
        block_unknown_users: blockUnknownUsers
    }
    source = POST(`/api/v1/chat/contacts/block_unknown_contacts`, data, onSuccess, onError, true, source)
}

export const getBlockedContactsAPICall = (onSuccess, onError) => {

    GET(`/api/v1/chat/contacts/blocked_contacts`, onSuccess, onError, true, source)
}