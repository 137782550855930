import { POST } from "../API"


export const createQuickChatApi = (name, onSuccess, onError) => {
    const data = {
        name: name
    }
    POST(`/api/v1/chat/quick_chat/create`, data, onSuccess, onError)
}

export const CloseQuickChatAPICall = (conversation_id, onSuccess, onError) => {
    const data = {
        conversation_id: conversation_id,
    }

    POST(`/api/v1/chat/quick_chat/close`, data, onSuccess, onError)
}
