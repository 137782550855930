import React from "react";
import styled from "styled-components";
import { colors } from "../Assets/styles/colors";
import { ReactComponent as NavLogo } from "../Assets/icons/landingPage/nav-logo.svg";
import { ReactComponent as Decor1 } from "../Assets/icons/quickChat/decor1.svg";
import { ReactComponent as DecorPhone } from "../Assets/icons/quickChat/phoneDecore.svg";
import { ReactComponent as New } from "../Assets/icons/newChatIcon.svg";
import { localizationStrings } from "../Localization/Localization";
import { useNavigate } from "react-router";
import { isMobile } from "react-device-detect";

const QuickChatClosedPage = () => {
  const navigate = useNavigate()

  let registerText = "   Register on emotii to create chats"
  if (isMobile) {
    registerText = "   Register on emotii"
  }
  return (
    <FullWrapper>
      <FirstWrapper>
        <LogoWrapper>
          <NavLogo />
          <DecorationLine />
        </LogoWrapper>
        <Content>

          <Row>
            <Group>
              <GroupTitle>
                This conversation <br /> doesn’t exist anymore!
              </GroupTitle>
              <Subtitle>
                Join emotii and keep your chats forever.
              </Subtitle>
              <PrimaryBtn

                onClick={(e) => window.open("https://7jkwc.app.link/redirect-quick-chats")}>
                <New />
                {registerText}
              </PrimaryBtn>
            </Group>

          </Row>
          <RowShowSmall >
            <DecorPhone />
          </RowShowSmall>

          <DecorationWrapper>
            <Decor1 />
          </DecorationWrapper>
          <PhoneDecoration>
            <DecorPhone />
          </PhoneDecoration>
        </Content>

      </FirstWrapper>
    </FullWrapper>
  );
};

export default QuickChatClosedPage;

const FullWrapper = styled.div`
  background: ${colors.quickChatClosedBg};
  height: 100%;
  min-height: 100svh;
  max-height: 100svh;
`;

const FirstWrapper = styled.div`
  width: 100%;
  height: 100svh;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  align-items: center;
  @media (max-width: 1440px) {
    margin: 0;
    padding: 0;

  }


`;


const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  svg {
    margin: 50px 0 0 100px;
    color: white;
  }
  @media(max-width:990px){
    svg{
        margin:25px 0 0 50px;
        }
}
`;
const DecorationLine = styled.div`
  min-height: 7px;
  max-width: 150px;
  min-width: 150px;
  margin-top: 30px;
  background: ${colors.purple};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height:100%;
  padding: 75px 150px;
  overflow:auto;
  position:relative;
  @media (max-width: 990px) {
    padding: 20px;
  }

`;

const DecorationWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: -150px;
  width:100%;
  svg {
    width: 100%;
  }

  @media (max-width: 990px) {
    width: unset;
    right: 0;
    bottom:-50px;

    svg {
      width: inherit;
      display: flex;
    }
  }
`;

const PhoneDecoration = styled.div`
  position: absolute;
  display: flex;
  right: 50px;
  bottom: 100px;
  z-index: 1;
  svg {
    margin-left: auto;
    width: fit-content;
    position: relative;
    max-height:500px;
  }

  @media (max-width: 990px) {
  display:none;
  }
`;

const Row = styled.div`
display:flex;
@media(max-width:990px){
    justify-content:center;
}
z-index:2;
`

const Group = styled.div`
display:flex;
flex-direction:column;
gap:20px;
width:100%;
@media(max-width:990px){
    align-items:center;
    justify-content:center;
    gap:15px;
}
`
const GroupTitle = styled.h1`
color:${colors.white};
font-size: 39px;
font-weight: 500;
line-height: 58.5px;

@media(max-width:990px){
    font-size: 22px;
    line-height: 38px;
    text-align: center;
}
`
const Subtitle = styled.p`
color:${colors.white};
font-size: 24.86px;
font-weight: 300;
line-height: 37.29px;
@media(max-width:990px){
   font-size: 20px;
    line-height: 30px;
        text-align: center;
}
`
const PrimaryBtn = styled.button`
display: flex;
align-items: center;
justify-content:center;
gap: 3px;
background: ${colors.purple};
color: ${colors.white};
font-size: 16px;
font-weight: 400;
line-height: 22px;
border: none;
border-radius: 10px;
padding: 10px 20px;
cursor: pointer;
width:fit-content;
svg {
  height: 20px;
  width: 20px;
}

&:disabled{
    cursor:inherit;
    opacity:0.3;
}
`;

const RowShowSmall = styled(Row)`
object-fit:cover;
margin-top:15px;
z-index:2;
@media(max-width:990px){
    display:flex;
}
svg{
    max-height:300px;
}
display:none;
`