import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, HashRouter } from "react-router-dom";
import './index.css';
import App from './App';
import AuthContextProvider from './Context/AuthContext';
import { CookiesProvider } from 'react-cookie';



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthContextProvider>
    <HashRouter>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <App />
      </CookiesProvider>
    </HashRouter>
  </AuthContextProvider>
);

