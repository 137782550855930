import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { colors } from '../../Assets/styles/colors';
import { ReactComponent as BackIcon } from "../../Assets/icons/arrowBack.svg";
import { localizationStrings } from '../../Localization/Localization';
import { FormControlLabel } from '@mui/material';
import { IOSSwitch } from '../GenericComponents/CustomSwitch';
import { ReactComponent as SearchIcon } from "../../Assets/icons/searchIcon.svg";
import BlockContactElement from './BlockContactElement';
import { useConversationService } from '../../Context/ConversationsContext';
import { useAuthService } from '../../Context/AuthContext';


const BlockUsers = ({ handleClose, setLoading, handleBlockContact, handleUnblockContact }) => {


    const { user, handleLoginUser } = useAuthService();
    const { getBlockedContacts, blockContact, unblockContact, blockUnknownContacts } = useConversationService();
    const [blockedContacts, setBlockedContacts] = useState([]);
    const [unblockedContacts, setUnblockedContacts] = useState([]);
    const [blockUnknownContacs, setBlockUnknownContacts] = useState(user.block_unknown_users)

    const [searchInput, setSearchInput] = useState("");

    const getContacts = () => {
        setLoading(true)
        getBlockedContacts(
            (response) => {
                const contacts = response.data.contacts
                contacts.sort((a, b) => a.name > b.name ? 1 : -1)
                let blocked = contacts.filter((item) => item.blocked)
                let unblocked = contacts.filter((item) => !item.blocked)
                setBlockedContacts(blocked)
                setUnblockedContacts(unblocked)
                setLoading(false)
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const searchMember = (list) => {
        if (searchInput === "") {
            return list
        }
        let found = list.filter((item) => item.name?.toLowerCase().includes(searchInput?.toLowerCase()))
        return found
    }

    const handleBlock = (contact) => {
        handleBlockContact(contact,
            () => {
                getContacts();
            }
        )
    }
    const handleUnblock = (contact) => {
        handleUnblockContact(contact,
            () => {
                getContacts();
            })
    }

    const handleBlockUnknownContacts = (block) => {
        blockUnknownContacts(
            block,
            (response) => {

                handleLoginUser(response.data.user)
                setBlockUnknownContacts(response.data.user.block_unknown_users)
            },
            (error) => {
                console.log(error)
                // setLoading(false)
            },
        )
    }


    useEffect(() => {
        getContacts()
    }, [])

    return (
        <BlockUsersContainer>
            <Container>
                <BackButton onClick={() => handleClose(false)}>
                    <BackIcon />
                    {localizationStrings.profile.back}
                </BackButton>
                <ProfileTitle>
                    <h1>{localizationStrings.profile.block_users}</h1>
                </ProfileTitle>
                <div
                    style={{ width: "100%", display: "flex", justifyContent: "end" }}
                ></div>

            </Container>
            <Group>
                <TitleWrapper>
                    <h1>{localizationStrings.profile.unknown_users}</h1>
                </TitleWrapper>
                <OptionWrapper>
                    <BlockUnknownUsers>
                        <p>{localizationStrings.profile.block_unknown_users}</p>
                        <FormControlLabel
                            style={{ margin: 0 }}
                            control={<IOSSwitch
                                checked={blockUnknownContacs}
                                onChange={(e) => handleBlockUnknownContacts(e.target.checked)}
                            />}
                        />
                    </BlockUnknownUsers>

                </OptionWrapper>
            </Group>
            <Group>
                <TitleWrapper>
                    <h1>{localizationStrings.new_chat.contacts}</h1>
                </TitleWrapper>
                <OptionWrapper>

                    <CustomSearch>
                        <SearchIcon />
                        <CustomInput
                            name='searchInput'
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.currentTarget.value)}
                        />
                    </CustomSearch>
                </OptionWrapper>
            </Group>
            {blockedContacts.length > 0 &&
                <Group>
                    <TitleWrapper>
                        <h1>{localizationStrings.block_contacts.blocked}</h1>
                    </TitleWrapper>
                    <OptionWrapper>
                        {searchMember(blockedContacts).map((contact) => (
                            <BlockContactElement
                                item={contact}
                                unblockContact={handleUnblock}
                                blocked={contact.blocked}
                            />
                        ))}
                    </OptionWrapper>
                </Group>
            }

            <Group>
                <TitleWrapper>
                    <h1>{localizationStrings.block_contacts.unblocked}</h1>
                </TitleWrapper>
                <OptionWrapper>
                    {searchMember(unblockedContacts).map((contact) => (
                        <BlockContactElement
                            item={contact}
                            blockContact={handleBlock}
                            blocked={contact.blocked}

                        />
                    ))}


                </OptionWrapper>
            </Group>
        </BlockUsersContainer>
    )
}

export default BlockUsers

const BlockUsersContainer = styled.div`
  background: ${colors.newChatModalBg};
  display: flex;
  flex-direction: column;
  padding: 24px 21px;
  gap: 25px;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const BackButton = styled.button`
  width: 100%;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  gap: 10px;
  display: flex;
  align-items: center;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.newChatModalBg};
  min-height: 38px;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    color: ${colors.optionsGroupTitle};
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
  }
`;
const ProfileTitle = styled.div`
width:100%;
  justify-content: center;
  display: flex;
  min-width:140px;
  h1 {
    color: ${colors.white};
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
`;
const BlockUnknownUsers = styled.div`
display:flex;
gap:10px;
align-items:center;
padding:10px 0;
p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: ${colors.white};
    width: 100%;
  }
`

const CustomSearch = styled.div`
background: ${colors.newChatModalInputBg};
padding: 10px 0;
border-radius: 10px;
display: flex;
align-items: center;
width: 100%;
gap:10px;

`
const CustomInput = styled.input`

    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color:${colors.white};
    background:none;
    width:100%;
    border:none;
    &:focus{
        outline:none;
    }

`