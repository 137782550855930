/* eslint-disable no-restricted-globals */

class EmotiiWebSocket {
  static instance = null;

  constructor() {
    if (EmotiiWebSocket.instance) {
      return EmotiiWebSocket.instance;
    }

    this.open = false;
    this.dataCallbacks = [];
    this.numberOfUnsucessfullReconnects = 0;

    EmotiiWebSocket.instance = this;
  }

  connect() {
    let endpoint = sessionStorage.getItem("wsLink");

    if (!endpoint) {
      return;
    }
    this.disconnect();
    this.ws = new WebSocket(endpoint);
    const self = this;
    // When the ws opens connect to the channel
    this.ws.onopen = () => {
      console.log("on open ");
      self.open = true;
      self.#connectToChannelsIfNeeded();
    };

    this.ws.onmessage = function (event) {
      self.#handleEvent(event);
    };

    // Reopen the socket if closed
    this.ws.onclose = function (e) {
      console.log("on close ");
      self.open = false;
      self.#handleSocketClosed();
    };
  }

  disconnect() {
    this.open = false;
    if (this.ws) {
      this.ws.close();
    }
  }

  subscribeToChannel(name, callback) {
    const item = { name: name, callback: callback };
    this.dataCallbacks.push(item);

    if (this.open === false) {
      return;
    }

    this.#connectToChannel(name);
  }

  sendMessage(channelName, dataObject) {

    if (this.open === false) {
      return;
    }

    let message = JSON.stringify(this.#dataMessage(channelName, dataObject));

    this.numberOfUnsucessfullReconnects = 0;
    this.ws.send(message);
  }

  // Private methods

  #connectToChannelsIfNeeded() {
    if (this.open === false) {
      return;
    }

    this.dataCallbacks.forEach((item, index) => {
      this.#connectToChannel(item.name);
    });
  }

  #connectToChannel(name) {
    if (this.open === false) {
      return;
    }
    console.log("connect message");
    let message = JSON.stringify(this.#connectMessage(name));

    this.numberOfUnsucessfullReconnects = 0;
    this.ws.send(message);
  }

  #connectMessage(channelName) {
    let message = {
      command: "subscribe",
      identifier: '{"channel": "' + channelName + '"}',
    };
    return message;
  }

  #handleEvent(event) {
    const json = JSON.parse(event.data);
    if (json.type === "ping") {
      return;
    }
    // console.log(json, "JSON");
    const identifier = json["identifier"];
    var channelName = null;
    if (identifier) {
      const channelNameJson = JSON.parse(identifier);
      channelName = channelNameJson["channel"];
    }

    if (channelName !== null && json.message) {
      this.#handleMessageForChannel(channelName, json.message);
    }
  }

  #handleMessageForChannel(channel, message) {
    const item = this.dataCallbacks.find((obj) => obj.name === channel);
    if (item) {
      item.callback(channel, message);
    }
  }

  #handleSocketClosed() {
    // Return if the flag is true
    if (this.open === false) {
      return;
    }

    var interval;
    // After more than 3 attems, try again in 1 min, otherwise the server will be flooded
    if (this.numberOfUnsucessfullReconnects > 3) {
      clearTimeout(interval);
      interval = setTimeout(() => {
        self.numberOfUnsucessfullReconnects = 0;
        console.log("reconnecting after delay");
        self.connect();
      }, 60 * 1000);
      return;
    }

    this.numberOfUnsucessfullReconnects += 1;
    console.log(self.numberOfUnsucessfullReconnects);
    console.log("reconnecting....");
    this.connect();
  }

  #dataMessage(channelName, dataObject) {
    let dataAsJsonString = JSON.stringify(dataObject);
    let message = {
      command: "message",
      identifier: '{"channel": "' + channelName + '"}',
      data: dataAsJsonString,
    };
    return message;
  }
}

export default EmotiiWebSocket;
