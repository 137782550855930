import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import "../../Assets/styles/customPhoneSelect.css"
import 'react-phone-number-input/style.css'
import { colors } from '../../Assets/styles/colors';
import { ReactComponent as NameIcon } from '../../Assets/icons/enterNameIcon.svg';
import { ReactComponent as LanguageIcon } from '../../Assets/icons/enterLanguageIcon.svg';
import { ReactComponent as GenderIcon } from '../../Assets/icons/enterGenderIcon.svg';
import { ReactComponent as BirthdayIcon } from '../../Assets/icons/enterBirthdayIcon.svg';
import { ReactComponent as CulturalIcon } from '../../Assets/icons/enterCulturalIcon.svg';
import SingleOptionMenu from './SingleOtionMenu';
import { genderList } from '../../Constants/DropdownValues';
import BirthdayMenu from './BirthdayMenu';
import { useAuthService } from '../../Context/AuthContext';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { notifyError } from '../../Helpers/Notifications';
import { localizationStrings } from '../../Localization/Localization';
import LanguageMenu from './LanguageMenu';


const CreateNewUser = ({ countryCode }) => {

    const branch = require("branch-sdk")

    const [infoCounter, setInfoCounter] = useState(1);
    const [name, setName] = useState("");
    const [language, setLanguage] = useState("");
    const [culturalBg, setCulturalBg] = useState("");
    const [gender, setGender] = useState("");
    const [birthday, setBirthday] = useState(new Date());
    const [branchData, setBranchData] = useState(null)

    const { createUser, translationsLanguages, pushToken } = useAuthService();
    const navigate = useNavigate();

    const handleContinue = () => {
        setInfoCounter(infoCounter + 1)
    }

    const handleNameChange = (e) => {
        const { value } = e.currentTarget;
        setName(value);
    }

    const handleCulturalBgChange = (e) => {
        const { value } = e.currentTarget;
        setCulturalBg(value);
    }

    const handleDontShareBirthday = () => {
        setBirthday("")
        handleContinue();
    }

    const handleDontWantShareCultural = () => {
        setCulturalBg("")
        handleContinue()
    }

    const handleDontWantShareGender = () => {
        setGender("")
        handleContinue();
    }

    const getBranchData = () => {
        branch.data((err, data) => {
            setBranchData(data)
        });
    }


    const handleCreateUser = (
        name,
        birthdate,
        language,
        gender,
        cultural_background,
        countryCode
    ) => {
        let date = null;
        const dataObject = {
            name: name,
            language: language,
            country_code: countryCode,
        }

        if (pushToken) {
            dataObject.push_token_platform = "web"
            dataObject.push_token = pushToken
        }

        if (birthdate) {
            date = moment(birthdate).format("yyyy-MM-DD");
        }

        if (gender !== "") {
            dataObject.gender = gender
        }

        if (cultural_background !== "") {
            dataObject.cultural_background = cultural_background
        }

        if (date != null) {
            dataObject.birthdate = date
        }

        if (branchData && branchData?.data_parsed?.invited_by) {
            dataObject.invited_by = branchData.data_parsed.invited_by
        }

        createUser(
            dataObject,
            function (response) {
                setInfoCounter(1);
                navigate("/");
            },
            function (error) {
                notifyError(`${localizationStrings.profile.something_wrong}`)
                setInfoCounter(1);
                navigate("/")
                console.log(error);
            }
        )
    }
    const calculateUploadProgress = (uploadedFiles, totalFiles) => {
        return (uploadedFiles * 100) / totalFiles;
    }
    useEffect(() => {
        if (infoCounter === 6) {
            handleCreateUser(name, birthday, language, gender, culturalBg, countryCode)
        }
    }, [infoCounter])

    useEffect(() => {
        getBranchData()
    }, [])

    return (
        <Container>
            <ProgressBarWrapper>
                <ProgressBar completed={calculateUploadProgress(infoCounter, 5)} />
            </ProgressBarWrapper>
            <LeftSide>
                {infoCounter === 1 && <Card>
                    <GroupTitle>
                        <h1>1. {localizationStrings.register.your_name}</h1>
                        <p>{localizationStrings.register.name_details}</p>
                    </GroupTitle>
                    <Group>
                        <CustomInputWrapper>
                            <input
                                placeholder={localizationStrings.register.name_placeholder}
                                onChange={(e) => handleNameChange(e)}
                                value={name}
                                name='name'
                                maxLength={100}
                            />

                        </CustomInputWrapper>

                    </Group>
                    <Group>
                        <LoginButton
                            disabled={name.length < 3}
                            id='name-continue-button'
                            onClick={(e) => handleContinue(e)}>
                            {localizationStrings.register.next_button}
                        </LoginButton>
                    </Group>
                </Card>}
                {infoCounter === 2 && <Card>
                    <GroupTitle>
                        <h1>2. {localizationStrings.register.chat_language}</h1>
                        <p>{localizationStrings.register.language_details}</p>
                    </GroupTitle>
                    <Group>
                        <LanguageMenu
                            value={language}
                            setValue={setLanguage}
                            valuesList={translationsLanguages}
                        />
                    </Group>
                    <Group>
                        <LoginButton
                            disabled={language === ""}
                            id='language-continue-button'
                            onClick={(e) => handleContinue(e)}>
                            {localizationStrings.register.next_button}
                        </LoginButton>
                    </Group>
                </Card>}
                {infoCounter === 3 && <Card>
                    <GroupTitle>
                        <h1>3. {localizationStrings.register.birthdate}</h1>
                        <p>{localizationStrings.register.birthdate_details}</p>
                    </GroupTitle>
                    <Group>
                        <BirthdayMenu
                            value={birthday}
                            setValue={setBirthday}
                        />
                    </Group>
                    <Group>
                        <LoginButton
                            disabled={language === ""}
                            id='birthday-continue-button'
                            onClick={(e) => handleContinue(e)}>
                            {localizationStrings.register.next_button}
                        </LoginButton>
                    </Group>
                    <Group>
                        <DontWantShare>
                            <p onClick={() => handleDontShareBirthday()}>{localizationStrings.register.dont_want_to_share}</p>
                        </DontWantShare>

                    </Group>
                </Card>}
                {infoCounter === 4 && <Card>
                    <GroupTitle>
                        <h1>4. {localizationStrings.register.gender}</h1>
                        <p>{localizationStrings.register.gender_details}</p>
                    </GroupTitle>
                    <Group>
                        <SingleOptionMenu
                            value={gender}
                            setValue={setGender}
                            valuesList={genderList}
                        />
                    </Group>
                    <Group>
                        <LoginButton
                            disabled={gender === ""}
                            id='gender-continue-button'
                            onClick={(e) => handleContinue(e)}>
                            {localizationStrings.register.next_button}
                        </LoginButton>
                    </Group>
                    <Group>
                        <DontWantShare>
                            <p onClick={() => handleDontWantShareGender()}>{localizationStrings.register.dont_want_to_share}</p>
                        </DontWantShare>
                    </Group>
                </Card>}
                {infoCounter === 5 && <Card>
                    <GroupTitle>
                        <h1>5. {localizationStrings.register.cultural_background}</h1>
                        <p>{localizationStrings.register.cultural_background_details}</p>
                    </GroupTitle>
                    <Group>
                        <CustomInputWrapper>
                            <input
                                placeholder={localizationStrings.profile.type_cultural_background}
                                onChange={(e) => handleCulturalBgChange(e)}
                                value={culturalBg}
                                name='culturalBg'
                                maxLength={1000}
                            />

                        </CustomInputWrapper>

                    </Group>
                    <Group>
                        <LoginButton
                            disabled={culturalBg === ""}
                            id='cultural-continue-button'
                            onClick={(e) => handleContinue(e)}>
                            {localizationStrings.register.next_button}
                        </LoginButton>
                    </Group>
                    <Group>
                        <DontWantShare>
                            <p onClick={() => handleDontWantShareCultural()}>{localizationStrings.register.dont_want_to_share}</p>
                        </DontWantShare>
                    </Group>
                </Card>}

            </LeftSide>
            <RightSide>
                {infoCounter === 1 && <NameIcon />}
                {infoCounter === 2 && <LanguageIcon />}
                {infoCounter === 3 && <BirthdayIcon />}
                {infoCounter === 4 && <GenderIcon />}
                {infoCounter === 5 && <CulturalIcon />}

            </RightSide>


        </Container>
    )
}

export default CreateNewUser
const Container = styled.div`
display:flex;
max-height:100vh;
height:100%;
min-height:600px;
width:100%;
align-items:center;
overflow:auto;
position:relative;
`
const Card = styled.div`
display:flex;
flex-direction:column;
max-width:400px;
width:100%;
gap:20px;
`
const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
h1{
color:${colors.white};
font-size: 32px;
font-weight: 600;
line-height: 39px;
letter-spacing: 0px;
text-align: left;
}
p{
color:#FFFFFF80;
font-size: 14px;
font-weight: 400;
line-height: 17px;
letter-spacing: 0px;
text-align: left;
}


`;
const CustomInputWrapper = styled.div`
 display: flex;
 width:100%;
input {
     width:100%;
    padding: 14px 16px;
    color: ${colors.white};
    background: ${colors.newChatModalInputBg};
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    &:focus {
      outline: none;
    }
  }
`
const GroupTitle = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
h1{
    color:${colors.white};
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0px;
    text-align: left;
}
    p{
    color:#FFFFFF80;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    }
`

const LoginButton = styled.button`
display: flex;
align-items: center;
justify-content:center;
gap: 3px;
background: ${colors.purple};
color: ${colors.white};
font-size: 16px;
font-weight: 600;
line-height: 22px;
text-align: center;
border: none;
border-radius: 10px;
padding: 14px 15px;
cursor: pointer;
svg {
  height: 20px;
  width: 20px;
}
&:disabled{
    cursor:inherit;
    opacity:0.3;
}
`;
const LeftSide = styled.div`
width:100%;
height:100%;
display:flex;
align-items:center;
justify-content:center;
padding:0 32px;

`
const RightSide = styled(LeftSide)`
`
const DontWantShare = styled.div`
width:100%;
display:flex;
justify-content:center;

p{
    cursor:pointer;
color:${colors.purple};
font-size: 16px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0px;


}
`
const ProgressBarWrapper = styled.div`
position:absolute;
width:100%;
top:0;
left:0;
background:#403E7D;
`
const ProgressBar = styled.div`
height:2px;
background:#7069FD;
width: ${(props) => props.completed}%;
transition: 200ms all linear;
`