class LocalStorage {
    static setAuthenthicationToken(access_token) {
        localStorage.setItem("access_token", access_token);
    }

    static getAuthenticationToken() {
        return localStorage.getItem("access_token");
    }

    static setRefreshToken(refresh_token) {
        localStorage.setItem("refresh_token", refresh_token);
    }
    static removeRefreshToken() {
        localStorage.removeItem("refresh_token")
    }
    static removeAuthenticationToken() {
        localStorage.removeItem("access_token")
    }

    static getRefreshToken() {
        return localStorage.getItem("refresh_token");
    }

    static setAppLanguage(app_language) {
        localStorage.setItem("emotii_language", app_language);
    }

    static getAppLanguage() {
        return localStorage.getItem("emotii_language");
    }

    static setWsLink(websocket_link) {
        localStorage.setItem("websocket_link", websocket_link);

    }

    static getWsLink() {
        return localStorage.getItem("websocket_link");
    }

    static setQcWsLink(websocket_link) {
        localStorage.setItem("qc_websocket_link", websocket_link);
    }

    static getQcWsLink() {
        return localStorage.getItem("qc_websocket_link");
    }

    static setRatingCounter(counter) {
        localStorage.setItem("rating_counter", counter);
    }
    static getRatingCounter() {
        return localStorage.getItem("rating_counter");
    }



}

export default LocalStorage;
