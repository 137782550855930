import { POST } from "../API"

export const logoutUserAPICAll = (push_token, onSuccess, onError) => {
    const data = {
    }

    if (push_token) {
        data.push_token = push_token
    }

    POST("/api/v1/identity/users/sign_out", data, onSuccess, onError, true)
}