/* eslint-disable no-restricted-globals */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getMessaging, onMessage } from "firebase/messaging";
import requestPermission from "./Helpers/RequestMessagingPermision";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBq-wzxjryxhwlGmEbPKX57AuVTzvk-K7g",
    authDomain: "emotii-9f976.firebaseapp.com",
    projectId: "emotii-9f976",
    storageBucket: "emotii-9f976.appspot.com",
    messagingSenderId: "35997610750",
    appId: "1:35997610750:web:680e2bd1f468a1f0cdabaf",
    measurementId: "G-BX8Z7GXZFV"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const messaging = getMessaging(app)

