import React from 'react'
import styled from 'styled-components';
import { colors } from '../../Assets/styles/colors';
import { displayAvatar } from '../../Helpers/chatHelper/AvatarHelper';
import { displayName } from '../../Helpers/chatHelper/NameHelper';
import ChatClosedInfo from '../QuickChat/ChatClosedInfo';
import { ReactComponent as CallIcon } from "../../Assets/icons/calls/callIcon.svg"
import { isSimpleAndBlockedConversation } from '../../Helpers/chatHelper/ConversationHelper';
const ConversationHeader = ({ selectedConversation, onClick, handleCreateCall }) => {

  const getGroupMembers = (groupMembers) => {
    let members = groupMembers.map((member) => { return member.name })
    members.sort((a, b) => a > b ? 1 : -1)
    return members.join(", ")
  }



  return (
    <Container>
      <Container onClick={() => onClick(selectedConversation)}>
        {selectedConversation &&
          <Wrapper $closed={!selectedConversation.open && selectedConversation.quick_chat}>
            <UserAvatar style={selectedConversation.quick_chat ? { border: "none" } : {}}>
              {displayAvatar(selectedConversation)}
            </UserAvatar>
            <Text>
              <h1>{displayName(selectedConversation)}</h1>
              {selectedConversation.group &&
                <h2>{getGroupMembers(selectedConversation.other_participants)}</h2>}
            </Text>
          </Wrapper>
        }

        {!selectedConversation.open && selectedConversation.quick_chat &&
          <InfoWrapper>
            <ChatClosedInfo />
          </InfoWrapper>
        }
      </Container>

      {!selectedConversation.group && !selectedConversation.quick_chat && !selectedConversation.with_emotii && !isSimpleAndBlockedConversation(selectedConversation) &&
        <InfoWrapper>
          <CallBtn onClick={() => handleCreateCall(selectedConversation)}>
            <CallIcon />
          </CallBtn>
        </InfoWrapper>
      }



    </Container>
  )
}

export default ConversationHeader
const Container = styled.div`
display:flex;
width:100%;
`
const Wrapper = styled.div`
display:flex;
align-items:center;
gap:15px;
padding: 16px;
min-height:74px;
cursor:pointer;
max-height:74px;
overflow:hidden;
opacity:${props => props.$closed ? "0.5" : ""};
`
const UserAvatar = styled.div`
  display: flex;
  align-items: center;
justify-content: center;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  cursor: pointer;
  max-height: 42px;
  max-width: 42px;
  object-fit: contain;
  position: relative;
  min-width: 42px;
  min-height:42px;
  overflow:hidden;
    svg {
    position: relative;
    width: 100%;
    height: 100%;
  }
  img{
    object-fit:cover;
 position: relative;
       height:43px;
    width:43px;
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
overflow:hidden;
width:100%;
h1{

    color:${colors.white};
font-size: 20px;
font-weight: 600;
line-height: 30px;
text-align: left;
}

h2{
width:100%;
  overflow:hidden;
  max-height:20px;
white-space: nowrap;
    text-overflow: ellipsis;
  color:${colors.white};
  opacity:0.4;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}
`;
const InfoWrapper = styled.div`
display:flex;
margin-left:auto;
align-items:center;
padding:0 20px;
`
const CallBtn = styled.button`
display:flex;
align-item:center;
justify-content:center;
border:none;
background:none;
border-radius:20px;
cursor:pointer;
svg{
width:22px;
height:22px;
}
`