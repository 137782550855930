import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Neutral } from "../../Assets/icons/messageMood/neutral.svg"
import { ReactComponent as Romantic } from "../../Assets/icons/messageMood/romantic.svg"
import { ReactComponent as Humorous } from "../../Assets/icons/messageMood/humorous.svg"
import { ReactComponent as Casual } from "../../Assets/icons/messageMood/casual.svg"
import { ReactComponent as Supportive } from "../../Assets/icons/messageMood/supportive.svg"
import { ReactComponent as Business } from "../../Assets/icons/messageMood/business.svg"
import { ReactComponent as Formal } from "../../Assets/icons/messageMood/formal.svg"
import { BUSINESS_MOOD, CASUAL_MOOD, FORMAL_MOOD, HUMOROUS_MOOD, NEUTRAL_MOOD, ROMANTIC_MOOD, SUPPORTIVE_MOOD } from '../../Constants/constantValues'
import { colors } from '../../Assets/styles/colors'

const MessageMood = ({ mood, onClick }) => {

    const displayMoodIcon = () => {
        let returnMood = <Neutral />
        switch (mood) {
            case ROMANTIC_MOOD:
                returnMood = <Romantic />
                break;
            case HUMOROUS_MOOD:
                returnMood = <Humorous />
                break;
            case CASUAL_MOOD:
                returnMood = <Casual />
                break;
            case SUPPORTIVE_MOOD:
                returnMood = <Supportive />
                break;
            case BUSINESS_MOOD:
                returnMood = <Business />
                break;
            case FORMAL_MOOD:
                returnMood = <Formal />
                break;
            default:
                break;
        }

        return returnMood
    }

    return (
        <Container>
            <CustomButton onClick={onClick}>
                {displayMoodIcon()}
            </CustomButton>


        </Container>
    )
}

export default MessageMood
const Container = styled.div`
display:flex;
align-items:center;
justify-content:center;

`
const CustomButton = styled.button`
display:flex;
align-items:center;
justify-content:center;
border:none;
background:none;
cursor:pointer;
color:${colors.purple};
opacity:0.7;
`