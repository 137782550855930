import React from 'react'
import "../Assets/styles/notFound.css"
const NotFound = () => {
    return (
        <div id='notfound'>
            <div className='notfound'>
                <h1>Oops!</h1>
                <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            </div>
        </div>

    )
}

export default NotFound