import { POST } from "../API"
let source;

export const addRatingAPI = (conversation_id, rating, onSuccess, onError) => {
    const data = {
        conversation_id: conversation_id,
        rating: rating

    }
    source = POST(`/api/v1/chat/ratings/add_rating`, data, onSuccess, onError, true, source)
}