import React, { useEffect } from 'react'
import ReactGA from "react-ga4";
import { useAuthService } from '../../Context/AuthContext';

const InitializeAnalytics = () => {
    const TRACKING_ID = "G-BX8Z7GXZFV";
    useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
    }, [])

}

export default InitializeAnalytics