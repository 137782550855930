export const colors = {
    background: "#222531",
    chatBg: "#171719",
    chatElementBg: "#1C1F26",
    chatElementBgSelected: "#5852D633",
    chatElementColor: "#EBEBF5CC",
    chaetElementBgSelected: "#5852D633",
    white: "#FFFFFF",
    purple: "#7069FD",
    darkPurple: "#5A54CC",
    validGreen: "rgba(112, 203, 151, 1)",
    fadedPurple: "rgba(87, 91, 127, 1)",

    userMenuHeaderBg: "#21242E",
    chatsListBg: "rgba(35, 37, 49, 1)",
    receivedMessageBg: "#333546",
    receivedReplyMessageBg: "#292A39",
    errorColor: "#EE5050",
    newChatModalBg: "rgba(26, 29, 36, 1)",
    newChatModalInputBg: "#30323A",
    optionsGroupTitle: "#757575",
    messageNotRead: "#5B5B5D",
    newButtonOptionsBg: "rgba(48, 50, 58, 1)",

    quickChatBg: "rgba(23, 23, 25, 1)",
    quickChatClosedBg: "rgba(45, 46, 59, 1)",
    landingPage: {
        darkBg: "rgba(32, 35, 43, 1)",
        semiDarkBg: "rgba(50, 52, 68, 1)",
        lightColor: "rgba(242, 242, 242, 1)",
        darkColor: "rgba(32, 35, 43, 1)",
        purple: "rgba(112, 105, 253, 1)",
    },

    controlBtn: {
        inactiveBg: "rgba(24, 29, 36, 1)",
        activeBg: "#FFFFFF",
        inactiveColor: "#FFFFFF",
        activeColor: "rgba(31, 35, 43, 1)"

    }
}