import {
    createContext,
    useContext,
    useCallback,
    useState,
    useRef,
} from "react";
import { addMessageQuickChatAPICall, addReplyMessageQuickChatAPICall, editMessageQuickChatAPICall, getMessagesQuickChatAPICall, getQuickChatAPICall, joinQuickChatAPICall, markAsReadQuickChatAPICall, reactToMessageQuickChatAPICall } from "../API/quickChatNoAuth.js/quickChatNoAuthService";


export const QuickChatContext = createContext({
    quickChatRef: [],
    quickChat: [],
    anonymousUserExist: [],
    getQuickChat: () => { },
    joinQuickChat: () => { },
    getMessagesQuickChat: () => { },
    addMessagesQuickChat: () => { },
    editMessageQuickChat: () => { },
    replyMessageQuickChat: () => { },
    reactMessageQuickChat: () => { },
    markAsReadMessageQuickChat: () => { },

});

const QuickChatContextProvider = ({ children }) => {

    const [quickChat, setQuickChat] = useState(undefined);
    const [anonymousUserExist, setAnonymousUserExist] = useState(false);
    const quickChatRef = useRef();

    const getQuickChat = useCallback(
        async (hashId, onSuccess, errorCallback) => {
            try {
                await getQuickChatAPICall(
                    hashId,
                    function (response) {
                        quickChatRef.current = response.data.conversation;
                        let anonUser = checkQcUser(quickChatRef.current)
                        setAnonymousUserExist(anonUser)
                        setQuickChat(quickChatRef.current);
                        onSuccess(response);
                    },
                    function (error) {
                        if (error.response.status == 404) {
                            if (error.response.data.message === "Conversation not open") {
                                quickChatRef.current = null;
                                setQuickChat(null)
                            }
                        }
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const joinQuickChat = useCallback(
        async (hashId, name, language, onSuccess, errorCallback) => {
            try {
                await joinQuickChatAPICall(
                    hashId,
                    name,
                    language,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const getMessagesQuickChat = useCallback(
        async (chatId, pageId, onSuccess, errorCallback) => {
            try {
                await getMessagesQuickChatAPICall(
                    chatId, pageId,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const addMessagesQuickChat = useCallback(
        async (data, chatId, onSuccess, errorCallback) => {
            try {
                await addMessageQuickChatAPICall(
                    data,
                    chatId,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const editMessageQuickChat = useCallback(
        async (chat_id, message_id, message, onSuccess, errorCallback) => {
            try {
                await editMessageQuickChatAPICall(
                    chat_id, message_id, message,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const replyMessageQuickChat = useCallback(
        async (data, chat_id, message_id, onSuccess, errorCallback) => {
            try {
                await addReplyMessageQuickChatAPICall(
                    data, chat_id, message_id,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const reactMessageQuickChat = useCallback(
        async (reaction, chat_id, message_id, onSuccess, errorCallback) => {
            try {
                await reactToMessageQuickChatAPICall(
                    reaction, chat_id, message_id,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );
    const markAsReadMessageQuickChat = useCallback(
        async (chat_id, message_id, onSuccess, errorCallback) => {
            try {
                await markAsReadQuickChatAPICall(
                    chat_id, message_id,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );


    const checkQcUser = (conversation) => {
        let isUserRegistered = false;
        if (conversation.anonymous_users.length > 0) {
            isUserRegistered = true
        }
        return isUserRegistered
    }

    return (
        <QuickChatContext.Provider
            value={{
                quickChatRef,
                quickChat,
                getQuickChat,
                anonymousUserExist,
                joinQuickChat,
                getMessagesQuickChat,
                addMessagesQuickChat,
                editMessageQuickChat,
                replyMessageQuickChat,
                reactMessageQuickChat,
                markAsReadMessageQuickChat

            }}
        >
            {children}
        </QuickChatContext.Provider>
    );
};

export const useQuickChatService = () => useContext(QuickChatContext);

export default QuickChatContextProvider;
