
export const isSimpleAndBlockedConversation = (conversation) => {
    if (!conversation) {
        return
    }
    if (conversation.other_participants.length == 1) {
        if (conversation.other_participants[0].blocked) {
            return true
        } else {
            return false
        }

    } else {
        return false
    }
}