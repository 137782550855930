import React from "react";
import styled from "styled-components";
import { Menu, Item } from "react-contexify";

import "react-contexify/dist/ReactContexify.css";
import { colors } from "../../Assets/styles/colors";
import { localizationStrings } from "../../Localization/Localization";
import { ReactComponent as ForwardIcon } from "../../Assets/icons/context-menu-icons/ForwardIcon.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/icons/context-menu-icons/DeleteIcon.svg";
import { ReactComponent as CopyIcon } from "../../Assets/icons/context-menu-icons/CopyIcon.svg";
import { ReactComponent as ReplyIcon } from "../../Assets/icons/context-menu-icons/ReplyIcon.svg";
import { ReactComponent as EditIcon } from "../../Assets/icons/context-menu-icons/EditIcon.svg";
import { ReactComponent as ReportIcon } from "../../Assets/icons/context-menu-icons/ReportIcon.svg";
import { ReactComponent as InfoIcon } from "../../Assets/icons/context-menu-icons/questionMarkIcon.svg";

const CustomItem = ({ text, icon, redButton }) => {
  return (
    <CustomItemContainer redButton={redButton}>
      <p>{text}</p>
      {icon}
    </CustomItemContainer>
  );
};

const buttonList = [
  {
    id: "reply",
    text: localizationStrings.chat.reply,
    icon: <ReplyIcon />,
  },
  {
    id: "copy",
    text: localizationStrings.chat.copy,
    icon: <CopyIcon />,
  },

  {
    id: "edit",
    text: localizationStrings.chat.edit,
    icon: <EditIcon />,
  },

  {
    id: "forward",
    text: localizationStrings.chat.forward,
    icon: <ForwardIcon />,
  },
  {
    id: "report",
    text: localizationStrings.chat.report,
    icon: <ReportIcon />,
  },
  {
    id: "info",
    text: localizationStrings.chat.info,
    icon: <InfoIcon />,
  },
  {
    id: "delete",
    text: localizationStrings.chat.delete,
    icon: <DeleteIcon />,
    redButton: true,
  },
];

const RightClickMenu = ({
  handleDeleteMessage,
  contextMenuItem,
  handleReportMessage,
  handleForwardMessage,
  handleEditMessageOption,
  handleReplyMessageOption,
  selectedConversation,
  user,
  conversations,
  handleCopyOption,
  currentConversationMessages,
  handleInfoOption,
}) => {
  function handleItemClick({ id }) {
    switch (id) {
      case "delete":
        handleDeleteMessage(
          contextMenuItem.conversationId,
          contextMenuItem.messageId
        );
        break;
      case "report":
        handleReportMessage(
          contextMenuItem.conversationId,
          contextMenuItem.messageId
        );
        break;
      case "forward":
        handleForwardMessage();
        break;
      case "edit":
        handleEditMessageOption();
        break;
      case "reply":
        handleReplyMessageOption();
        break;
      case "copy":
        handleCopyOption(contextMenuItem?.messageObject);
        break;
      case "info":
        handleInfoOption(contextMenuItem?.messageObject);
        break;

      default:
        break;
    }
  }

  const chefIfUserIsAnonymous = () => {
    if (!contextMenuItem) {
      return;
    }
    if (!contextMenuItem.messageObject) {
      return;
    }

    if (!selectedConversation) {
      return;
    }
    let isUserAnonymous = user.phone_number === ""
    return isUserAnonymous

  }

  const checkIfLastMessage = () => {
    if (!contextMenuItem) {
      return;
    }

    if (!contextMenuItem.messageObject) {
      return;
    }

    if (!selectedConversation) {
      return;
    }

    let myMessages = [];

    currentConversationMessages.forEach((element) => {
      if (element.user_id === user.id) {
        myMessages.push(element);
      }
    });

    const lastMessageFromList = myMessages.pop();

    if (contextMenuItem.messageObject.id === lastMessageFromList?.id) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfIsMymessage = () => {
    if (!contextMenuItem) {
      return;
    }

    if (!contextMenuItem.messageObject) {
      return;
    }

    if (!selectedConversation) {
      return;
    }
    let isMyMessage = user.id === contextMenuItem?.messageObject?.user_id;
    return isMyMessage;
  };


  const filterButtons = (buttons) => {
    let availableOptions = buttons;
    if (!contextMenuItem) {
      return availableOptions;
    }

    if (!contextMenuItem.messageObject) {
      return availableOptions;
    }

    let lastMessage = checkIfLastMessage();
    let myMessage = checkIfIsMymessage();
    let quickChat = selectedConversation.quick_chat;
    let anonymousUser = chefIfUserIsAnonymous()

    if (contextMenuItem.messageObject?.deleted) {
      availableOptions = buttons.filter((item) => item.id == "report");
    }

    if (contextMenuItem.messageObject?.image) {
      availableOptions = buttons.filter((item) => item.id != "copy");
    }

    if (!lastMessage) {
      availableOptions = availableOptions.filter((item) => item.id != "edit");
    }

    if (myMessage) {
      availableOptions = availableOptions.filter((item) => item.id != "reply");
    }
    if (!myMessage || anonymousUser) {
      availableOptions = availableOptions.filter((item) => item.id != "info");
    }

    if (quickChat) {
      availableOptions = availableOptions.filter(
        (item) => item.id != "forward" && item.id != "delete"
      );
    }

    return availableOptions;
  };

  return (

    <StyledMenu id={"menu-id"} className="styledMenu">
      {contextMenuItem?.messageObject?.mood && checkIfIsMymessage() &&
        <ComplementaryMessageInfoWrapper>
          <ComplementaryMessageInfoElement>
            {localizationStrings.context_menu.message_mood_info_text}{" "}
            <span>{contextMenuItem?.messageObject?.mood}</span>
          </ComplementaryMessageInfoElement>
        </ComplementaryMessageInfoWrapper>
      }
      {filterButtons(buttonList).map((item, index) => (
        <Item onClick={handleItemClick} id={item.id} key={index}>
          <CustomItem
            text={item.text}
            icon={item.icon}
            redButton={item.redButton}
          />
        </Item>
      ))}
    </StyledMenu>
  );
};

export default RightClickMenu;

const ComplementaryMessageInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(50, 52, 68, 0.7);
  position: relative;
  backdrop-filter: blur(10px);
  padding: 10px 16px;
  border-radius: 12px 12px 0 0;
  width:100%;
min-width:265px;
`;
const ComplementaryMessageInfoElement = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: ${colors.white};

  span {
    color: ${colors.purple};
    font-weight: 600;
    text-transform:capitalize;
  }
`;

const CustomItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${(props) => (props.redButton ? colors.errorColor : "white")};
  svg {
    height: 20px;
    width: 20px;
  }
`;

const StyledMenu = styled(Menu).attrs({
  // custom props
})`
overflow:hidden;

  background: transparent;
  color: white;
  padding: 0;
  border-radius: 12px;
  .contexify_item {
    background: rgba(50, 52, 68, 1);
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
    padding: 6px 10px;
    &:last-child {
      border-bottom: none;
      border-radius: 0 0 12px 12px;
    }
  }
  .contexify_itemContent {
    color: ${colors.errorColor};
  }
  .contexify_item:not(.contexify_item-disabled):hover > .contexify_itemContent,
  .contexify_item:not(.contexify_item-disabled):focus > .contexify_itemContent {
    color: inherit;
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
