import { ReactComponent as AvatarUnset } from "../../Assets/icons/unsetAvatar.svg";
import { ReactComponent as LogoColor } from "../../Assets/icons/logo-color.svg";
import { ReactComponent as QcIcon } from "../../Assets/icons/quickChat/QuickChatIcon.svg";

export const displayAvatar = (data) => {
    if (!data) {
        return <AvatarUnset />
    }

    let displayAvatar = <></>
    let profilePicture = data?.other_participants[0]?.profile_picture;

    if (data.group != null) {
        profilePicture = data.group?.image
    }

    if (data.with_emotii) {
        displayAvatar = <LogoColor />
    } else {
        if (data.quick_chat) {
            displayAvatar = <QcIcon />
        }
        else
            if (profilePicture) {
                displayAvatar = <img src={profilePicture} alt="avatar" />
            } else {
                displayAvatar = <AvatarUnset />
            }

    }

    return displayAvatar
}