import LocalizedStrings from "react-localization";
import { EN } from "./EN";
import { RO } from "./RO";


export const allAppLanguages = [EN, RO]

export const languages = {}
for (let index = 0; index < allAppLanguages.length; index++) {
    const element = allAppLanguages[index];
    languages[element.language_code] = element
}

export const localizationStrings = new LocalizedStrings(languages)

