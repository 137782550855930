import React from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { ReactComponent as BellIcon } from "../../Assets/icons/bell.svg";
import NewChatButton from "./NewChatButton";

const RequestNotificationsModal = ({
  handleClose,
  handleRequestNotificationsPermission,
}) => {
  if (window.isElectron) {
    return
  }
  return (
    <FullScreenItem>
      <Card>
        <Line style={{ alignItems: "leading" }}>
          <BellIcon />
          <Title>Notifications</Title>
        </Line>

        <Line>
          <InfoText>
            Would you like to enable push notifications for emotii? You will be
            notified of new messages and calls.
          </InfoText>
        </Line>
        <Line>
          <CustomBtn outlined onClick={() => handleClose()}>
            Cancel
          </CustomBtn>
          <CustomBtn onClick={() => handleRequestNotificationsPermission()}>
            Enable
          </CustomBtn>
        </Line>
      </Card>
    </FullScreenItem>
  );
};

export default RequestNotificationsModal;

const FullScreenItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  z-index: 1;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  max-width: 500px;
  width: 100%;
  background: ${colors.newChatModalBg};
  border-radius: 8px;
  padding: 30px;
  align-items: center;
  position: relative;
  gap: 20px;
`;
const Line = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  svg {
    max-height: 40px;
    width: fit-content;
  }
`;
const InfoText = styled.h1`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${colors.chatElementColor};
`;
const Title = styled.h1`
  margin-top: 6px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: ${colors.chatElementColor};
`;

const CustomBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${colors.white};
  border-radius: 10px;
  border: none;
  background: ${(props) => (props.outlined ? "none" : colors.darkPurple)};
  padding: 14px;
  cursor: pointer;
  border: 1px solid ${colors.darkPurple};
  &:hover {
    background: ${(props) =>
    props.outlined ? colors.chatsListBg : colors.purple};
    border: 1px solid ${colors.purple};
  }
`;
