import React, { useEffect, useReducer, useRef, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as ReactionIcon } from '../../Assets/icons/ReactionIcon.svg'
import { ReactionBarSelector } from '@charkour/react-reactions';
import { handleReceivedMessages } from '../../Helpers/chatHelper/ChatHelper';
import { reactionButtonEmojiItems } from '../../Constants/emojiList';
const ReactionButton = ({ id, handleReactToMessage, messageId, conversationId, setShowReactionButton }) => {

    const ref = useRef();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    var letftDistance = 185;

    const handleOpen = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    if (ref.current) {
        letftDistance = ref.current.offsetLeft;
    }

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);


    return (
        <Container ref={ref} id={id}>
            <ReactionBtn onClick={() => handleOpen()}>
                <ReactionIcon />
            </ReactionBtn>
            {isMenuOpen &&
                <ReactionsContainer letftdistance={letftDistance}>
                    <ReactionBarSelector
                        iconSize={20}
                        style={{
                            zIndex: 2,
                            background: colors.receivedMessageBg
                        }}
                        reactions={reactionButtonEmojiItems}
                        onSelect={(e) => handleReactToMessage(e, conversationId, messageId, () => {
                            setShowReactionButton(false)
                        })}
                    />
                </ReactionsContainer>}


        </Container>
    )
}

export default ReactionButton
const Container = styled.div`
position:absolute;
right:-50px;
`
const ReactionBtn = styled.div`
display:flex;
justify-content:center;
align-items:center;
background:${colors.receivedMessageBg};
width:42px;
height:42px;
border-radius:100%;
border:none;
cursor:pointer;
`
const ReactionsContainer = styled.div`
display:flex;
align-items:center;
justify-content:center;
position:absolute;
top:-40px;
right:${(props) => props.letftdistance < 184 ? props.letftdistance : 0};
border-radius:100px;
background:${colors.receivedMessageBg};
`