import React from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { localizationStrings } from "../../Localization/Localization";
import { useAuthService } from "../../Context/AuthContext";
import TimeAgo from "timeago-react";
import { displayAvatar } from "../../Helpers/chatHelper/AvatarHelper";
import { displayName } from "../../Helpers/chatHelper/NameHelper";
import { findTranslatedTextForUser } from "../../Helpers/chatHelper/ChatHelper";

const ChatListElement = ({ handleSelectConversation, data, selected, displayMenu }) => {

  const { user } = useAuthService();

  const handleLastMessage = (last_message) => {
    if (!last_message) {
      return
    }
    if (last_message.image) {
      return (localizationStrings.chat.image)
    }

    if (last_message.document) {
      return (localizationStrings.chat.file)
    }

    if (last_message.image) {
      return (localizationStrings.chat.image)
    }
    if (last_message.video) {
      return (localizationStrings.chat.video)
    }
    else {
      let translatedForCurrentUser = findTranslatedTextForUser(last_message.message_translations, user)
      if (!data.group) {
        if (last_message.deleted) {
          return (localizationStrings.chat.message_deleted)
        }

        if (last_message.document) {
          return (localizationStrings.chat.file)
        }

        if (last_message.image) {
          return (localizationStrings.chat.image)
        }
        if (last_message.video) {
          return (localizationStrings.chat.video)
        }
        return translatedForCurrentUser?.translation
      }
      else {
        if (last_message.deleted) {
          return (`~ ${last_message.user?.name}: ` + (localizationStrings.chat.message_deleted))
        }

        if (last_message.video) {
          return (`~ ${last_message.user?.name}: ` + (localizationStrings.chat.video))
        }
        if (last_message.image) {
          return (`~ ${last_message.user?.name}: ` + (localizationStrings.chat.image))
        }
        if (last_message.document) {
          return (`~ ${last_message.user?.name}: ` + (localizationStrings.chat.file))
        }
        if (last_message.deleted) {
          return (`~ ${last_message.user?.name}: ` + (localizationStrings.chat.message_deleted))
        }

        if (last_message.video) {
          return (`~ ${last_message.user?.name}: ` + (localizationStrings.chat.video))
        }
        if (last_message.image) {
          return (`~ ${last_message.user?.name}: ` + (localizationStrings.chat.image))
        }
        if (last_message.document) {
          return (`~ ${last_message.user?.name}: ` + (localizationStrings.chat.file))
        }
        if (last_message.user) {
          return (`~ ${last_message.user?.name}: ` + translatedForCurrentUser?.translation)
        }

      }
    }
  }

  const displayNewOrTimeAgo = (last_message) => {

    if (!last_message || !last_message.id) {
      return
    }

    const translationForCurrentUser = findTranslatedTextForUser(last_message.message_translations, user)

    if (last_message && last_message.user_id != user.id && !translationForCurrentUser?.read_at) {
      return <h2>{localizationStrings.chat.new}</h2>
    }
    else {
      if (last_message) {
        return <TimeAgo
          datetime={translationForCurrentUser.created_at}
          style={{ color: "white", fontSize: "12px" }}
        />
      }
      // else {
      //   if (last_message && last_message.user_id == user.id) {
      //     return <TimeAgo
      //       datetime={last_message.created_at}
      //       style={{ color: "white", fontSize: "12px" }}
      //     />
      //   }
      // }

    }

  }


  return (
    <ChatListElementContainer
      onClick={() => handleSelectConversation(data)}
      selected={selected}
      $closeQc={!data.open && data.quick_chat}
      onContextMenu={(e) => displayMenu(e, data)}
    >
      <UserAvatar style={data.quick_chat ? { border: "none" } : {}}>
        {displayAvatar(data)}
      </UserAvatar>

      <Text>
        <PhoneNumber>
          <h1>{displayName(data)}</h1>
          {displayNewOrTimeAgo(data.last_message)}
        </PhoneNumber>
        <Message>
          <p>{handleLastMessage(data.last_message)}</p>
        </Message>
      </Text>

    </ChatListElementContainer>
  );
};

export default ChatListElement;

const ChatListElementContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  background: ${(props) => props.selected ? colors.chatElementBgSelected : colors.chatElementBg};
  border-radius: 16px;
  gap: 16px;
  border:${(props) => props.selected ? "1px solid #5852D633" : ""};
  margin:${(props) => props.selected ? "-1px" : ""};
opacity:${(props) => props.$closeQc ? "0.5" : ""};
`;
const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  cursor: pointer;
  max-height: 45px;
  max-width: 45px;
  object-fit: contain;
  position: relative;
  min-width: 45px;
  min-height:45px;
  overflow:hidden;
  svg {

    position: relative;
    width: 100%;
    height: 100%;
  }
  img{
    object-fit:cover;
 position: relative;
       height:43px;
    width:43px;
  }
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
overflow:hidden;
width:100%;
`;
const PhoneNumber = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {

    color: ${colors.white};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  h2 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: ${colors.purple};
  }
`;
const Message = styled.div`
width:100%;
  p {
    width:100%;
    display:-webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: wrap;
    text-overflow: ellipsis;
    color: ${colors.chatElementColor};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;
