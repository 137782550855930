import React from 'react'
import OtpInput from "react-otp-input";
import styled from 'styled-components'
import "../../Assets/styles/customPhoneSelect.css"
import 'react-phone-number-input/style.css'

import { colors } from '../../Assets/styles/colors';
import { ReactComponent as CodeOtpIcon } from '../../Assets/icons/enterOtpCodeIcon.svg';
import { localizationStrings } from '../../Localization/Localization';

const EnterOtpCode = ({
    otp,
    setOtp,
    wrongCode,
    handleVerifyCode,
    handleResendOTP,
}) => {
    return (
        <Container>
            <LeftSide>
                <Card>
                    <Group>
                        <h1>{localizationStrings.login.enter_code}</h1>
                        <OtpInputWrapper>
                            <OtpInput
                                containerStyle={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    gap: "5px"
                                }}
                                shouldAutoFocus
                                inputStyle={wrongCode ? { borderColor: "red" } : {}}
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span> </span>}
                                renderInput={(props) => <input id="opt-input" {...props} />}
                            />
                        </OtpInputWrapper>
                    </Group>
                    {wrongCode && <WrongCodeStatus>
                        <p>{localizationStrings.login.wrong_validation_code}</p></WrongCodeStatus>}
                    <Group>
                        <LoginButton
                            disabled={otp?.length < 6}
                            id='verify-code-button'
                            onClick={(e) => handleVerifyCode(otp)}>
                            {localizationStrings.login.verify_code}
                        </LoginButton>
                        <TermsInfo>
                            <p>{localizationStrings.login.code_not_received} <span onClick={() => handleResendOTP()}>{localizationStrings.login.resend_code}</span>
                            </p>
                        </TermsInfo>
                    </Group>
                </Card>
            </LeftSide>
            <RightSide>
                <CodeOtpIcon />
            </RightSide>


        </Container>
    )
}

export default EnterOtpCode
const Container = styled.div`
display:flex;
min-height:600px;
max-height:100vh;
height:100%;
width:100%;
`
const Card = styled.div`
display:flex;
flex-direction:column;
max-width:400px;
width:100%;
gap:20px;
`

const OtpInputWrapper = styled.div`
  display: flex;
  width: 100%;

  input {
    padding: 14px 16px;
    min-width: 50px;
    border-radius: 10px;
    border: 1px solid #306d6d4d;
    color: ${colors.white};
    background: ${colors.newChatModalInputBg};
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    &:focus {
        outline: none;
      }
  }
  
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

h1{
color:${colors.white};
font-size: 32px;
font-weight: 600;
line-height: 39px;
letter-spacing: 0px;
text-align: left;
}

  
`;
const LoginButton = styled.button`
display: flex;
align-items: center;
justify-content:center;
gap: 3px;
background: ${colors.purple};
color: ${colors.white};
font-size: 16px;
font-weight: 600;
line-height: 22px;
text-align: center;
border: none;
border-radius: 10px;
padding: 14px 15px;
cursor: pointer;
svg {
  height: 20px;
  width: 20px;
}
&:disabled{
    opacity:0.5;
}
`;
const LeftSide = styled.div`
width:100%;
display:flex;
align-items:center;
justify-content:center;
padding:0 32px;
`
const RightSide = styled(LeftSide)`
`

const TermsInfo = styled.div`
display:flex;
align-items:center;

p{
color:#B5B8CD;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    
}

span{
    cursor:pointer;
    color:${colors.purple};
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    text-decoration:underline;
}
`
const WrongCodeStatus = styled.div`
p{
   color:${colors.errorColor};
font-size: 14px;
font-weight: 500;
line-height: 17px;
letter-spacing: 0px;
text-align: left;

}
`