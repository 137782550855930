import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/icons/deleteIcon.svg";
import { colors } from '../../Assets/styles/colors';
import { localizationStrings } from '../../Localization/Localization';
const FullscreenImage = ({ image, handleCloseFullScreenImg, handleDeletePhoto, selectedConversation }) => {


    useEffect(() => {
        const keyDownHandler = event => {

            if (event.key === 'Escape') {
                event.preventDefault();

                // 👇️ your logic here
                handleCloseFullScreenImg();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);



    return (
        <Container id='fs'>
            {!selectedConversation.with_emotii && handleDeletePhoto && <DeleteBtn onClick={() => handleDeletePhoto()}>
                {localizationStrings.chat.delete}
            </DeleteBtn>}

            <CloseBtn onClick={() => handleCloseFullScreenImg()}>
                {localizationStrings.chat.close}
            </CloseBtn>
            <img src={image} alt='fscreen' />
        </Container>
    )
}

export default FullscreenImage

const Container = styled.div`
display:flex;
align-items:center;
justify-content:center;
position:absolute;
background:rgba(0,0,0,0.8);
height:100%;
width:100%;
z-index:2;
left:0;
top:0;
padding:50px 100px ;
img{
    max-height:100%;
    max-width:100%;
}

@media(max-width:990px){
    padding:20px;
}
`
const CloseBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
border:none;
background:none;
cursor:pointer;
position:absolute;
top:30px;
right:30px;
z-index:2;

font-size:20px;
font-weight:600;
color:${colors.white};

`
const DeleteBtn = styled(CloseBtn)`
color:${colors.errorColor};
left:30px;
right:unset;
gap:5px;
// border:1px solid ${colors.errorColor};
padding:5px;
border-radius:10px;
`