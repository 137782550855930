import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

const DateSeparator = ({ date }) => {

    const checkDate = (date) => {
        if (moment(date).isBefore(moment(), "M")) {
            return (moment(date).format("YYYY.MM.DD"))
        } else {
            return moment(date).format("dddd, DD")
        }
    }

    return (
        <Container>
            <DateSeparatorWrapper>
                <p>{checkDate(date)}</p>
            </DateSeparatorWrapper>
        </Container>
    )
}

export default DateSeparator
const Container = styled.div`
display:flex;
width:100%;
align-items:center;
justify-content:center;
padding:10px 0;
`
const DateSeparatorWrapper = styled.div`
padding:8px 16px;
display:flex;
justify-content:center;
align-items:center;
background:#33354654;
border-radius:100px;
p{
font-size: 14px;
font-weight: 500;
line-height: 17px;
color:#F5F5F5;
}

`