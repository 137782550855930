import React from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";

const ControlButton = ({ icon, active, ...props }) => {
  return (
    <CustomButton {...props} $active={active}>
      {icon}
    </CustomButton>
  );
};

export default ControlButton;
const CustomButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background: ${(props) =>
    props.$active ? colors.white : colors.controlBtn.inactiveBg};
  border: none;
  cursor: pointer;
  overflow: hidden;
  color: ${(props) =>
    props.$active ? colors.controlBtn.inactiveBg : colors.white};
`;
