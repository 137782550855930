import { createContext, useCallback, useContext, useEffect } from "react";
import WebSocketHandler from "../Helpers/websocket/WebSocketHandler";
import { getQcWebsockerUrlApi } from "../API/websocket/getWebsocketurl";
import LocalStorage from "../Helpers/LocalStorage";
import { useOnlineStatus } from "../Helpers/useOnlineStatus";

export const QcWebSocketServiceContext = createContext({
  add_observer: () => {},
  remove_observer: () => {},
});

const qcHandler = new WebSocketHandler();

const QcWebSocketServiceProvider = ({ children }) => {
  const isOnline = useOnlineStatus();

  const add_observer = (observer) => {
    qcHandler.addObserver(observer);
  };

  const remove_observer = (observer) => {
    qcHandler.removeObserver(observer);
  };

  const connectHandler = () => {
    qcHandler.disconnect();

    qcHandler.connect();
  };

  const getQcWebsocketUrl = useCallback(
    async (conversation_id, onSucces, errorCallback) => {
      try {
        await getQcWebsockerUrlApi(
          conversation_id,
          function (response) {
            sessionStorage.setItem("wsLink", response.data.url);
            qcHandler.connect();
            onSucces(response);
          },
          function (error) {
            errorCallback(error);
          }
        );
      } catch (err) {
        errorCallback();
      }
    }
  );

  useEffect(() => {
    if (isOnline) {
      connectHandler();
    }
  }, [isOnline]);

  return (
    <QcWebSocketServiceContext.Provider
      value={{
        add_observer,
        remove_observer,
        getQcWebsocketUrl,
      }}
    >
      {children}
    </QcWebSocketServiceContext.Provider>
  );
};
export const useQcWebSocketDataService = () =>
  useContext(QcWebSocketServiceContext);
export default QcWebSocketServiceProvider;
